import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ResetPassword from './ResetPassword';
import { resetPasswordTokenRequest, resetPasswordRequest } from '../../packages/authentication';

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPasswordTokenRequest,
  resetPasswordRequest,
}, dispatch);

export default connect(null, mapDispatchToProps)(ResetPassword);
