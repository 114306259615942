export const languages = [
  { name: 'Abkhazian', langCode: 'AB' },
  { name: 'Afar', langCode: 'AA' },
  { name: 'Afrikaans', langCode: 'AF' },
  { name: 'Albanian', langCode: 'SQ' },
  { name: 'Amharic', langCode: 'AM' },
  { name: 'Arabic', langCode: 'AR' },
  { name: 'Armenian', langCode: 'HY' },
  { name: 'Assamese', langCode: 'AS' },
  { name: 'Aymara', langCode: 'AY' },
  { name: 'Azerbaijani', langCode: 'AZ' },
  { name: 'Bashkir', langCode: 'BA' },
  { name: 'Basque', langCode: 'EU' },
  { name: 'Bengali, Bangla', langCode: 'BN' },
  { name: 'Bhutani', langCode: 'DZ' },
  { name: 'Bihari', langCode: 'BH' },
  { name: 'Bislama', langCode: 'BI' },
  { name: 'Breton', langCode: 'BR' },
  { name: 'Bulgarian', langCode: 'BG' },
  { name: 'Burmese', langCode: 'MY' },
  { name: 'Byelorussian', langCode: 'BE' },
  { name: 'Cambodian', langCode: 'KM' },
  { name: 'Catalan', langCode: 'CA' },
  { name: 'Chinese', langCode: 'ZH' },
  { name: 'Corsican', langCode: 'CO' },
  { name: 'Croatian', langCode: 'HR' },
  { name: 'Czech', langCode: 'CS' },
  { name: 'Danish', langCode: 'DA' },
  { name: 'Dutch', langCode: 'NL' },
  { name: 'English, American', langCode: 'EN' },
  { name: 'Esperanto', langCode: 'EO' },
  { name: 'Estonian', langCode: 'ET' },
  { name: 'Faeroese', langCode: 'FO' },
  { name: 'Fiji', langCode: 'FJ' },
  { name: 'Finnish', langCode: 'FI' },
  { name: 'French', langCode: 'FR' },
  { name: 'Frisian', langCode: 'FY' },
  { name: 'Gaelic', langCode: 'GD' },
  { name: 'Galician', langCode: 'GL' },
  { name: 'Georgian', langCode: 'KA' },
  { name: 'German', langCode: 'DE' },
  { name: 'Greek', langCode: 'EL' },
  { name: 'Greenlandic', langCode: 'KL' },
  { name: 'Guarani', langCode: 'GN' },
  { name: 'Gujarati', langCode: 'GU' },
  { name: 'Hausa', langCode: 'HA' },
  { name: 'Hebrew', langCode: 'IW' },
  { name: 'Hindi', langCode: 'HI' },
  { name: 'Hungarian', langCode: 'HU' },
  { name: 'Icelandic', langCode: 'IS' },
  { name: 'Indonesian', langCode: 'IN' },
  { name: 'Interlingua', langCode: 'IA' },
  { name: 'Interlingue', langCode: 'IE' },
  { name: 'Inupiak', langCode: 'IK' },
  { name: 'Irish', langCode: 'GA' },
  { name: 'Italian', langCode: 'IT' },
  { name: 'Japanese', langCode: 'JA' },
  { name: 'Javanese', langCode: 'JW' },
  { name: 'Kannada', langCode: 'KN' },
  { name: 'Kashmiri', langCode: 'KS' },
  { name: 'Kazakh', langCode: 'KK' },
  { name: 'Kinyarwanda', langCode: 'RW' },
  { name: 'Kirghiz', langCode: 'KY' },
  { name: 'Kirundi', langCode: 'RN' },
  { name: 'Korean', langCode: 'KO' },
  { name: 'Kurdish', langCode: 'KU' },
  { name: 'Laothian', langCode: 'LO' },
  { name: 'Latin', langCode: 'LA' },
  { name: 'Latvian, Lettish', langCode: 'LV' },
  { name: 'Lingala', langCode: 'LN' },
  { name: 'Lithuanian', langCode: 'LT' },
  { name: 'Macedonian', langCode: 'MK' },
  { name: 'Malagasy', langCode: 'MG' },
  { name: 'Malay', langCode: 'MS' },
  { name: 'Malayalam', langCode: 'ML' },
  { name: 'Maltese', langCode: 'MT' },
  { name: 'Maori', langCode: 'MI' },
  { name: 'Marathi', langCode: 'MR' },
  { name: 'Moldavian', langCode: 'MO' },
  { name: 'Mongolian', langCode: 'MN' },
  { name: 'Nauru', langCode: 'NA' },
  { name: 'Nepali', langCode: 'NE' },
  { name: 'Norwegian', langCode: 'NO' },
  { name: 'Occitan', langCode: 'OC' },
  { name: 'Oriya', langCode: 'OR' },
  { name: 'Oromo, Afan', langCode: 'OM' },
  { name: 'Pashto, Pushto', langCode: 'PS' },
  { name: 'Persian', langCode: 'FA' },
  { name: 'Polish', langCode: 'PL' },
  { name: 'Portuguese', langCode: 'PT' },
  { name: 'Punjabi', langCode: 'PA' },
  { name: 'Quechua', langCode: 'QU' },
  { name: 'Rhaeto, Romance', langCode: 'RM' },
  { name: 'Romanian', langCode: 'RO' },
  { name: 'Russian', langCode: 'RU' },
  { name: 'Samoan', langCode: 'SM' },
  { name: 'Sangro', langCode: 'SG' },
  { name: 'Sanskrit', langCode: 'SA' },
  { name: 'Serbian', langCode: 'SR' },
  { name: 'Serbo, Croatian', langCode: 'SH' },
  { name: 'Sesotho', langCode: 'ST' },
  { name: 'Setswana', langCode: 'TN' },
  { name: 'Shona', langCode: 'SN' },
  { name: 'Sindhi', langCode: 'SD' },
  { name: 'Singhalese', langCode: 'SI' },
  { name: 'Siswati', langCode: 'SS' },
  { name: 'Slovak', langCode: 'SK' },
  { name: 'Slovenian', langCode: 'SL' },
  { name: 'Somali', langCode: 'SO' },
  { name: 'Spanish', langCode: 'ES' },
  { name: 'Sudanese', langCode: 'SU' },
  { name: 'Swahili', langCode: 'SW' },
  { name: 'Swedish', langCode: 'SV' },
  { name: 'Tagalog', langCode: 'TL' },
  { name: 'Tajik', langCode: 'TG' },
  { name: 'Tamil', langCode: 'TA' },
  { name: 'Tatar', langCode: 'TT' },
  { name: 'Tegulu', langCode: 'TE' },
  { name: 'Thai', langCode: 'TH' },
  { name: 'Tibetan', langCode: 'BO' },
  { name: 'Tigrinya', langCode: 'TI' },
  { name: 'Tonga', langCode: 'TO' },
  { name: 'Tsonga', langCode: 'TS' },
  { name: 'Turkish', langCode: 'TR' },
  { name: 'Turkmen', langCode: 'TK' },
  { name: 'Twi', langCode: 'TW' },
  { name: 'Ukrainian', langCode: 'UK' },
  { name: 'Urdu', langCode: 'UR' },
  { name: 'Uzbek', langCode: 'UZ' },
  { name: 'Vietnamese', langCode: 'VI' },
  { name: 'Volapuk', langCode: 'VO' },
  { name: 'Welsh', langCode: 'CY' },
  { name: 'Wolof', langCode: 'WO' },
  { name: 'Xhosa', langCode: 'XH' },
  { name: 'Yiddish', langCode: 'JI' },
  { name: 'Yoruba', langCode: 'YO' },
  { name: 'Zulu', langCode: 'ZU' },
];

export const langOptions = languages.map(({ name, langCode }) => ({
  value: langCode, text: name,
}));

export default languages;
