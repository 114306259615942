import { connect } from 'react-redux';
import UserForm from './UserForm';

const mapStateToProps = ({
  userStatuses: { byId: statusesById, byHash: statusesByHash },
}) => ({
  statusesOptions: statusesById
    .map(id => ({ value: id, text: statusesByHash[id].name })),
});

export default connect(mapStateToProps)(UserForm);
