import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Label, Segment } from 'semantic-ui-react';

import styles from './ResetPasswordSegment.module.scss';

const ResetPasswordSegment = ({
  method, selectedMethod, selectMethod, children,
}) => {
  const isActive = selectedMethod === method;
  const handleSelect = () => {
    selectMethod(method);
  };

  return (
    <Segment
      className={classNames(styles.segment, isActive && styles.active)}
      onClick={handleSelect}
      padded
    >
      <Label circular content={method} color="blue" basic={!isActive} size="huge" />
      <div>{children}</div>
    </Segment>
  );
};

ResetPasswordSegment.defaultProps = {
  selectedMethod: null,
};

ResetPasswordSegment.propTypes = {
  children: PropTypes.node.isRequired,
  method: PropTypes.number.isRequired,
  selectMethod: PropTypes.func.isRequired,
  selectedMethod: PropTypes.number,
};

export default ResetPasswordSegment;
