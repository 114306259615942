import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';

export const formatDate = date => (
  date ? moment(date).format(DATE_FORMAT) : ''
);

export const formatTime = (hours, minutes) => (
  `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
);
