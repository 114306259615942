import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Pagination, Image } from 'semantic-ui-react';

import List from '../../../List';
import SearchPanel from '../../../SearchPanel';
import params from '../../../../utils/params';
import styles from './ListContainer.module.scss';
import useFilter from '../../../../hooks/useFilter';
import usePagination from '../../../../hooks/usePagination';
import useSearch from '../../../../hooks/useSearch';
import useSorting from '../../../../hooks/useSorting';
import { filterConfigType } from '../../../../constants/propTypes';

const ListQueryParams = {
  LIST_PAGE_URL_PARAM: 'page',
  LIST_FIELD_URL_PARAM: 'field',
  LIST_ORDER_URL_PARAM: 'order',
  LIST_ACTIVE_ID_URL_PARAM: 'activeId',
};

const ListContainer = ({
  fetch,
  loading,
  count,
  history,
  columns,
  filterConfig,
  renderItems,
  renderAddButton,
  renderHeader,
  searchText,
  expandable,
}) => {
  const { getParams } = params(history);
  const {
    [ListQueryParams.LIST_PAGE_URL_PARAM]: page,
    [ListQueryParams.LIST_FIELD_URL_PARAM]: field,
    [ListQueryParams.LIST_ORDER_URL_PARAM]: order,
    [ListQueryParams.LIST_ACTIVE_ID_URL_PARAM]: activeId,
  } = getParams();
  const [onPageChange, totalPages, showPagination] = usePagination(history, count, loading);
  const [search, onChangeSearch] = useSearch(history);
  const [filters] = useFilter(history);

  const {
    onSortChange,
    setSortField,
    setSortOrder,
    sortField,
    sortOrder,
  } = useSorting(history);

  useEffect(() => {
    fetch({
      order,
      field,
      filters,
      page,
      search,
    });
    setSortField(field);
    setSortOrder(order);
  }, [
    fetch, page, field, order, setSortField, setSortOrder, search, JSON.stringify(filters),
  ]);

  return (
    <div className={styles.container} key="listContainer">
      <div className={styles.listWrapper}>
        <div className={styles.header}>
          <SearchPanel
            defaultValue={search}
            filterConfig={filterConfig}
            onChange={onChangeSearch}
            placeholder={searchText}
          />
          {renderAddButton && renderAddButton()}
        </div>
        <div className={styles.wrapper}>
          {renderHeader && renderHeader()}
          <List
            columns={columns}
            count={count}
            loading={loading}
            onSortChange={onSortChange}
            sortField={sortField}
            sortOrder={sortOrder}
            expandable={expandable}
            activeId={activeId}
          >
            {renderItems()}
          </List>
        </div>
      </div>
      <div className={styles.pagination}>
        {showPagination && (
          <Pagination
            defaultActivePage={page || 1}
            firstItem={{ content: (<Image src="/images/icons/arrow-left.svg" />) }}
            lastItem={{ content: (<Image src="/images/icons/arrow-right.svg" />) }}
            nextItem={false}
            prevItem={false}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

ListContainer.defaultProps = {
  count: null,
  searchText: '',
  renderAddButton: null,
  renderHeader: null,
  expandable: false,
};

ListContainer.propTypes = {
  expandable: PropTypes.bool,
  fetch: PropTypes.func.isRequired,
  filterConfig: filterConfigType.isRequired,
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number,
  history: PropTypes.shape({}).isRequired,
  renderItems: PropTypes.func.isRequired,
  renderAddButton: PropTypes.func,
  renderHeader: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchText: PropTypes.string,
};

export default ListContainer;
