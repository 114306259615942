import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserStatusListItem from './UserStatusListItem';
import { updateUserStatusRequest, deleteUserStatusRequest } from '../../../../actions/userStatuses';

const mapDispatchToProps = dispatch => ({
  updateUserStatus: bindActionCreators(updateUserStatusRequest, dispatch),
  deleteUserStatus: bindActionCreators(deleteUserStatusRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(UserStatusListItem);
