import insertIf from '../../utils/insertIf';

export default statuses => [{
  header: 'NAME',
  align: 'left',
  size: 3,
  sortable: true,
  key: 'name',
}, {
  header: '#_OF_USERS',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'userCount',
}, {
  header: 'CREATED_AT',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'created',
},
...insertIf(!!statuses.length, {
  header: 'STATUS',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'status',
})];
