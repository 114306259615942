export default ({ statuses }) => [{
  name: 'CREATED',
  key: 'created',
  type: 'date',
}, {
  name: 'UPDATED',
  key: 'updated',
  type: 'date',
}, {
  name: '#_OF_USERS',
  key: 'userCount',
  type: 'number',
}, {
  name: 'STATUS',
  key: 'statuses',
  type: 'selection',
  values: statuses,
}];
