import {
  FETCH_ORGANIZATION_STATUSES_REQUEST,
  FETCH_ORGANIZATION_STATUSES_SUCCESS,
  UPDATE_ORGANIZATION_STATUS_REQUEST,
  UPDATE_ORGANIZATION_STATUS_SUCCESS,
  NEW_ORGANIZATION_STATUS_REQUEST,
  NEW_ORGANIZATION_STATUS_SUCCESS,
  NEW_ORGANIZATION_STATUS_MODAL_OPEN,
  NEW_ORGANIZATION_STATUS_MODAL_CLOSE,
  DELETE_ORGANIZATION_STATUS_REQUEST,
  DELETE_ORGANIZATION_STATUS_SUCCESS,
} from '../constants/actionTypes';

export const fetchOrganizationStatusesRequest = () => ({
  type: FETCH_ORGANIZATION_STATUSES_REQUEST,
});

export const fetchOrganizationStatusesSuccess = organizationStatuses => ({
  type: FETCH_ORGANIZATION_STATUSES_SUCCESS,
  payload: {
    organizationStatuses,
  },
});

export const updateOrganizationStatusRequest = status => ({
  type: UPDATE_ORGANIZATION_STATUS_REQUEST,
  payload: {
    status,
  },
});

export const updateOrganizationStatusSuccess = status => ({
  type: UPDATE_ORGANIZATION_STATUS_SUCCESS,
  payload: {
    status,
  },
});

export const newOrganizationStatusRequest = status => ({
  type: NEW_ORGANIZATION_STATUS_REQUEST,
  payload: {
    status,
  },
});

export const newOrganizationStatusSuccess = status => ({
  type: NEW_ORGANIZATION_STATUS_SUCCESS,
  payload: {
    status,
  },
});

export const openModal = () => ({
  type: NEW_ORGANIZATION_STATUS_MODAL_OPEN,
});

export const closeModal = () => ({
  type: NEW_ORGANIZATION_STATUS_MODAL_CLOSE,
});

export const deleteOrganizationStatusRequest = id => ({
  type: DELETE_ORGANIZATION_STATUS_REQUEST,
  payload: {
    id,
  },
});

export const deleteOrganizationStatusSuccess = id => ({
  type: DELETE_ORGANIZATION_STATUS_SUCCESS,
  payload: {
    id,
  },
});
