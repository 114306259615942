import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Header, Dropdown, Form,
} from 'semantic-ui-react';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import styles from './OrganizationForm.module.scss';
import CustomAttributesList from '../../../../components/CustomAttributesList';

const OrganizationForm = ({
  organization, handleChange, errors, statusesOptions, loadingList,
  handleBlur, fetchOrganizations, organizationsOptions,
  selectedParentOrg, setSelectedParentOrg,
}) => {
  const [translate] = useTranslation();
  const [searchParentOrg, setSearchParentOrg] = useState('');
  const [search$] = useState(() => new Subject());

  const {
    id: organizationId, name, accountStatus, customAttributes, parent,
  } = organization;

  useEffect(() => {
    fetchOrganizations({ search: searchParentOrg, saveTempOrgList: true });
  }, []);

  const setCustomAttributes = (currentCustomAttributes) => {
    handleChange(null, { name: 'customAttributes', value: currentCustomAttributes });
  };

  useEffect(() => {
    search$.next(searchParentOrg);
  }, [searchParentOrg, search$]);

  useEffect(() => {
    const searchListener = search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((searchValue) => {
        fetchOrganizations({ search: searchValue, saveTempOrgList: true });
      });

    return () => {
      searchListener.unsubscribe();
    };
  }, [search$]);

  const handleSearchParentOrg = (event, { searchQuery }) => {
    setSearchParentOrg(searchQuery);
  };

  const handleChangeParentOrg = (event, { value }) => {
    setSelectedParentOrg(value);
  };

  return (
    <div className={styles.detailsWrapper}>
      <Form className={styles.form}>
        <Form.Group inline>
          <label htmlFor="org-name">{translate('NAME')}</label>
          <Form.Input
            defaultValue={name}
            id="org-name"
            maxLength={50}
            error={errors.name}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translate('NAME')}
            fluid
          />
        </Form.Group>
        { !!statusesOptions.length && (
          <Form.Group inline>
            <label htmlFor="org-status">{translate('STATUS')}</label>
            <Form.Dropdown
              id="org-status"
              defaultValue={accountStatus && accountStatus.id}
              error={errors.statusId}
              name="statusId"
              onChange={handleChange}
              options={statusesOptions}
              onBlur={handleBlur}
              placeholder={translate('STATUS')}
              selection
              clearable
              fluid
            />
          </Form.Group>
        )}
        <CustomAttributesList
          upsertCustomAttributes={setCustomAttributes}
          customAttributes={customAttributes}
          handleBlur={handleBlur}
          errors={errors}
        />
        <div>
          <Header as="h4" className={styles.parentOrgHeader}>{translate('PARENT_ORGANIZATION')}</Header>
          <Dropdown
            selection
            fluid
            defaultValue={parent && parent.id}
            onChange={handleChangeParentOrg}
            onSearchChange={handleSearchParentOrg}
            loading={loadingList}
            disabled={loadingList}
            search
            clearable={!!selectedParentOrg}
            options={[
              ...(organizationId
                ? organizationsOptions.filter(({ value }) => value !== organizationId)
                : organizationsOptions),
            ]}
          />
        </div>
      </Form>
    </div>
  );
};

OrganizationForm.defaultProps = {
  handleBlur: () => {},
  selectedParentOrg: null,
};

OrganizationForm.propTypes = {
  fetchOrganizations: PropTypes.func.isRequired,
  setSelectedParentOrg: PropTypes.func.isRequired,
  selectedParentOrg: PropTypes.number,
  errors: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  loadingList: PropTypes.bool.isRequired,
  statusesOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  organizationsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  organization: PropTypes.shape({}).isRequired,
};

export default OrganizationForm;
