import insertIf from '../../utils/insertIf';

export default statuses => [{
  header: 'NAME',
  align: 'left',
  size: 1,
  sortable: true,
  key: 'name',
}, {
  header: 'ORGANIZATIONS',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'organizationsCount',
}, {
  header: 'CONTACT',
  size: 2,
  align: 'left',
}, {
  header: 'CREATED_AT',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'created',
},
...(insertIf(!!statuses.length, {
  header: 'STATUS',
  size: 1,
  align: 'left',
  sortable: true,
  key: 'status',
}))];
