import {
  arrayOf,
  shape,
  string,
} from 'prop-types';

export const filterValues = arrayOf(shape({
  name: string,
  key: string,
}));

export const filterConfigType = arrayOf(shape({
  name: string,
  key: string,
  type: string,
  values: filterValues,
}));

export const locationType = shape({
  pathname: string,
  search: string,
});
