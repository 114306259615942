import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Form, Message,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import OrganizationsForm from '../OrganizationsForm';
import styles from './UserForm.module.scss';
import { ROLES } from '../../../../constants/customizations';
import { countryOptions } from '../../../../utils/countries';
import InputPassword from '../../../../components/InputPassword/InputPassword';
import { langOptions } from '../../../../utils/languages';
import CustomAttributesList from '../../../../components/CustomAttributesList';

const UserForm = ({
  user, handleChange, errors, statusesOptions, createForm, formError, handleBlur,
}) => {
  const [translate, i18n] = useTranslation();
  const {
    firstName = '', lastName = '', email = '', username = '',
    language, country, role, memberships, status, customAttributes = [],
  } = user;

  const roleOptions = ROLES.map(value => ({ value, text: translate(value) }));

  const dropdownProps = {
    selection: true, fluid: true, clearable: true,
  };

  useEffect(() => {
    if (createForm && !role) {
      handleChange(null, { name: 'role', value: roleOptions.find(({ value }) => value === 'USER') ? 'USER' : '' });
    }
  }, [createForm]);

  const setCustomAttributes = (currentCustomAttributes) => {
    handleChange(null, { name: 'customAttributes', value: currentCustomAttributes });
  };

  return [
    <div className={styles.detailsWrapper} key="details">
      <Form className={styles.form}>
        <Form.Group inline className={[styles.formGroup, styles.formGroupMobile].join(' ')}>
          <label htmlFor="user-form-name">{translate('NAME')}</label>
          <Form.Input
            value={firstName}
            id="user-form-name"
            error={errors.firstName}
            className={styles.formInput}
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={translate('FIRST_NAME')}
            maxLength={50}
            fluid
          />
          <label htmlFor="user-form-last-name" className={styles.labelLastName}>{translate('LAST_NAME')}</label>
          <Form.Input
            value={lastName}
            id="user-form-last-name"
            error={errors.lastName}
            className={styles.formInput}
            name="lastName"
            maxLength={50}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={translate('LAST_NAME')}
            fluid
          />
        </Form.Group>
        <Form.Group inline className={[styles.formGroup, styles.formGroupMobile].join(' ')}>
          <label htmlFor="user-form-email">{translate('EMAIL.LABEL')}</label>
          <Form.Input
            value={email}
            disabled={!createForm}
            id="user-form-email"
            error={errors.email}
            className={styles.formInput}
            name="email"
            fluid
            maxLength={50}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={translate('EMAIL.LABEL')}
          />
        </Form.Group>

        <Form.Group inline className={[styles.formGroup, styles.formGroupMobile].join(' ')}>
          <label htmlFor="user-form-username">{translate('USERNAME.LABEL')}</label>
          <Form.Input
            error={errors.username}
            name="username"
            id="user-form-username"
            onChange={handleChange}
            disabled={!createForm}
            className={styles.formInput}
            maxLength={50}
            value={username}
            fluid
            onBlur={handleBlur}
            placeholder={translate('USERNAME.LABEL')}
            type="text"
          />

          {createForm && (
            <>
              <label htmlFor="user-form-password">{translate('PASSWORD')}</label>
              <InputPassword
                error={errors.password}
                name="password"
                fluid
                id="user-form-password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={translate('PASSWORD')}
              />
            </>
          )}
        </Form.Group>
        <Form.Group inline className={[styles.formGroup, styles.formGroupMobile].join(' ')}>
          <label htmlFor="user-form-role">{translate('ROLE')}</label>
          <Form.Dropdown
            {...dropdownProps}
            value={role}
            id="user-form-role"
            onBlur={handleBlur}
            error={errors.role}
            className={styles.formInput}
            name="role"
            onChange={handleChange}
            options={roleOptions}
            placeholder={translate('ROLE')}
          />

          { !!statusesOptions.length && (
            <>
              <label htmlFor="user-form-status">{translate('STATUS')}</label>
              <Form.Dropdown
                {...dropdownProps}
                id="user-form-status"
                defaultValue={status && status.id}
                error={errors.status}
                name="accountStatusId"
                onBlur={handleBlur}
                className={styles.formInput}
                onChange={handleChange}
                options={statusesOptions}
                placeholder={translate('STATUS')}
              />
            </>
          )}
        </Form.Group>

        <Form.Group inline className={[styles.formGroup, styles.formGroupMobile].join(' ')}>
          <label htmlFor="user-form-country">{translate('COUNTRY')}</label>
          <Form.Dropdown
            {...dropdownProps}
            lazyLoad
            id="user-form-country"
            search
            value={country}
            name="country"
            className={styles.formInput}
            onChange={handleChange}
            onBlur={handleBlur}
            options={countryOptions}
            placeholder={translate('COUNTRY')}
          />
          <label htmlFor="user-form-language">{translate('LANGUAGE')}</label>
          <Form.Dropdown
            {...dropdownProps}
            lazyLoad
            search
            id="user-form-language"
            value={language && language.length ? language.toUpperCase() : null}
            name="language"
            onBlur={handleBlur}
            onChange={handleChange}
            className={styles.formInput}
            options={langOptions}
            placeholder={translate('LANGUAGE')}
          />
        </Form.Group>
        <CustomAttributesList
          upsertCustomAttributes={setCustomAttributes}
          customAttributes={customAttributes}
          handleBlur={handleBlur}
          errors={errors}
        />
        {formError && i18n.exists(formError.toUpperCase()) && (
          <Message negative content={translate(formError.toUpperCase())} />
        )}
      </Form>
    </div>,
    <OrganizationsForm
      key="organizations"
      memberships={memberships}
      handleChange={handleChange}
    />,
  ];
};

UserForm.defaultProps = {
  createForm: false,
  formError: null,
};

UserForm.propTypes = {
  createForm: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  formError: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  statusesOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default UserForm;
