export const SIGN_IN_REQUEST = '@@AUTHENTICATION/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@@AUTHENTICATION/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = '@@AUTHENTICATION/SIGN_IN_ERROR';

export const SIGN_OUT_REQUEST = '@@AUTHENTICATION/SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = '@@AUTHENTICATION/SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = '@@AUTHENTICATION/SIGN_OUT_ERROR';

export const RESET_PASSWORD_TOKEN_REQUEST = '@@AUTHENTICATION/RESET_PASSWORD_TOKEN_REQUEST';
export const RESET_PASSWORD_TOKEN_SUCCESS = '@@AUTHENTICATION/RESET_PASSWORD_TOKEN_SUCCESS';
export const RESET_PASSWORD_TOKEN_ERROR = '@@AUTHENTICATION/RESET_PASSWORD_TOKEN_ERROR';

export const RESET_PASSWORD_REQUEST = '@@AUTHENTICATION/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = '@@AUTHENTICATION/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = '@@AUTHENTICATION/RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_CLEAN_UP = '@@AUTHENTICATION/RESET_PASSWORD_CLEAN_UP';

export const RESET_PASSWORD_BY_ADMIN_REQUEST = '@@AUTHENTICATION/RESET_PASSWORD_BY_ADMIN_REQUEST';
export const RESET_PASSWORD_BY_ADMIN_SUCCESS = '@@AUTHENTICATION/RESET_PASSWORD_BY_ADMIN_SUCCESS';

export const ERROR = 'ERROR';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const REDUX_INIT = '@@INIT';
