import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { CUSTOM_ROLES_PATH } from '../../constants/paths';
import { NEW_ROLE_ERROR, NEW_ROLE_REQUEST, NEW_ROLE_SUCCESS } from '../../constants/actionTypes';
import { newRoleSuccess, closeModal } from '../../actions/roles';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(NEW_ROLE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { role } }, { authentication: { authToken } }]) => ajax({
    url: CUSTOM_ROLES_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ name: role }),
  }).pipe(
    mergeMap(({ response: newRole }) => [
      addNotification(NotificationType.SUCCESS, NEW_ROLE_SUCCESS),
      newRoleSuccess(newRole),
      closeModal(),
    ]),
    catchError(error => of(handleError(error, NEW_ROLE_ERROR))),
  )),
);
