import {
  deleteRole,
  updateRole,
  newRole,
  fetchRoles,
} from './roles';

import {
  deleteUserStatus,
  fetchUserStatuses,
  newUserStatus,
  updateUserStatus,
} from './userStatuses';

import {
  deleteOrganizationStatus,
  fetchOrganizationStatuses,
  newOrganizationStatus,
  updateOrganizationStatus,
} from './organizationStatuses';

import {
  createUser,
  editUser,
  fetchUser,
  fetchUsers,
  editEmail,
} from './users';

import {
  createOrganization,
  editOrganization,
  fetchOrganization,
  fetchChildrenOrganizations,
  fetchOrganizations,
  assignParentOrganization,
} from './organizations';

import addNotification from './notifications';
import handleError from './errors';
import signIn from './authentication';

export default [
  addNotification,
  createOrganization,
  createUser,
  editOrganization,
  editUser,
  fetchOrganizations,
  fetchOrganization,
  fetchUser,
  fetchUsers,
  deleteUserStatus,
  fetchUserStatuses,
  handleError,
  newUserStatus,
  updateUserStatus,
  assignParentOrganization,
  deleteOrganizationStatus,
  fetchChildrenOrganizations,
  fetchOrganizationStatuses,
  newOrganizationStatus,
  updateOrganizationStatus,
  deleteRole,
  updateRole,
  newRole,
  fetchRoles,
  signIn,
  editEmail,
];
