import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UsersListItemNew from './UsersListItemNew';
import { createUserRequest } from '../../../../actions/users';

const mapStateToProps = ({ users: { error } }) => ({
  error,
});

const mapDispatchToProps = dispatch => ({
  createUser: bindActionCreators(createUserRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListItemNew);
