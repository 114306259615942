import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonAdd from '../../components/ButtonAdd';
import List from '../../components/List';
import NewRoleModal from './components/NewRoleModal';
import RolesListItem from './components/RolesListItem';
import columns from './columns';
import styles from './Roles.module.scss';
import WrapperBackdrop from '../../components/WrapperBackdrop';

const Roles = ({
  roles, loading, fetchRoles, addNewRole, newRoleModalOpened, openModal, closeModal, count,
}) => {
  const [roleToUpdate, setRoleToUpdate] = useState();

  useEffect(() => {
    fetchRoles();
  }, []);

  const renderItems = () => roles.map(role => (
    <RolesListItem
      key={role.id}
      role={role}
      setRoleToUpdate={setRoleToUpdate}
      roleToUpdate={roleToUpdate}
    />
  ));

  const handleNewRole = () => {
    setRoleToUpdate(null);
    openModal();
  };

  return [
    <div className={styles.container} key="container">
      <div className={styles.header}>
        <ButtonAdd handleClick={handleNewRole} title="ADD_NEW" />
      </div>

      <WrapperBackdrop>
        <List
          columns={columns}
          loading={loading}
          emptyTableText="NO_ROLES_DEFINED_YET"
          count={count}
        >
          {renderItems()}
        </List>
      </WrapperBackdrop>
    </div>,
    newRoleModalOpened && (
      <NewRoleModal
        closeModal={closeModal}
        addNewRole={addNewRole}
        loading={loading}
        key="modal"
      />
    ),
  ];
};

Roles.propTypes = {
  fetchRoles: PropTypes.func.isRequired,
  addNewRole: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  newRoleModalOpened: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
};

export default Roles;
