import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ResetPasswordSegment from './components/ResetPasswordSegment';
import UpdatePasswordAdminForm from './components/UpdatePasswordAdminForm';
import UpdatePasswordMessage from './components/UpdatePasswordMessage';
import styles from './ResetPasswordModal.module.scss';
import useForm from '../../hooks/useForm';
import { validationSchema } from './schema';
import { RESET_PASSWORD_MODAL_METHODS } from '../../constants/resetPasswordModalMethods';

const ResetPasswordModal = ({
  resetPasswordByAdmin, resetPasswordToken, username, email, userId,
}) => {
  const [translate] = useTranslation();
  const [resultMessageVisible, setResultMessageVisible] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedMethod, selectMethod] = useState(null);
  const {
    values, setValues, handleChange, validate, errors, handleBlur, clearForm,
  } = useForm(validationSchema);

  const handleSendEmail = () => {
    resetPasswordToken(username);
    setResultMessageVisible(true);
  };

  const handleResetPassword = () => {
    if (validate()) {
      resetPasswordByAdmin(userId, values.password);
      setResultMessageVisible(true);
    }
  };

  const toggleModal = () => {
    clearForm({});
    selectMethod(null);
    setResultMessageVisible(false);
    setModalOpened(isOpened => !isOpened);
  };

  return (
    <Modal
      closeIcon
      onClose={toggleModal}
      open={modalOpened}
      size="large"
      trigger={<Button content={translate('RESET_PASSWORD')} onClick={toggleModal} circular primary disabled={!username} />}
    >
      <Modal.Header content={translate('RESET_PASSWORD')} />

      {!resultMessageVisible ? (
        <Modal.Content className={styles.modalContent}>
          {translate('SELECT_ONE')}

          <ResetPasswordSegment
            method={RESET_PASSWORD_MODAL_METHODS.SEND_EMAIL}
            selectMethod={selectMethod}
            selectedMethod={selectedMethod}
          >
            <p>{translate('SEND_RESET_EMAIL_TEXT')}</p>
            <span>{email}</span>
          </ResetPasswordSegment>

          <ResetPasswordSegment
            method={RESET_PASSWORD_MODAL_METHODS.RESET_PASSWORD}
            selectMethod={selectMethod}
            selectedMethod={selectedMethod}
          >
            <p>{translate('CREATE_NEW_PASSWORD_TEXT')}</p>
            <UpdatePasswordAdminForm
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              errors={errors}
              handleBlur={handleBlur}
            />
          </ResetPasswordSegment>
        </Modal.Content>
      ) : (
        <UpdatePasswordMessage selectedMethod={selectedMethod} />
      )}

      {!resultMessageVisible && (
        <Modal.Actions>
          <Button content={translate('CANCEL')} onClick={toggleModal} primary basic className="simple" />

          {selectedMethod === RESET_PASSWORD_MODAL_METHODS.SEND_EMAIL && (
            <Button content={translate('SEND_EMAIL')} onClick={handleSendEmail} primary />
          )}
          {selectedMethod === RESET_PASSWORD_MODAL_METHODS.RESET_PASSWORD && (
            <Button content={translate('RESET_PASSWORD')} onClick={handleResetPassword} primary />
          )}
        </Modal.Actions>
      )}
    </Modal>
  );
};

ResetPasswordModal.defaultProps = {
  username: '',
};

ResetPasswordModal.propTypes = {
  email: PropTypes.string.isRequired,
  resetPasswordByAdmin: PropTypes.func.isRequired,
  resetPasswordToken: PropTypes.func.isRequired,
  username: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

export default ResetPasswordModal;
