import { useCallback } from 'react';
import params from '../../utils/params';

const useSearch = (history) => {
  const { getParams, setParam } = params(history);
  const { search } = getParams();

  const onChange = useCallback((value) => {
    setParam('search', value);
    setParam('page');
  }, []);

  return [
    search,
    onChange,
  ];
};

export default useSearch;
