import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimmer, Message, Modal, Loader,
} from 'semantic-ui-react';

import { RESET_PASSWORD_MODAL_METHODS } from '../../../../constants/resetPasswordModalMethods';

const UpdatePasswordMessage = ({
  emailSent, errorMessage, loading, selectedMethod, resetSuccess,
}) => {
  const [translate] = useTranslation();

  const successMessage = selectedMethod === RESET_PASSWORD_MODAL_METHODS.RESET_PASSWORD
    ? translate('PASSWORD_HAS_BEEN_CHANGED')
    : translate('RESET_PASSWORD_EMAIL_HAS_BEEN_SENT');

  return (
    <Modal.Content>
      {loading && (
        <Dimmer active inverted>
          <Loader active inline content={translate('SENDING')} />
        </Dimmer>
      )}

      {!!errorMessage && <Message content={errorMessage} negative size="mini" />}

      {(emailSent || resetSuccess) && <Message content={successMessage} positive size="mini" />}
    </Modal.Content>
  );
};

UpdatePasswordMessage.propTypes = {
  emailSent: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedMethod: PropTypes.number.isRequired,
  resetSuccess: PropTypes.bool.isRequired,
};

export default UpdatePasswordMessage;
