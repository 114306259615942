import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrganizationStatuses from './OrganizationStatuses';
import {
  fetchOrganizationStatusesRequest, updateOrganizationStatusRequest,
  deleteOrganizationStatusRequest, newOrganizationStatusRequest, openModal, closeModal,
} from '../../actions/organizationStatuses';

const mapStateToProps = ({
  organizationStatuses: {
    loading, byId, byHash, newOrganizationStatusModalOpened,
  },
}) => ({
  statuses: byId.map(id => byHash[id]),
  loading,
  newOrganizationStatusModalOpened,
  count: byId.length,
});

const mapDispatchToProps = dispatch => ({
  fetchOrganizationStatuses: bindActionCreators(fetchOrganizationStatusesRequest, dispatch),
  updateOrganizationStatus: bindActionCreators(updateOrganizationStatusRequest, dispatch),
  deleteOrganizationStatus: bindActionCreators(deleteOrganizationStatusRequest, dispatch),
  addOrganizationStatus: bindActionCreators(newOrganizationStatusRequest, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationStatuses);
