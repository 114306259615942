import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import params from '../../utils/params';

const useFilter = (history) => {
  const { getParams, setParam } = params(history);
  const { filters, operators } = getParams();

  const onChange = useCallback((filtersValue, operatorsValue) => {
    setParam('filters', !isEmpty(filtersValue) ? JSON.stringify(filtersValue) : '');
    setParam('operators', !isEmpty(operatorsValue) ? JSON.stringify(operatorsValue) : '');
    setParam('page');
  }, []);

  return [
    filters ? JSON.parse(filters) : {},
    operators ? JSON.parse(operators) : {},
    onChange,
  ];
};

export default useFilter;
