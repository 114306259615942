import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UpdatePasswordForm from './UpdatePasswordForm';
import { resetPasswordRequest } from '../../../../packages/authentication';

const mapStateToProps = ({ authentication }) => ({
  errorMessage: authentication.resetPasswordErrorMessage,
  loading: authentication.resetPasswordLoading,
  success: authentication.resetPasswordSuccess,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPasswordRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
