import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_REQUEST,
  NEW_ROLE_REQUEST,
  NEW_ROLE_SUCCESS,
  NEW_ROLE_MODAL_OPEN,
  NEW_ROLE_MODAL_CLOSE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_REQUEST,
} from '../constants/actionTypes';

export const fetchRolesRequest = () => ({
  type: FETCH_ROLES_REQUEST,
});

export const fetchRolesSuccess = roles => ({
  type: FETCH_ROLES_SUCCESS,
  payload: {
    roles,
  },
});

export const updateRoleRequest = role => ({
  type: UPDATE_ROLE_REQUEST,
  payload: {
    role,
  },
});

export const updateRoleSuccess = updatedRole => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: {
    updatedRole,
  },
});

export const newRoleRequest = role => ({
  type: NEW_ROLE_REQUEST,
  payload: {
    role,
  },
});

export const newRoleSuccess = newRole => ({
  type: NEW_ROLE_SUCCESS,
  payload: {
    newRole,
  },
});

export const openModal = () => ({
  type: NEW_ROLE_MODAL_OPEN,
});

export const closeModal = () => ({
  type: NEW_ROLE_MODAL_CLOSE,
});

export const deleteRoleRequest = id => ({
  type: DELETE_ROLE_REQUEST,
  payload: {
    id,
  },
});

export const deleteRoleSuccess = id => ({
  type: DELETE_ROLE_SUCCESS,
  payload: {
    id,
  },
});
