import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import App from './App';
import setMenuExpanded from '../../actions/application';
import { signOut } from '../../packages/authentication';

const mapDispatchToProps = dispatch => bindActionCreators({
  setMenuExpanded,
  signOut,
}, dispatch);

const mapStateToProps = ({ application: { menuExpanded } }) => ({
  menuExpanded,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
