import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';

import styles from './CustomAttributesList.module.scss';

const CustomAttributesList = ({
  upsertCustomAttributes, customAttributes = [], errors, handleBlur,
}) => {
  const [translate] = useTranslation();

  const getEmptyCustomAttribute = () => ({
    key: uniqueId('_key'),
    id: null,
    name: '',
    value: '',
  });

  const handleAddCustomAttribute = () => {
    upsertCustomAttributes(customAttributes.concat(getEmptyCustomAttribute()));
  };

  const handleRemoveCustomAttribute = key => () => {
    if (customAttributes.length === 1) {
      upsertCustomAttributes(customAttributes.map(ca => ({
        ...ca,
        name: '',
        value: '',
      })));
    } else {
      upsertCustomAttributes(customAttributes.filter(ca => ca.key !== key));
    }
  };

  const handleChangeCustomAttribute = key => (event, { name, value }) => {
    upsertCustomAttributes(customAttributes.map((ca) => {
      if (ca.key !== key) {
        return ca;
      }
      return ({
        ...ca,
        [name]: value,
      });
    }));
  };

  useEffect(() => {
    if (!customAttributes.length) {
      upsertCustomAttributes([getEmptyCustomAttribute()]);
    }
  }, [upsertCustomAttributes, customAttributes]);

  return (
    <div className={styles.customAttributesListWrapper}>
      <Header as="h4" className={styles.customAttributesHeader}>{translate('CUSTOM_ATTRIBUTES')}</Header>
      {customAttributes.map(({
        key, id, name, value,
      }, index) => (
        <Form.Group inline className={styles.customAttributeRow} key={key || id}>
          <div className={styles.customAttributeInputWrapper}>
            <label htmlFor="language">{translate('NAME')}</label>
            <Form.Input
              value={name}
              id="custom-attr-name"
              className={styles.caInput}
              name="name"
              onChange={handleChangeCustomAttribute(key)}
              onBlur={handleBlur}
              placeholder={translate('CUSTOM_ATTRIBUTE_NAME')}
              maxLength={50}
              error={!name && errors.customAttributes && translate('CUSTOM_ATTRIBUTE_NAME_REQUIRED')}
            />
          </div>
          <div className={styles.customAttributeInputWrapper}>
            <label htmlFor="value">{translate('VALUE')}</label>
            <Form.Input
              value={value}
              id="custom-attr-value"
              className={styles.caInput}
              name="value"
              maxLength={50}
              onChange={handleChangeCustomAttribute(key)}
              onBlur={handleBlur}
              error={!value && errors.customAttributes && translate('CUSTOM_ATTRIBUTE_VALUE_REQUIRED')}
              placeholder={translate('CUSTOM_ATTRIBUTE_VALUE')}
            />
          </div>
          <div className={styles.actions}>
            {customAttributes.length > 0 && (
              <Button icon="minus" className={styles.dynamicListButton} circular primary basic size="mini" onClick={handleRemoveCustomAttribute(key)} />
            )}
            {index === customAttributes.length - 1 && (
              <Button icon="plus" className={styles.dynamicListButton} circular primary basic size="mini" onClick={handleAddCustomAttribute} />
            )}
          </div>
        </Form.Group>
      ))}
    </div>
  );
};

CustomAttributesList.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  upsertCustomAttributes: PropTypes.func.isRequired,
  customAttributes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errors: PropTypes.shape().isRequired,
};

export default CustomAttributesList;
