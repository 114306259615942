import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Header, Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styles from './ChildrenOrgSection.module.scss';
import { ORGANIZATIONS_ROUTE } from '../../../../constants/customizations';

const ChildrenOrgSection = ({ childrenOrganizations }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.childrenOrganiationsWrapper}>
      <Header as="h4" className={styles.sectionHeader}>{translate('SUB_ORGANIZATIONS')}</Header>
      <Table basic="very" className={styles.subOrgTable}>
        <Table.Body>
          {childrenOrganizations.map(({ id, name }) => (
            <Table.Row key={id}>
              <Table.Cell className={styles.orgName}>
                {name}
              </Table.Cell>
              <Table.Cell collapsing>
                <Link to={`${ORGANIZATIONS_ROUTE}?activeId=${id}&search=${name}`}>
                  {translate('VIEW_DETAILS')}
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

ChildrenOrgSection.propTypes = {
  childrenOrganizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ChildrenOrgSection;
