import PropTypes from 'prop-types';
import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

import styles from '../Sidebar.module.scss';

const ButtonWithPopup = ({ title, ...props }) => (
  <Popup
    className={styles.popup}
    content={title}
    on="hover"
    popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
    position="right center"
    trigger={(
      <Button {...props} />
    )}
  />
);

ButtonWithPopup.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ButtonWithPopup;
