import isObject from 'lodash/isObject';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useForm = (validationSchema) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [translate] = useTranslation();
  const [visitedFields, setVisitedFields] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event, { name, value }) => {
    if (event) {
      event.persist();
    }
    setValues(data => ({
      ...data,
      [name]: value,
    }));
  };

  const validate = (allFields = true) => {
    const toValidate = allFields ? Object.keys(validationSchema) : visitedFields;
    const fieldsErrors = toValidate.reduce((acc, fieldName) => {
      const error = (validationSchema[fieldName] || [])
        .filter(isObject)
        .map(validator => !validator.rule(values[fieldName]) && validator)
        .find(Boolean);

      return {
        ...acc,
        ...(error && { [fieldName]: translate(error.errorMessage, error.errorMessageParams) }),
      };
    }, {});

    setErrors(fieldsErrors);

    return !Object.keys(fieldsErrors).length;
  };

  const clearForm = (data) => {
    setValues(data);
    setErrors({});
  };

  const handleBlur = (event, data) => {
    const { name } = data || event.target;
    setVisitedFields((visited) => {
      if (!visited.includes(name)) {
        return [name, ...visited];
      }
      return visited;
    });
  };

  useEffect(() => {
    validate(formSubmitted || false);
  }, [values, visitedFields]);

  return {
    handleChange,
    values,
    setValues,
    validate,
    errors,
    clearForm,
    handleBlur,
    setFormSubmitted,
  };
};

export default useForm;
