import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { ORGANIZATIONS_STATUSES_PATH } from '../../constants/paths';
import {
  NEW_ORGANIZATION_STATUS_ERROR,
  NEW_ORGANIZATION_STATUS_REQUEST,
  NEW_ORGANIZATION_STATUS_SUCCESS,
} from '../../constants/actionTypes';
import { newOrganizationStatusSuccess, closeModal } from '../../actions/organizationStatuses';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(NEW_ORGANIZATION_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { status } }, { authentication: { authToken } }]) => ajax({
    url: ORGANIZATIONS_STATUSES_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(status),
  }).pipe(
    mergeMap(({ response: newOrgStatus }) => [
      addNotification(NotificationType.SUCCESS, NEW_ORGANIZATION_STATUS_SUCCESS),
      newOrganizationStatusSuccess(newOrgStatus),
      closeModal(),
    ]),
    catchError(error => of(
      handleError(error, NEW_ORGANIZATION_STATUS_ERROR),
    )),
  )),
);
