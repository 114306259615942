import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { USER_STATUSES_PATH } from '../../constants/paths';
import { NEW_USER_STATUS_ERROR, NEW_USER_STATUS_REQUEST, NEW_USER_STATUS_SUCCESS } from '../../constants/actionTypes';
import { newUserStatusSuccess, closeModal } from '../../actions/userStatuses';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(NEW_USER_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { status } }, { authentication: { authToken } }]) => ajax({
    url: USER_STATUSES_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(status),
  }).pipe(
    mergeMap(({ response: newUserStatus }) => [
      addNotification(NotificationType.SUCCESS, NEW_USER_STATUS_SUCCESS),
      newUserStatusSuccess(newUserStatus),
      closeModal(),
    ]),
    catchError(error => of(handleError(error, NEW_USER_STATUS_ERROR))),
  )),
);
