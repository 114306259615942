export const SIGN_IN_ROUTE = '/login';
export const RESET_PASSWORD = '/password-reset';
export const HOME_ROUTE = '/';
export const USERS_ROUTE = '/users';
export const ORGANIZATIONS_ROUTE = '/organizations';
export const SETTINGS_ROUTE = '/settings';
export const ROLES_MANAGEMENT_ROUTE = `${SETTINGS_ROUTE}/roles`;
export const USER_STATUSES_ROUTE = `${SETTINGS_ROUTE}/user-statuses`;
export const ORGANIZATION_STATUSES_ROUTE = `${SETTINGS_ROUTE}/organization-statuses`;

export const APP_NAME = 'admin';
export const DROPDOWN_OPTIONS_LIMIT = 10;
export const DEFAULT_LIST_LIMIT = 10;
export const ORGANIZATIONS_LIST_LIMIT = DEFAULT_LIST_LIMIT;
export const USERS_LIST_LIMIT = DEFAULT_LIST_LIMIT;
export const PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';

export const NOTIFICATION_DISPLAY_TIME = 5000;

export const ROLES = ['ADMIN', 'USER'];
export const REQUIRED_ROLES = ['ADMIN'];
