import { ERROR } from '../constants/actionTypes';

export const handleError = (error, actionType, props) => ({
  type: ERROR,
  payload: {
    error,
    actionType,
    props,
  },
});

export default handleError;
