import params from '../../utils/params';
import { DEFAULT_LIST_LIMIT } from '../../constants/customizations';

export default (history, count, loadingList, limit = DEFAULT_LIST_LIMIT) => {
  const { setParam } = params(history);

  const onPageChange = (event, { activePage }) => {
    setParam('page', activePage);
  };

  const totalPages = Math.ceil(count / limit);
  const showPagination = limit < count;

  return [onPageChange, totalPages, showPagination];
};
