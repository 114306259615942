import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import set from 'lodash/set';
import uniqueId from 'lodash/uniqueId';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Text from '../../../../components/Text';
import styles from './OrganizationsForm.module.scss';

const getEmptyOrganization = () => ({
  key: uniqueId('_key'),
  organizationId: null,
  organizationName: '',
  organizationRole: '',
});

const OrganizationsForm = ({
  fetchOrganizations, handleChange, memberships,
  organizationsByHash, organizationsOptions, rolesOptions,
}) => {
  const [translate] = useTranslation();
  const [searchOrganization, setSearchOrganization] = useState('');

  useEffect(() => {
    fetchOrganizations({ search: searchOrganization });
  }, [fetchOrganizations, searchOrganization]);

  const setOrganizations = (organizations) => {
    handleChange(null, { name: 'memberships', value: organizations });
  };

  useEffect(() => {
    if (!memberships.length) {
      setOrganizations([getEmptyOrganization()]);
    }
  }, [setOrganizations, memberships]);

  const handleAddOrganization = () => {
    setOrganizations(memberships.concat(getEmptyOrganization()));
  };

  const handleRemoveOrganization = key => () => {
    setOrganizations(memberships.filter(member => member.key !== key));
  };

  const findMembershipIndex = key => findIndex(memberships, member => member.key === key);

  const handleChangeMember = key => (event, { name, value }) => {
    const index = findMembershipIndex(key);
    const newMemberships = [...memberships];
    set(newMemberships[index], name, value);
    setOrganizations(newMemberships);
  };

  const handleChangeOrganization = key => (event, { value }) => {
    const index = findMembershipIndex(key);
    const newMemberships = [...memberships];
    const organizationName = get(organizationsByHash, [value, 'name'], '');
    set(newMemberships[index], 'organizationId', value);
    set(newMemberships[index], 'organizationName', organizationName);
    setOrganizations(newMemberships);
  };

  const handleSearch = (event, { searchQuery }) => {
    setSearchOrganization(searchQuery);
  };

  const dropdownProps = {
    selection: true, fluid: true,
  };

  return (
    <div className={styles.organizationsWrapper} key="organizations">
      <Text label>{translate('ORGANIZATION')}</Text>

      <div>
        {memberships.map(({
          key, organizationId, organizationName, organizationRole,
        }, index) => (
          <div className={styles.organizationRow} key={key}>
            <Form.Dropdown
              {...dropdownProps}
              defaultValue={organizationId}
              onChange={handleChangeOrganization(key)}
              onSearchChange={handleSearch}
              search
              options={[
                ...organizationsOptions.filter(({ value }) => value !== organizationId),
                { value: organizationId, text: organizationName },
              ]}
            />
            <Form.Dropdown
              {...dropdownProps}
              defaultValue={organizationRole}
              name="organizationRole"
              onChange={handleChangeMember(key)}
              options={rolesOptions.map(roleOption => ({
                ...roleOption, text: translate(`CUSTOM_ROLE.${roleOption.text}`, roleOption.text),
              }))}
            />
            <div className={styles.actions}>
              {memberships.length > 1 && (
                <Button icon="minus" circular primary basic size="mini" onClick={handleRemoveOrganization(key)} />
              )}
              {index === memberships.length - 1 && (
                <Button icon="plus" circular primary basic size="mini" onClick={handleAddOrganization} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

OrganizationsForm.defaultProps = {
  memberships: [],
};

OrganizationsForm.propTypes = {
  fetchOrganizations: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  memberships: PropTypes.arrayOf(PropTypes.shape({})),
  organizationsByHash: PropTypes.shape({}).isRequired,
  organizationsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rolesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrganizationsForm;
