import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Roles from './Roles';
import {
  fetchRolesRequest, updateRoleRequest, deleteRoleRequest, newRoleRequest, openModal, closeModal,
} from '../../actions/roles';

const mapStateToProps = ({
  roles: {
    loading, byId, byHash, newRoleModalOpened,
  },
}) => ({
  roles: byId.map(id => byHash[id]),
  loading,
  newRoleModalOpened,
  count: byId.length,
});

const mapDispatchToProps = dispatch => ({
  fetchRoles: bindActionCreators(fetchRolesRequest, dispatch),
  updateRole: bindActionCreators(updateRoleRequest, dispatch),
  deleteRole: bindActionCreators(deleteRoleRequest, dispatch),
  addNewRole: bindActionCreators(newRoleRequest, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
