import findKey from 'lodash/findKey';

const userEnrichEdit = (user, { rolesByHash }) => {
  const {
    accountStatusId, country, firstName,
    language, lastName, role, memberships,
    customAttributes, ...rest
  } = user;

  const getRoleId = roleName => +findKey(rolesByHash, ({ name }) => name === roleName);

  return {
    accountStatusId,
    customAttributes: customAttributes
      .map(({ key, ...restAttributes }) => restAttributes)
      .filter(attr => attr.name && attr.value),
    country,
    firstName,
    language,
    lastName,
    role,
    rolesInOrganizations: memberships
      .filter(({ organizationId }) => organizationId)
      .map(({ organizationId, organizationRole }) => ({
        organizationId,
        roleId: getRoleId(organizationRole),
      })),
    ...rest,
  };
};

export default userEnrichEdit;
