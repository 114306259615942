import PropTypes from 'prop-types';
import React from 'react';

import styles from './FilterDatePickerOverlay.module.scss';

const CustomOverlay = ({
  classNames, selectedDay, children, ...props
}) => (
  <div className={`${classNames.overlayWrapper} ${styles.wrapper}`} {...props}>
    <div className={classNames.overlay}>
      {children}
    </div>
  </div>
);

CustomOverlay.propTypes = {
  classNames: PropTypes.shape({}).isRequired,
  selectedDay: PropTypes.arrayOf(Date).isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomOverlay;
