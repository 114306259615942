import {
  hasCapitalLetter,
  hasMinLength,
  hasNumber,
  hasSpecialCharacter,
  isRequired,
  isValidEmail,
  hasValidAttributes,
} from '../../utils/formValidators';

export const validationSchemaEdit = {
  firstName: [isRequired],
  lastName: [isRequired],
  email: [isRequired, isValidEmail],
  role: [isRequired],
  customAttributes: [hasValidAttributes],
};

export const validationSchemaCreate = {
  ...validationSchemaEdit,
  password: [isRequired, hasCapitalLetter, hasNumber, hasSpecialCharacter, hasMinLength(8)],
  username: [isRequired],
};

export default validationSchemaEdit;
