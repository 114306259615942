import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ButtonAdd from '../../components/ButtonAdd';
import List from '../../components/List';
import NewOrganizationStatusModal from './components/NewOrganizationStatusModal';
import OrganizationStatusListItem from './components/OrganizationStatusListItem';
import WrapperBackdrop from '../../components/WrapperBackdrop';
import columns from './columns';
import styles from './OrganizationStatuses.module.scss';
import { ACTIVE, INACTIVE } from '../../constants/statusActiveness';

const OrganizationStatuses = ({
  statuses, loading, fetchOrganizationStatuses, addOrganizationStatus,
  newOrganizationStatusModalOpened, openModal, closeModal, count,
}) => {
  const [organizationStatusToUpdate, setOrganizationStatusToUpdate] = useState();

  useEffect(() => {
    fetchOrganizationStatuses();
  }, []);

  const renderItems = () => statuses.map(organizationStatus => (
    <OrganizationStatusListItem
      key={organizationStatus.id}
      organizationStatus={organizationStatus}
      organizationStatusToUpdate={organizationStatusToUpdate}
      setOrganizationStatusToUpdate={setOrganizationStatusToUpdate}
    />
  ));

  const handleNewOrganizationStatus = () => {
    setOrganizationStatusToUpdate(null);
    openModal();
  };

  return [
    <div className={styles.container} key="container">
      <div className={styles.header}>
        <ButtonAdd handleClick={handleNewOrganizationStatus} title="ADD_NEW" />
      </div>
      <WrapperBackdrop>
        <List
          columns={columns}
          loading={loading}
          emptyTableText="NO_ORGANIZATION_STATUSES_DEFINED_YET"
          count={count}
        >
          {renderItems()}
        </List>
      </WrapperBackdrop>
    </div>,
    newOrganizationStatusModalOpened && (
      <NewOrganizationStatusModal
        closeModal={closeModal}
        addOrganizationStatus={addOrganizationStatus}
        loading={loading}
        key="modal"
      />
    ),
  ];
};

OrganizationStatuses.propTypes = {
  fetchOrganizationStatuses: PropTypes.func.isRequired,
  addOrganizationStatus: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  newOrganizationStatusModalOpened: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.oneOf([ACTIVE, INACTIVE]),
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
};

export default OrganizationStatuses;
