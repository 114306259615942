import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button, Menu, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonWithPopup from './ButtonWithPopup';
import styles from './Sidebar.module.scss';
import { locationType } from '../../constants/propTypes';
import {
  ORGANIZATIONS_ROUTE,
  ORGANIZATION_STATUSES_ROUTE,
  ROLES_MANAGEMENT_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE,
  USER_STATUSES_ROUTE,
} from '../../constants/customizations';

const isActive = (pathname, route) => {
  const [path0] = pathname.substr(1).split('/');
  return `/${path0}` === route;
};

const isActiveSubmenu = (pathname, route) => {
  const [, subPathname] = pathname.substr(1).split('/');
  const [, routePath] = route.substr(1).split('/');
  return subPathname === routePath;
};

const Sidebar = ({
  signOut, location: { pathname, search },
}) => {
  const [translate] = useTranslation();

  useEffect(() => window.scrollTo(0, 0), [pathname, search]);

  const buttonProps = {
    circular: true,
    color: 'grey',
    as: Link,
  };

  return [
    <Menu vertical key="menu1" className={classNames('collapsed', styles.sidebar)}>
      <div>
        <Button
          {...buttonProps}
          icon={<Image src="/images/icons/logo.svg" />}
          to="/"
        />
        <ButtonWithPopup
          {...buttonProps}
          active={isActive(pathname, USERS_ROUTE)}
          icon={<Image src="/images/icons/users.svg" />}
          to={USERS_ROUTE}
          title={translate('USERS')}
        />
        <ButtonWithPopup
          {...buttonProps}
          active={isActive(pathname, ORGANIZATIONS_ROUTE)}
          icon={<Image src="/images/icons/group.svg" />}
          to={ORGANIZATIONS_ROUTE}
          title={translate('ORGANIZATIONS')}
        />
        <ButtonWithPopup
          {...buttonProps}
          active={isActive(pathname, SETTINGS_ROUTE)}
          icon={<Image src="/images/icons/settings.svg" />}
          to={SETTINGS_ROUTE}
          title={translate('SETTINGS')}
        />
      </div>
      <div>
        <ButtonWithPopup
          circular
          color="grey"
          icon={<Image src="/images/icons/logout.svg" />}
          onClick={signOut}
          title={translate('LOGOUT')}
        />
      </div>
    </Menu>,
    isActive(pathname, SETTINGS_ROUTE) && (
      <Menu vertical key="menu2" secondary>
        <Menu.Item>
          <Menu.Header
            content={translate('USER_MANAGEMENT')}
          />
          <Menu.Item
            active={isActiveSubmenu(pathname, USER_STATUSES_ROUTE)}
            as={Link}
            to={USER_STATUSES_ROUTE}
            name={translate('USER_STATUSES')}
          />
          <Menu.Item
            active={isActiveSubmenu(pathname, ROLES_MANAGEMENT_ROUTE)}
            as={Link}
            to={ROLES_MANAGEMENT_ROUTE}
            name={translate('USER_ROLES')}
          />
        </Menu.Item>
        <Menu.Item>
          <Menu.Header
            content={translate('ORGANIZATION_MANAGEMENT')}
          />
          <Menu.Item
            active={isActiveSubmenu(pathname, ORGANIZATION_STATUSES_ROUTE)}
            as={Link}
            to={ORGANIZATION_STATUSES_ROUTE}
            name={translate('ORGANIZATION_STATUSES')}
          />
        </Menu.Item>
      </Menu>
    ),
  ];
};

Sidebar.defaultProps = {
  menuExpanded: null,
};

Sidebar.propTypes = {
  location: locationType.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Sidebar;
