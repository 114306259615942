import get from 'lodash/get';

import {
  ERROR,
  ORGANIZATIONS_FETCH_REQUEST,
  ORGANIZATIONS_FETCH_SUCCESS,
  ORGANIZATION_CREATE_REQUEST,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_EDIT_REQUEST,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_FETCH_REQUEST,
  ORGANIZATION_FETCH_SUCCESS,
  ORGANIZATIONS_CHILDREN_FETCH_REQUEST,
  ORGANIZATIONS_CHILDREN_FETCH_SUCCESS,
  ORGANIZATIONS_CHILDREN_FETCH_ERROR,
  ASSIGN_PARENT_ORGANIZATION_ERROR,
  HELPER_ORGANIZATIONS_FETCH_SUCCESS,
  ASSIGN_PARENT_ORGANIZATION_SUCCESS,
  ASSIGN_PARENT_ORGANIZATION_REQUEST,
} from '../constants/actionTypes';
import { DEFAULT_LIST_LIMIT } from '../constants/customizations';

const initialState = {
  count: null,
  error: null,
  loading: false,
  loadingList: false,
  loadingChildren: false,
  byHash: {},
  byId: [],
  detailsById: [],
  helperOrgList: [],
  helperOrgListLoading: false,
  errorAssignParentId: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ORGANIZATIONS_FETCH_REQUEST:
      return {
        ...state,
        loadingList: !payload.saveTempOrgList,
        helperOrgListLoading: !!payload.saveTempOrgList,
      };
    case ORGANIZATIONS_CHILDREN_FETCH_REQUEST:
      return {
        ...state,
        loadingChildren: true,
      };
    case ORGANIZATIONS_CHILDREN_FETCH_SUCCESS:
      return {
        ...state,
        loadingChildren: false,
        byHash: {
          ...state.byHash,
          [payload.parentId]: {
            ...state.byHash[payload.parentId],
            children: [...payload.organizations],
          },
        },
      };
    case HELPER_ORGANIZATIONS_FETCH_SUCCESS: {
      return {
        ...state,
        helperOrgList: [...payload.organizations],
        helperOrgListLoading: false,
      };
    }
    case ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loadingList: false,
        byHash: payload.organizations
          .reduce((byHash, organization) => ({
            ...byHash,
            [organization.id]: {
              ...byHash[organization.id],
              ...organization,
            },
          }), state.byHash),
        byId: payload.organizations.map(user => user.id),
        count: payload.count,
      };
    case ORGANIZATION_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORGANIZATION_CREATE_REQUEST:
    case ORGANIZATION_EDIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case ORGANIZATION_CREATE_SUCCESS: {
      const { organization } = payload;
      const { byHash, count, byId } = state;

      return {
        ...state,
        loading: false,
        count: count + 1,
        byHash: {
          ...byHash,
          [organization.id]: {
            ...byHash[organization.id],
            ...organization,
          },
        },
        byId: [
          organization.id,
          ...byId,
        ].slice(0, DEFAULT_LIST_LIMIT),
        detailsById: [
          organization.id,
          ...state.detailsById.filter(id => organization.id !== id),
        ],
      };
    }
    case ASSIGN_PARENT_ORGANIZATION_REQUEST:
    case ASSIGN_PARENT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        errorAssignParentId: null,
      };
    case ORGANIZATION_EDIT_SUCCESS:
    case ORGANIZATION_FETCH_SUCCESS: {
      const { organization } = payload;
      const { byHash } = state;

      return {
        ...state,
        loading: false,
        byHash: {
          ...byHash,
          [organization.id]: {
            ...byHash[organization.id],
            ...organization,
          },
        },
        detailsById: [
          organization.id,
          ...state.detailsById.filter(id => organization.id !== id),
        ],
      };
    }
    case ERROR: {
      switch (payload.actionType) {
        case ASSIGN_PARENT_ORGANIZATION_ERROR:
          return {
            ...state,
            errorAssignParentId: payload.props.organizationId,
          };
        case ORGANIZATIONS_CHILDREN_FETCH_ERROR:
          return {
            ...state,
            loadingChildren: false,
          };
        case ORGANIZATIONS_FETCH_REQUEST:
        case ORGANIZATION_FETCH_REQUEST:
          return {
            ...state,
            loading: false,
            loadingList: false,
          };
        case ORGANIZATION_CREATE_REQUEST:
        case ORGANIZATION_EDIT_REQUEST:
          return {
            ...state,
            error: get(payload, 'error.response.message'),
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
};
