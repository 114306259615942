import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ButtonAdd from '../../components/ButtonAdd';
import List from '../../components/List';
import NewUserStatusModal from './components/NewUserStatusModal';
import UserStatusListItem from './components/UserStatusListItem';
import columns from './columns';
import styles from './UserStatuses.module.scss';
import { ACTIVE, INACTIVE } from '../../constants/statusActiveness';
import WrapperBackdrop from '../../components/WrapperBackdrop';

const UserStatuses = ({
  statuses, loading, fetchUserStatuses, addNewUserStatus, count,
  newUserStatusModalOpened, openModal, closeModal,
}) => {
  const [userStatusToUpdate, setUserStatusToUpdate] = useState(null);

  useEffect(() => {
    fetchUserStatuses();
  }, []);

  const renderItems = () => statuses.map(userStatus => (
    <UserStatusListItem
      key={userStatus.id}
      userStatus={userStatus}
      status={userStatus.status}
      userStatusToUpdate={userStatusToUpdate}
      setUserStatusToUpdate={setUserStatusToUpdate}
    />
  ));

  const handleNewUserStatus = () => {
    setUserStatusToUpdate(null);
    openModal();
  };

  return [
    <div className={styles.container} key="container">
      <div className={styles.header}>
        <ButtonAdd handleClick={handleNewUserStatus} title="ADD_NEW" />
      </div>
      <WrapperBackdrop>
        <List
          columns={columns}
          emptyTableText="NO_USER_STATUSES_DEFINED_YET"
          loading={loading}
          count={count}
        >
          {renderItems()}
        </List>
      </WrapperBackdrop>
    </div>,
    newUserStatusModalOpened && (
      <NewUserStatusModal
        closeModal={closeModal}
        addNewUserStatus={addNewUserStatus}
        loading={loading}
        key="modal"
      />
    ),
  ];
};

UserStatuses.propTypes = {
  fetchUserStatuses: PropTypes.func.isRequired,
  addNewUserStatus: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  newUserStatusModalOpened: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.oneOf([ACTIVE, INACTIVE]),
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
};

export default UserStatuses;
