import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import OrganizationsListItemNew from './OrganizationsListItemNew';
import { createOrganizationRequest } from '../../../../actions/organizations';

const mapDispatchToProps = dispatch => ({
  createOrganization: bindActionCreators(createOrganizationRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(OrganizationsListItemNew);
