import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Text.module.scss';

const Text = ({
  bold,
  children,
  translate,
  placeholder,
  ellipsis,
  label,
  classNames: componentClassNames,
}) => {
  const [trans] = useTranslation();

  return (
    <span
      className={classNames(
        styles.default,
        bold && styles.bold,
        placeholder && styles.placeholder,
        ellipsis && styles.ellipsis,
        label && styles.label,
        componentClassNames,
      )}
    >
      {translate ? trans(children) : children}
    </span>
  );
};

Text.propTypes = {
  bold: PropTypes.bool,
  classNames: PropTypes.string,
  translate: PropTypes.bool,
  placeholder: PropTypes.bool,
  ellipsis: PropTypes.bool,
  label: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

Text.defaultProps = {
  bold: false,
  classNames: '',
  translate: false,
  children: '',
  placeholder: false,
  ellipsis: false,
  label: false,
};

export default Text;
