import { connect } from 'react-redux';
import UpdatePasswordMessage from './UpdatePasswordMessage';

const mapStateToProps = ({
  authentication: {
    resetPasswordEmailSent, resetPasswordSuccess, resetPasswordErrorMessage, resetPasswordLoading,
  },
}) => ({
  emailSent: resetPasswordEmailSent,
  resetSuccess: resetPasswordSuccess,
  errorMessage: resetPasswordErrorMessage,
  loading: resetPasswordLoading,
});

export default connect(mapStateToProps)(UpdatePasswordMessage);
