import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Dropdown, Loader,
} from 'semantic-ui-react';

import ListItem from '../../../../components/List/components/ListItem';
import MembersList from '../MembersList';
import OrganizationDetails from '../OrganizationDetails';
import OrganizationForm from '../OrganizationForm';
import StatusCell from '../../../../components/StatusCell';
import Text from '../../../../components/Text';
import styles from './OrganizationsListItem.module.scss';
import useForm from '../../../../hooks/useForm';
import { validationSchema } from '../../schema';
import { formatDate } from '../../../../utils/dateUtils';
import params from '../../../../utils/params';

const filterOptions = ['VIEW_ALL', 'ADMIN', 'USER', 'INACTIVE'];

const OrganizationsListItem = ({
  detailsById, fetchOrganization, organization, columns,
  editOrganization, history, expandedId, setExpandedId, showStatuses,
  fetchChildrenOrganizations, assignParentOrganization, errorAssignParentId,
}) => {
  const { setParam, getParams } = params(history);
  const [translate] = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState('VIEW_ALL');
  const { activeId } = getParams();
  const [selectedParentOrg, setSelectedParentOrg] = useState(null);
  const {
    values, setValues, handleChange, validate, errors, clearForm, handleBlur, setFormSubmitted,
  } = useForm(validationSchema);
  const {
    id, name, userCount, createdAt, accountStatus, users, parent,
  } = organization;

  const onExpand = () => {
    const newExpandedId = expandedId === id ? null : id;
    setExpandedId(newExpandedId);
    setParam('activeId', newExpandedId);
  };

  useEffect(() => {
    if (activeId && +activeId === id) {
      fetchChildrenOrganizations(activeId);
    }
  }, [fetchChildrenOrganizations, activeId]);

  useEffect(() => {
    if (expandedId && expandedId === id) {
      fetchOrganization(expandedId);
    }
  }, [expandedId, id]);

  useEffect(() => {
    setValues(organization);
  }, [organization]);

  const handleChangeFilter = (event, { value }) => {
    setFilter(value);
  };

  useEffect(() => {
    if (!activeId && errorAssignParentId) {
      setParam('activeId', errorAssignParentId);
    }
  }, [errorAssignParentId]);

  const handleSave = () => {
    setFormSubmitted(true);
    if (validate()) {
      if ((parent && selectedParentOrg && selectedParentOrg !== parent.id)
        || (!parent && selectedParentOrg)) {
        assignParentOrganization(values, selectedParentOrg, editOrganization);
      } else {
        editOrganization(values, selectedParentOrg);
      }
      clearForm(organization);
      setSelectedParentOrg(null);
      setShowForm(false);
    }
  };

  const handleCancel = () => {
    clearForm(organization);
    setSelectedParentOrg(null);
    setShowForm(false);
  };

  const handleEdit = () => {
    setShowForm(true);
  };

  const renderExpandedWrapper = () => (
    <div className={styles.expandedWrapper}>
      {showForm ? (
        <OrganizationForm
          organization={values}
          handleChange={handleChange}
          errors={errors}
          handleBlur={handleBlur}
          setSelectedParentOrg={setSelectedParentOrg}
          selectedParentOrg={selectedParentOrg}
        />
      ) : (
        <OrganizationDetails organization={organization} key="details" />
      )}

      <MembersList filter={filter} members={users} key="list" />

      {showForm ? (
        <div className={styles.actionWrapper}>
          <Button primary content={translate('CANCEL')} onClick={handleCancel} basic className="simple" />
          <Button primary content={translate('SAVE')} onClick={handleSave} disabled={!!Object.keys(errors).length} />
        </div>
      ) : (
        <div className={styles.actionWrapper}>
          <Button primary basic icon="edit" className="simple" size="big" onClick={handleEdit} />
          {/* TODO: Implement delete logic when backend is ready */}
          {/* <Button primary basic icon="trash" className="simple" size="big" /> */}
        </div>
      )}

      <div className={styles.filterWrapper}>
        <Dropdown
          button
          className="right labeled icon"
          defaultValue="VIEW_ALL"
          options={filterOptions.map(value => ({ value, text: translate(value) }))}
          onChange={handleChangeFilter}
        />
      </div>

      <Dimmer active={!detailsById.includes(id)} inverted>
        <Loader active inline />
      </Dimmer>
    </div>
  );

  return (
    <ListItem
      key={id}
      onExpand={onExpand}
      expandable
      expanded={expandedId === id}
      columns={columns}
    >
      <Text bold>{`${name}`}</Text>
      <Text placeholder={!userCount}>{userCount || translate('NONE')}</Text>
      <Text>{createdAt && formatDate(createdAt)}</Text>
      {showStatuses && <StatusCell status={accountStatus} />}
      {renderExpandedWrapper()}
    </ListItem>
  );
};

OrganizationsListItem.defaultProps = {
  expandedId: null,
  errorAssignParentId: null,
};

OrganizationsListItem.propTypes = {
  fetchChildrenOrganizations: PropTypes.func.isRequired,
  assignParentOrganization: PropTypes.func.isRequired,
  expandedId: PropTypes.number,
  errorAssignParentId: PropTypes.number,
  setExpandedId: PropTypes.func.isRequired,
  detailsById: PropTypes.arrayOf(PropTypes.number).isRequired,
  editOrganization: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStatuses: PropTypes.bool.isRequired,
};

export default OrganizationsListItem;
