export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_ERROR = 'USERS_FETCH_ERROR';

export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_ERROR = 'USER_EDIT_ERROR';

export const ORGANIZATIONS_FETCH_REQUEST = 'ORGANIZATIONS_FETCH_REQUEST';
export const ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS';
export const ORGANIZATIONS_FETCH_ERROR = 'ORGANIZATIONS_FETCH_ERROR';
export const HELPER_ORGANIZATIONS_FETCH_REQUEST = 'HELPER_ORGANIZATIONS_FETCH_REQUEST';
export const HELPER_ORGANIZATIONS_FETCH_SUCCESS = 'HELPER_ORGANIZATIONS_FETCH_SUCCESS';

export const ORGANIZATIONS_CHILDREN_FETCH_REQUEST = 'ORGANIZATIONS_CHILDREN_FETCH_REQUEST';
export const ORGANIZATIONS_CHILDREN_FETCH_SUCCESS = 'ORGANIZATIONS_CHILDREN_FETCH_SUCCESS';
export const ORGANIZATIONS_CHILDREN_FETCH_ERROR = 'ORGANIZATIONS_CHILDREN_FETCH_ERROR';

export const ASSIGN_PARENT_ORGANIZATION_SUCCESS = 'ASSIGN_PARENT_ORGANIZATION_SUCCESS';
export const ASSIGN_PARENT_ORGANIZATION_ERROR = 'ASSIGN_PARENT_ORGANIZATION_ERROR';
export const ASSIGN_PARENT_ORGANIZATION_REQUEST = 'ASSIGN_PARENT_ORGANIZATION_REQUEST';

export const ORGANIZATION_FETCH_REQUEST = 'ORGANIZATION_FETCH_REQUEST';
export const ORGANIZATION_FETCH_SUCCESS = 'ORGANIZATION_FETCH_SUCCESS';
export const ORGANIZATION_FETCH_ERROR = 'ORGANIZATION_FETCH_ERROR';

export const ORGANIZATION_CREATE_REQUEST = 'ORGANIZATION_CREATE_REQUEST';
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS';
export const ORGANIZATION_CREATE_ERROR = 'ORGANIZATION_CREATE_ERROR';

export const ORGANIZATION_EDIT_REQUEST = 'ORGANIZATION_EDIT_REQUEST';
export const ORGANIZATION_EDIT_SUCCESS = 'ORGANIZATION_EDIT_SUCCESS';
export const ORGANIZATION_EDIT_ERROR = 'ORGANIZATION_EDIT_ERROR';

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const ERROR = 'ERROR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const NEW_ROLE_REQUEST = 'NEW_ROLE_REQUEST';
export const NEW_ROLE_SUCCESS = 'NEW_ROLE_SUCCESS';
export const NEW_ROLE_ERROR = 'NEW_ROLE_ERROR';

export const NEW_ROLE_MODAL_OPEN = 'NEW_ROLE_MODAL_OPEN';
export const NEW_ROLE_MODAL_CLOSE = 'NEW_ROLE_MODAL_CLOSE';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
export const DELETE_ROLE_CONFLICT_ERROR = 'DELETE_ROLE_CONFLICT_ERROR';

export const FETCH_USER_STATUSES_REQUEST = 'FETCH_USER_STATUSES_REQUEST';
export const FETCH_USER_STATUSES_SUCCESS = 'FETCH_USER_STATUSES_SUCCESS';
export const FETCH_USER_STATUSES_ERROR = 'FETCH_USER_STATUSES_ERROR';

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_ERROR = 'UPDATE_USER_STATUS_ERROR';

export const NEW_USER_STATUS_REQUEST = 'NEW_USER_STATUS_REQUEST';
export const NEW_USER_STATUS_SUCCESS = 'NEW_USER_STATUS_SUCCESS';
export const NEW_USER_STATUS_ERROR = 'NEW_USER_STATUS_ERROR';

export const NEW_USER_STATUS_MODAL_OPEN = 'NEW_USER_STATUS_MODAL_OPEN';
export const NEW_USER_STATUS_MODAL_CLOSE = 'NEW_USER_STATUS_MODAL_CLOSE';

export const DELETE_USER_STATUS_REQUEST = 'DELETE_USER_STATUS_REQUEST';
export const DELETE_USER_STATUS_SUCCESS = 'DELETE_USER_STATUS_SUCCESS';
export const DELETE_USER_STATUS_ERROR = 'DELETE_USER_STATUS_ERROR';
export const DELETE_USER_STATUS_CONFLICT_ERROR = 'DELETE_USER_STATUS_CONFLICT_ERROR';

export const FETCH_ORGANIZATION_STATUSES_REQUEST = 'FETCH_ORGANIZATION_STATUSES_REQUEST';
export const FETCH_ORGANIZATION_STATUSES_SUCCESS = 'FETCH_ORGANIZATION_STATUSES_SUCCESS';
export const FETCH_ORGANIZATION_STATUSES_ERROR = 'FETCH_ORGANIZATION_STATUSES_ERROR';

export const UPDATE_ORGANIZATION_STATUS_REQUEST = 'UPDATE_ORGANIZATION_STATUS_REQUEST';
export const UPDATE_ORGANIZATION_STATUS_SUCCESS = 'UPDATE_ORGANIZATION_STATUS_SUCCESS';
export const UPDATE_ORGANIZATION_STATUS_ERROR = 'UPDATE_ORGANIZATION_STATUS_ERROR';

export const NEW_ORGANIZATION_STATUS_REQUEST = 'NEW_ORGANIZATION_STATUS_REQUEST';
export const NEW_ORGANIZATION_STATUS_SUCCESS = 'NEW_ORGANIZATION_STATUS_SUCCESS';
export const NEW_ORGANIZATION_STATUS_ERROR = 'NEW_ORGANIZATION_STATUS_ERROR';

export const NEW_ORGANIZATION_STATUS_MODAL_OPEN = 'NEW_ORGANIZATION_STATUS_MODAL_OPEN';
export const NEW_ORGANIZATION_STATUS_MODAL_CLOSE = 'NEW_ORGANIZATION_STATUS_MODAL_CLOSE';

export const DELETE_ORGANIZATION_STATUS_REQUEST = 'DELETE_ORGANIZATION_STATUS_REQUEST';
export const DELETE_ORGANIZATION_STATUS_SUCCESS = 'DELETE_ORGANIZATION_STATUS_SUCCESS';
export const DELETE_ORGANIZATION_STATUS_ERROR = 'DELETE_ORGANIZATION_STATUS_ERROR';
export const DELETE_ORGANIZATION_STATUS_CONFLICT_ERROR = 'DELETE_ORGANIZATION_STATUS_CONFLICT_ERROR';

export const SET_MENU_EXPANDED = 'SET_MENU_EXPANDED';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';

export const EDIT_USER_EMAIL_REQUEST = 'EDIT_USER_EMAIL_REQUEST';
export const EDIT_USER_EMAIL_SUCCESS = 'EDIT_USER_EMAIL_SUCCESS';
export const EDIT_USER_EMAIL_ERROR = 'EDIT_USER_EMAIL_ERROR';
export const EDIT_USER_EMAIL_COMPLETE = 'EDIT_USER_EMAIL_COMPLETE';
