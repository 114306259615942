import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserStatuses from './UserStatuses';
import {
  deleteUserStatusRequest, fetchUserStatusesRequest, updateUserStatusRequest,
  newUserStatusRequest, openModal, closeModal,
} from '../../actions/userStatuses';

const mapStateToProps = ({
  userStatuses: {
    loading, byId, byHash, newUserStatusModalOpened,
  },
}) => ({
  statuses: byId.map(id => byHash[id]),
  loading,
  newUserStatusModalOpened,
  count: byId.length,
});

const mapDispatchToProps = dispatch => ({
  fetchUserStatuses: bindActionCreators(fetchUserStatusesRequest, dispatch),
  updateUserStatus: bindActionCreators(updateUserStatusRequest, dispatch),
  deleteUserStatus: bindActionCreators(deleteUserStatusRequest, dispatch),
  addNewUserStatus: bindActionCreators(newUserStatusRequest, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStatuses);
