import userEnrichEdit from './userEnrichEdit';

const userEnrichCreate = (user, { rolesByHash }) => {
  const { username, password, email } = user;

  return {
    ...userEnrichEdit(user, { rolesByHash }),
    password,
    username,
    email,
  };
};

export default userEnrichCreate;
