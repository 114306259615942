import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Icon, Message,
} from 'semantic-ui-react';

import InputPassword from '../../../../components/InputPassword/InputPassword';
import useForm from '../../../../hooks/useForm';
import { styles } from '../../../../components/LayoutLogin';
import { validationSchema } from './schema';

import { SIGN_IN_ROUTE } from '../../../../constants/customizations';

const UpdatePasswordForm = ({
  errorMessage, loading, resetPasswordRequest, success, token, username,
}) => {
  const [translate] = useTranslation();
  const {
    values, handleChange, validate, errors, handleBlur,
  } = useForm(validationSchema);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      resetPasswordRequest(username, values.password, token);
    }
  };

  const renderBackLink = () => (
    <Link className={styles.backLink} to={SIGN_IN_ROUTE}>
      <Icon name="caret left" />
      {translate('BACK_TO_LOGIN')}
    </Link>
  );

  return success ? (
    <>
      <Message
        positive
        header={translate('SUCCESS')}
        content={translate('PASSWORD_HAS_BEEN_CHANGED')}
      />
      {renderBackLink()}
    </>
  ) : (
    <Form onSubmit={handleSubmit} loading={loading}>
      <InputPassword
        className={styles.input}
        disabled={loading}
        error={errors.password}
        name="password"
        onChange={handleChange}
        onBlue={handleBlur}
        placeholder={translate('PASSWORD')}
        type="password"
        value={values.password}
      />

      {errorMessage && (
        <Message size="mini" negative header={translate(errorMessage)} />
      )}

      <Button
        className={styles.submit}
        content={translate('RESET_PASSWORD')}
        fluid
        type="submit"
      />

      {renderBackLink()}
    </Form>
  );
};

UpdatePasswordForm.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  resetPasswordRequest: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default UpdatePasswordForm;
