import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ListItem from '../../../../components/List/components/ListItem';
import Text from '../../../../components/Text';
import UserForm from '../UserForm';
import styles from '../UsersListItem/UsersListItem.module.scss';
import useForm from '../../../../hooks/useForm';
import { validationSchemaCreate } from '../../schema';

const UsersListItemNew = ({
  createUser, setVisible, error, columns, showStatuses,
}) => {
  const [translate] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const {
    values, handleChange, validate, errors, clearForm, handleBlur, setFormSubmitted,
  } = useForm(validationSchemaCreate);
  const handleSave = () => {
    setFormSubmitted(true);
    if (validate()) {
      setLoading(true);
      createUser(values);
    }
  };

  const handleCancel = () => {
    clearForm({});
    setVisible(false);
  };

  useEffect(() => {
    if (loading && error === false) {
      setLoading(false);
      setVisible(false);
      clearForm({});
    } else if (loading && error) {
      setLoading(false);
      setFormError(error);
    }
  }, [loading, error]);

  const renderExpandedWrapper = () => (
    <div className={styles.expandedWrapper}>
      <UserForm
        createForm
        errors={errors}
        formError={formError}
        handleChange={handleChange}
        user={values}
        handleBlur={handleBlur}
      />
      <div className={styles.actionWrapper}>
        <Button content={translate('SAVE')} onClick={handleSave} primary disabled={!!Object.keys(errors).length} />
        <Button content={translate('CANCEL')} onClick={handleCancel} primary basic className="simple" />
      </div>
    </div>
  );

  return (
    <ListItem
      key="newUser"
      expandable
      expanded
      columns={columns}
    >
      <Text bold>{translate('NEW_USER')}</Text>
      <Text />
      <Text />
      <Text>{moment().format('DD/MM/YYYY')}</Text>
      {showStatuses && <Text /> }
      {renderExpandedWrapper()}
    </ListItem>
  );
};

UsersListItemNew.defaultProps = {
  error: null,
  columns: [],
};

UsersListItemNew.propTypes = {
  setVisible: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  showStatuses: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

export default UsersListItemNew;
