import uniqueId from 'lodash/uniqueId';

const usersSerializer = ({
  accountStatus, status, memberships = [], customAttributes = [], ...rest
}) => ({
  ...rest,
  status: accountStatus || status,
  memberships: memberships.reduce((acc, { roles, ...restMember }) => {
    const membersip = roles.length ? roles.map(role => ({
      key: uniqueId('_key'),
      organizationRole: role,
      ...restMember,
    })) : [{
      key: uniqueId('_key'),
      organizationRole: null,
      ...restMember,
    }];

    return [
      ...acc,
      ...membersip,
    ];
  }, []),
  customAttributes: customAttributes.map(attribute => ({
    key: uniqueId('_key'),
    ...attribute,
  })),
});

export default usersSerializer;
