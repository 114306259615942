import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RequestTokenForm from './RequestTokenForm';
import { resetPasswordTokenRequest } from '../../../../packages/authentication';

const mapStateToProps = ({ authentication }) => ({
  emailSent: authentication.resetPasswordEmailSent,
  errorMessage: authentication.resetPasswordErrorMessage,
  loading: authentication.resetPasswordLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPasswordTokenRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestTokenForm);
