import { ofType } from 'redux-observable';
import { delay, map } from 'rxjs/operators';

import { ADD_NOTIFICATION } from '../../constants/actionTypes';
import { NOTIFICATION_DISPLAY_TIME } from '../../constants/customizations';
import { removeNotification } from '../../actions/notifications';

export default action$ => action$.pipe(
  ofType(ADD_NOTIFICATION),
  delay(NOTIFICATION_DISPLAY_TIME),
  map(({ payload: { id } }) => removeNotification(id)),
);
