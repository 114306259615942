import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './OrganizationStatusListItem.module.scss';
import ListItem from '../../../../components/List/components/ListItem';
import ListActionButtons from '../../../../components/ListActionButtons';
import { ACTIVE, INACTIVE } from '../../../../constants/statusActiveness';

const OrganizationStatusListItem = ({
  organizationStatus, updateOrganizationStatus, deleteOrganizationStatus,
  organizationStatusToUpdate, setOrganizationStatusToUpdate, columns,
}) => {
  const [translate] = useTranslation();
  const { name: initName, status: initStatus } = organizationStatus;
  const [newName, setNewName] = useState(organizationStatus.name);
  const [newStatus, setNewStatus] = useState(organizationStatus.status);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && organizationStatusToUpdate) {
      inputRef.current.focus();
    }
  }, [organizationStatusToUpdate]);

  const handleSubmit = () => {
    setOrganizationStatusToUpdate(null);
    updateOrganizationStatus({ ...organizationStatus, name: newName, status: newStatus });
  };

  const handleUpdate = () => {
    setOrganizationStatusToUpdate(organizationStatus.id);
    setNewName(organizationStatus.name);
    setNewStatus(organizationStatus.status);
  };

  const handleDelete = () => {
    deleteOrganizationStatus(organizationStatus.id);
  };

  const isStatusChanged = () => !(initName === newName && initStatus === newStatus);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isStatusChanged()) {
      handleSubmit();
    }
  };

  const handleNameChange = (event, { value }) => {
    setNewName(value);
  };

  const handleCancel = () => {
    setOrganizationStatusToUpdate(null);
  };

  const handleStatusChange = (event, { checked, disabled }) => {
    if (!disabled) {
      setNewStatus(checked ? ACTIVE : INACTIVE);
    }
  };

  const renderCheckbox = () => {
    const underEdition = organizationStatusToUpdate === organizationStatus.id;
    const statusToShow = underEdition ? newStatus : organizationStatus.status;
    const onClick = underEdition ? handleStatusChange : null;

    return (
      <Checkbox
        label={translate(statusToShow === ACTIVE ? ACTIVE : INACTIVE)}
        onClick={onClick}
        checked={statusToShow === ACTIVE}
        disabled={!underEdition}
      />
    );
  };

  return (
    <ListItem key={organizationStatus.id} classNames={[styles.listItem, (organizationStatusToUpdate === organizationStatus.id) && styles.activeListItem].join(' ')} columns={columns}>
      {(organizationStatusToUpdate === organizationStatus.id)
        ? (
          <Input
            value={newName}
            className={['border-bottom', styles.updateInput].join(' ')}
            onChange={handleNameChange}
            maxLength={50}
            ref={inputRef}
            onKeyDown={handleKeyDown}
          />
        )
        : <span>{organizationStatus.name}</span>
      }
      { renderCheckbox() }
      <ListActionButtons
        isActive={organizationStatusToUpdate === organizationStatus.id}
        disableSubmit={!isStatusChanged() || !newName}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        deleteConfirmationText={translate('ARE_YOU_SURE_YOU_WANT_TO_DELETE_ORGANIZATION_STATUS')}
      />
    </ListItem>
  );
};

OrganizationStatusListItem.propTypes = {
  organizationStatus: PropTypes.shape({}).isRequired,
  updateOrganizationStatus: PropTypes.func.isRequired,
  deleteOrganizationStatus: PropTypes.func.isRequired,
  setOrganizationStatusToUpdate: PropTypes.func.isRequired,
  organizationStatusToUpdate: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

OrganizationStatusListItem.defaultProps = {
  organizationStatusToUpdate: null,
};

export default OrganizationStatusListItem;
