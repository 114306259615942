import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Header, Form, Button, Message, Image,
} from 'semantic-ui-react';

import InputPassword from '../../components/InputPassword/InputPassword';
import useForm from '../../hooks/useForm';
import { REQUIRED_ROLES, RESET_PASSWORD } from '../../constants/customizations';
import { styles } from '../../components/LayoutLogin';
import { validationSchema } from './schema';

const Login = ({ signIn, loading, error }) => {
  const [translate] = useTranslation();
  const {
    values, handleChange, validate, errors, handleBlur, setFormSubmitted,
  } = useForm(validationSchema);

  const handleSubmit = (event) => {
    setFormSubmitted(true);
    event.preventDefault();
    if (validate()) {
      signIn(values.username, values.password, REQUIRED_ROLES);
    }
  };

  return (
    <>
      <Image src="/images/bloom-logo.svg" centered className={styles.logo} />
      <Header as="h2" content={translate('USER_MANAGEMENT')} />
      <Form
        onSubmit={handleSubmit}
        loading={loading}
        error={Boolean(error)}
      >
        <Form.Input
          className={styles.input}
          disabled={loading}
          error={errors.username}
          onBlur={handleBlur}
          name="username"
          onChange={handleChange}
          placeholder={translate('USERNAME.LABEL')}
          value={values.username || ''}
        />
        <InputPassword
          className={styles.input}
          disabled={loading}
          error={errors.password}
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={translate('PASSWORD')}
          type="password"
          value={values.password || ''}
        />

        {error && <Message size="mini" header={translate('INVALID_CREDENTIALS')} negative />}

        <Button
          content={translate('SIGN_IN')}
          fluid
          className={styles.submit}
          type="submit"
        />

        <Link className={styles.forgotLink} to={RESET_PASSWORD}>
          {translate('FORGOT_PASSWORD')}
        </Link>
      </Form>
    </>
  );
};

Login.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default Login;
