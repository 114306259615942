import React, { useState, useRef, useEffect } from 'react';
import { Input, Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NewRoleModal = ({ addNewRole, closeModal, loading }) => {
  const [newRole, setNewRole] = useState('');
  const [translate] = useTranslation();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleSubmit = () => {
    addNewRole(newRole);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newRole) {
      handleSubmit();
    }
  };

  const handleRoleChange = (event, { value }) => {
    setNewRole(value);
  };

  return (
    <Modal defaultOpen closeOnDimmerClick={false}>
      <Modal.Header>{translate('ADD_NEW_USER_ROLE')}</Modal.Header>
      <Modal.Content>
        <Input
          value={newRole}
          placeholder={translate('ENTER_ROLE_NAME')}
          className="border-bottom"
          onChange={handleRoleChange}
          ref={inputRef}
          maxLength={50}
          fluid
          disabled={loading}
          onKeyDown={handleKeyDown}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          className="simple"
          content={translate('CANCEL')}
          onClick={closeModal}
          primary
        />
        <Button
          content={translate('SAVE')}
          disabled={!newRole}
          loading={loading}
          onClick={handleSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

NewRoleModal.propTypes = {
  addNewRole: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default NewRoleModal;
