import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { CUSTOM_ROLES_PATH } from '../../constants/paths';
import { UPDATE_ROLE_ERROR, UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS } from '../../constants/actionTypes';
import { updateRoleSuccess } from '../../actions/roles';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(UPDATE_ROLE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { role: { id, name } } }, { authentication: { authToken } }]) => ajax({
    url: `${CUSTOM_ROLES_PATH}/${id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ name }),
  }).pipe(
    mergeMap(({ response: updatedRole }) => [
      addNotification(NotificationType.SUCCESS, UPDATE_ROLE_SUCCESS),
      updateRoleSuccess(updatedRole),
    ]),
    catchError(error => of(handleError(error, UPDATE_ROLE_ERROR))),
  )),
);
