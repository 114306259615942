import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ListItem from '../../../../components/List/components/ListItem';
import OrganizationForm from '../OrganizationForm';
import Text from '../../../../components/Text';
import styles from '../OrganizationsListItem/OrganizationsListItem.module.scss';
import useForm from '../../../../hooks/useForm';
import { validationSchema } from '../../schema';

const OrganizationsListItemNew = ({
  createOrganization, setVisible, columns, showStatuses,
}) => {
  const [translate] = useTranslation();
  const [selectedParentOrg, setSelectedParentOrg] = useState(null);
  const {
    values, handleChange, validate, errors, clearForm, handleBlur, setFormSubmitted,
  } = useForm(validationSchema);

  const handleSave = () => {
    setFormSubmitted(true);
    if (validate()) {
      createOrganization(values, selectedParentOrg);
      clearForm({});
      setVisible(false);
    }
  };

  const handleCancel = () => {
    clearForm({});
    setVisible(false);
  };

  const renderExpandedWrapper = () => (
    <div className={styles.expandedWrapper}>
      <OrganizationForm
        organization={values}
        handleChange={handleChange}
        errors={errors}
        handleBlur={handleBlur}
        setSelectedParentOrg={setSelectedParentOrg}
        selectedParentOrg={selectedParentOrg}
      />
      <div />
      <div className={styles.actionWrapper}>
        <Button content={translate('CANCEL')} onClick={handleCancel} primary basic className="simple" />
        <Button content={translate('SAVE')} onClick={handleSave} primary disabled={Object.keys(errors).length} />
      </div>
    </div>
  );

  return (
    <ListItem key="newOrganization" onExpand={() => {}} expandable expanded columns={columns}>
      <Text bold>{translate('NEW_ORGANIZATION')}</Text>
      <Text />
      <Text>{moment().format('DD/MM/YYYY')}</Text>
      {showStatuses && <Text /> }
      {renderExpandedWrapper()}
    </ListItem>
  );
};

OrganizationsListItemNew.propTypes = {
  setVisible: PropTypes.func.isRequired,
  showStatuses: PropTypes.bool.isRequired,
  createOrganization: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrganizationsListItemNew;
