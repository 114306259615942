import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { USER_STATUSES_PATH } from '../../constants/paths';
import { FETCH_USER_STATUSES_ERROR, FETCH_USER_STATUSES_REQUEST } from '../../constants/actionTypes';
import { fetchUserStatusesSuccess } from '../../actions/userStatuses';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(FETCH_USER_STATUSES_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken } }]) => ajax({
    url: USER_STATUSES_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: userStatuses }) => fetchUserStatusesSuccess(userStatuses)),
    catchError(error => of(handleError(error, FETCH_USER_STATUSES_ERROR))),
  )),
);
