import {
  map,
  mergeMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { ORGANIZATION_FETCH_REQUEST, ORGANIZATION_FETCH_ERROR } from '../../constants/actionTypes';
import { ORGANIZATIONS_PATH } from '../../constants/paths';
import { fetchOrganizationSuccess } from '../../actions/organizations';
import organizationsSerializer from '../../utils/serializers/organizationsSerializer';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ORGANIZATION_FETCH_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { id } }, { authentication: { authToken } }]) => ajax({
    url: `${ORGANIZATIONS_PATH}/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }) => fetchOrganizationSuccess(organizationsSerializer(response))),
    catchError(error => of(handleError(error, ORGANIZATION_FETCH_ERROR))),
  )),
);
