import React, { useState, useRef, useEffect } from 'react';
import {
  Input, Modal, Button, Form, Checkbox,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ACTIVE, INACTIVE } from '../../../../constants/statusActiveness';

const NewOrganizationStatusModal = ({ addOrganizationStatus, closeModal, loading }) => {
  const [newName, setNewName] = useState('');
  const [newStatus, setNewStatus] = useState(ACTIVE);
  const [translate] = useTranslation();
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleSubmit = () => {
    addOrganizationStatus({ name: newName, status: newStatus });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newName) {
      handleSubmit();
    }
  };

  const handleNameChange = (event, { value }) => {
    setNewName(value);
  };

  const handleStatusChange = (event, { checked }) => {
    setNewStatus(checked ? ACTIVE : INACTIVE);
  };

  return (
    <Modal defaultOpen closeOnDimmerClick={false}>
      <Modal.Header>{translate('ADD_NEW_ORGANIZATION_STATUS')}</Modal.Header>
      <Modal.Content>
        <Form id="newOrgStatusForm">
          <Form.Field>
            <Input
              value={newName}
              placeholder={translate('STATUS_NAME')}
              className="border-bottom"
              onChange={handleNameChange}
              ref={inputRef}
              fluid
              maxLength={50}
              id="statusName"
              disabled={loading}
              onKeyDown={handleKeyDown}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={translate(newStatus === ACTIVE ? ACTIVE : INACTIVE)}
              checked={newStatus === ACTIVE}
              onClick={handleStatusChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          className="simple"
          content={translate('CANCEL')}
          onClick={closeModal}
          primary
        />
        <Button
          content={translate('SAVE')}
          disabled={!newName}
          form="newOrgStatusForm"
          loading={loading}
          onClick={handleSubmit}
          primary
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

NewOrganizationStatusModal.propTypes = {
  addOrganizationStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default NewOrganizationStatusModal;
