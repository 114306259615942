import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ResetPasswordModal from './ResetPasswordModal';
import { resetPasswordByAdminRequest, resetPasswordTokenRequest } from '../../packages/authentication';

const mapDispatchToProps = dispatch => ({
  resetPasswordByAdmin: bindActionCreators(resetPasswordByAdminRequest, dispatch),
  resetPasswordToken: bindActionCreators(resetPasswordTokenRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(ResetPasswordModal);
