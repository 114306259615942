import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './containers/App';
import store from './configureStore';
import './styles/index.scss';
import './i18n';

const appComponent = (
  <Provider store={store}>
    <Suspense fallback="">
      <App />
    </Suspense>
  </Provider>
);

ReactDOM.render(
  appComponent,
  document.getElementById('root'),
);
