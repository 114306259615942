import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map, mergeMap, catchError, withLatestFrom,
} from 'rxjs/operators';

import usersSerializer from '../../utils/serializers/usersSerializer';
import { USERS_FETCH_ERROR, USERS_FETCH_REQUEST } from '../../constants/actionTypes';
import { USERS_LIST_LIMIT } from '../../constants/customizations';
import { USERS_PATH } from '../../constants/paths';
import { fetchUsersSuccess } from '../../actions/users';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(USERS_FETCH_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      page, search, filters, field, order,
    },
  }, {
    authentication: { authToken },
  }]) => {
    const query = queryString.stringify(pickBy({
      offset: (page - 1) * USERS_LIST_LIMIT,
      limit: USERS_LIST_LIMIT,
    }));

    return ajax({
      url: `${USERS_PATH}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        search,
        ...((field || order) && { sort: { field, order } }),
        ...filters,
      }),
    }).pipe(
      map(({ response: { data, count } }) => fetchUsersSuccess(data.map(usersSerializer), count)),
      catchError(error => of(handleError(error, USERS_FETCH_ERROR))),
    );
  }),
);
