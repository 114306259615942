import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrganizationForm from './OrganizationForm';
import { fetchOrganizationsRequest } from '../../../../actions/organizations';

const mapStateToProps = ({
  organizationStatuses: { byId: statusesById, byHash: statusesByHash },
  organizations: { helperOrgList = [], helperOrgListLoading: loadingList },
}, { organization: { parent } }) => {
  const orgOptions = [
    parent && { value: parent.id, text: parent.name },
    ...helperOrgList.map(({ id, name }) => ({
      value: id,
      text: name,
    }))].filter(Boolean);

  const uniqueOptionIds = Array.from(new Set(orgOptions.map(option => option.value)));

  return ({
    statusesOptions: statusesById
      .map(id => ({ value: id, text: statusesByHash[id].name })),
    organizationsOptions: uniqueOptionIds.map(uoid => orgOptions.find(o => o.value === uoid)),
    loadingList,
  });
};

const mapDispatchToProps = dispatch => ({
  fetchOrganizations: bindActionCreators(fetchOrganizationsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
