import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { USER_STATUSES_PATH } from '../../constants/paths';
import {
  DELETE_USER_STATUS_ERROR,
  DELETE_USER_STATUS_REQUEST,
  DELETE_USER_STATUS_SUCCESS,
  DELETE_USER_STATUS_CONFLICT_ERROR,
} from '../../constants/actionTypes';
import { deleteUserStatusSuccess } from '../../actions/userStatuses';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(DELETE_USER_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { id } }, { authentication: { authToken } }]) => ajax({
    url: `${USER_STATUSES_PATH}/${id}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, DELETE_USER_STATUS_SUCCESS),
      deleteUserStatusSuccess(id),
    ]),
    catchError(error => of(
      handleError(
        error,
        error.status !== 409
          ? DELETE_USER_STATUS_ERROR
          : DELETE_USER_STATUS_CONFLICT_ERROR,
      ),
    )),
  )),
);
