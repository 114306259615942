import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Text from '../../../../components/Text';
import styles from './OrganizationsList.module.scss';
import { ORGANIZATIONS_ROUTE } from '../../../../constants/customizations';

const OrganizationsList = ({ organizations }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.detailsWrapper}>
      <Text label>{translate('ORGANIZATION')}</Text>
      <Table basic="very">
        <Table.Body>
          {organizations.sort((org, anotherOrg) => {
            if (!org || !anotherOrg) {
              return 0;
            }
            if (org.organizationName === anotherOrg.organizationName) {
              if (org.organizationRole > anotherOrg.organizationRole) {
                return 1;
              }
              if (org.organizationRole < anotherOrg.organizationRole) {
                return -1;
              }
              return 0;
            }
            if (org.organizationName > anotherOrg.organizationName) {
              return 1;
            }
            return -1;
          }).map(({
            key,
            organizationExternalId,
            organizationId,
            organizationName,
            organizationRole,
          }) => (
            <Table.Row key={key}>
              <Table.Cell width={5}>{organizationName}</Table.Cell>
              <Table.Cell width={4}>{translate(`CUSTOM_ROLE.${organizationRole}`, organizationRole) || 'None'}</Table.Cell>
              <Table.Cell width={3}>
                <Link to={`${ORGANIZATIONS_ROUTE}?activeId=${organizationId}&search=${organizationExternalId}`}>
                  {translate('VIEW_DETAILS')}
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

OrganizationsList.defaultProps = {
  organizations: [],
};

OrganizationsList.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OrganizationsList;
