import DayPickerInput from 'react-day-picker/DayPickerInput';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Input } from 'semantic-ui-react';
import { parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import FilterDatePickerOverlay from '../FilterDatePickerOverlay';
import styles from './FilterDatePicker.module.scss';
import { OPERATORS } from '../../constants';
import { formatDate } from '../../../../utils/dateUtils';

const { SINCE, BEFORE, BETWEEN } = OPERATORS;

const FilterDatePicker = ({
  fieldValue: { from: dateFromUnix, to: dateToUnix },
  onChange,
  operator,
}) => {
  const dateFrom = dateFromUnix && moment(dateFromUnix).toDate();
  const dateTo = dateToUnix && moment(dateToUnix).toDate();

  useEffect(() => {
    onChange({
      ...(operator !== BEFORE && { from: dateFromUnix }),
      ...(operator !== SINCE && { to: dateToUnix }),
    });
  }, [operator]);

  const handleDayFromChange = (value) => {
    onChange({
      from: moment(value).toISOString(),
      to: dateToUnix,
    });
  };

  const handleDayToChange = (value) => {
    onChange({
      from: dateFromUnix,
      to: moment(value).toISOString(),
    });
  };

  const dayPickerClassNames = {
    container: styles.inputContainer,
    overlayWrapper: '',
    overlay: '',
  };

  const renderInput = React.forwardRef((props, ref) => (
    <Input fluid {...props} ref={ref} />
  ));

  return (
    <div className={styles.wrapper}>
      {operator !== BEFORE && (
        <DayPickerInput
          classNames={dayPickerClassNames}
          component={renderInput}
          formatDate={formatDate}
          onDayChange={handleDayFromChange}
          overlayComponent={FilterDatePickerOverlay}
          parseDate={parseDate}
          value={dateFrom || ''}
          dayPickerProps={{
            disabledDays: { after: dateTo },
            numberOfMonths: 1,
            selectedDays: [dateFrom],
            toMonth: dateTo,
          }}
        />
      )}

      {operator === BETWEEN && (
        <div> — </div>
      )}

      {operator !== SINCE && (
        <DayPickerInput
          classNames={dayPickerClassNames}
          component={renderInput}
          formatDate={formatDate}
          onDayChange={handleDayToChange}
          overlayComponent={FilterDatePickerOverlay}
          parseDate={parseDate}
          value={dateTo || ''}
          dayPickerProps={{
            disabledDays: { before: dateFrom },
            fromMonth: dateFrom,
            numberOfMonths: 1,
            selectedDays: [dateTo],
          }}
        />
      )}
    </div>
  );
};

FilterDatePicker.defaultProps = {
  onChange: () => {},
};

FilterDatePicker.propTypes = {
  fieldValue: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  operator: PropTypes.string.isRequired,
};

export default FilterDatePicker;
