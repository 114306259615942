import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import FilterField from '../FilterField';
import styles from './FilterForm.module.scss';
import { filterConfigType } from '../../../../constants/propTypes';

const FilterForm = ({
  config, onReset, onSubmit, setFilters, setOperators, filters, operators,
}) => {
  const [translate] = useTranslation();

  const setFilterForName = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const setOperatorForName = (name, value) => {
    setOperators({
      ...operators,
      [name]: value,
    });
  };

  return (
    <Form className={styles.formContainer}>
      {config.map(filter => (
        <FilterField
          filter={filter}
          fieldValue={filters[filter.key]}
          key={filter.key}
          operatorValue={operators[filter.key]}
          setFilterForName={setFilterForName}
          setOperatorForName={setOperatorForName}
          config={config}
        />
      ))}

      <div className={styles.actions}>
        <Button content={translate('RESET')} className="simple uppercase" primary basic onClick={onReset} />
        <Button content={translate('APPLY_FILTERS')} className="uppercase" primary onClick={onSubmit} />
      </div>
    </Form>
  );
};

FilterForm.propTypes = {
  config: filterConfigType.isRequired,
  filters: PropTypes.shape({}).isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  operators: PropTypes.shape({}).isRequired,
  setFilters: PropTypes.func.isRequired,
  setOperators: PropTypes.func.isRequired,
};

export default FilterForm;
