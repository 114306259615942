import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import Notifications from './components/Notifications';
import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import Layout from '../../components/Layout';
import Login from '../Login';
import Organizations from '../Organizations';
import PasswordReset from '../ResetPassword';
import Roles from '../Roles';
import UserStatuses from '../UserStatuses';
import OrganizationStatuses from '../OrganizationStatuses';
import Users from '../Users';

import {
  HOME_ROUTE,
  ORGANIZATIONS_ROUTE,
  RESET_PASSWORD,
  ROLES_MANAGEMENT_ROUTE,
  SETTINGS_ROUTE,
  SIGN_IN_ROUTE,
  USERS_ROUTE,
  USER_STATUSES_ROUTE,
  ORGANIZATION_STATUSES_ROUTE,
} from '../../constants/customizations';
import LayoutLogin from '../../components/LayoutLogin';

const App = ({ signOut, menuExpanded, setMenuExpanded }) => {
  const renderWithLayout = Component => props => (
    <Layout signOut={signOut} menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded}>
      <Component {...props} />
    </Layout>
  );

  const renderWithLayoutLogin = Component => props => (
    <LayoutLogin>
      <Component {...props} />
    </LayoutLogin>
  );

  return [
    <Router basename={process.env.PUBLIC_URL} key="router">
      <Switch>
        <UnprotectedRoute exact path={SIGN_IN_ROUTE} render={renderWithLayoutLogin(Login)} />
        <UnprotectedRoute
          exact
          path={RESET_PASSWORD}
          render={renderWithLayoutLogin(PasswordReset)}
        />
        <ProtectedRoute path={USERS_ROUTE} render={renderWithLayout(Users)} />
        <ProtectedRoute path={ORGANIZATIONS_ROUTE} render={renderWithLayout(Organizations)} />
        <ProtectedRoute path={USER_STATUSES_ROUTE} render={renderWithLayout(UserStatuses)} />
        <ProtectedRoute path={ROLES_MANAGEMENT_ROUTE} render={renderWithLayout(Roles)} />
        <ProtectedRoute
          path={ORGANIZATION_STATUSES_ROUTE}
          render={renderWithLayout(OrganizationStatuses)}
        />
        <Redirect exact from={HOME_ROUTE} to={USERS_ROUTE} />
        <Redirect exact from={SETTINGS_ROUTE} to={USER_STATUSES_ROUTE} />
        <ProtectedRoute render={renderWithLayout(Users)} />
      </Switch>
    </Router>,
    <Notifications key="notifications" />,
  ];
};

App.defaultProps = {
  menuExpanded: null,
};

App.propTypes = {
  menuExpanded: PropTypes.bool,
  setMenuExpanded: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};


export default App;
