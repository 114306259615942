import PropTypes from 'prop-types';
import React, { useState, forwardRef } from 'react';
import classNames from 'classnames';
import { Form } from 'semantic-ui-react';

import styles from './InputPassword.module.scss';

const InputPassword = forwardRef((
  { className, ...props },
  ref,
) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = (event) => {
    event.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Form.Input
      {...props}
      className={classNames(className, styles.InputPassword)}
      type={passwordVisible ? 'text' : 'password'}
      icon={{
        link: true,
        name: passwordVisible ? 'eye slash' : 'eye',
        onClick: togglePasswordVisible,
      }}
      ref={ref}
    />
  );
});

InputPassword.defaultProps = {
  className: '',
};

InputPassword.propTypes = {
  className: PropTypes.string,
};

export default InputPassword;
