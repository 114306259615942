import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { EDIT_USER_EMAIL_ERROR, EDIT_USER_EMAIL_REQUEST, EDIT_USER_EMAIL_SUCCESS } from '../../constants/actionTypes';
import { handleError } from '../../actions/errors';
import { EDIT_USER_EMAIL_PATH } from '../../constants/paths';
import { editUserEmailSuccess } from '../../actions/users';
import { addNotification } from '../../actions/notifications';
import notificationTypes from '../../constants/notificationTypes';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(EDIT_USER_EMAIL_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { user, email } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: EDIT_USER_EMAIL_PATH.replace('$userId', user.id),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      username: email,
      email,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(notificationTypes.SUCCESS, EDIT_USER_EMAIL_SUCCESS),
      editUserEmailSuccess({ ...user, email }),
    ]),
    catchError(error => of(handleError(error, EDIT_USER_EMAIL_ERROR, error))),
  )),
);
