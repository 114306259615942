import { useState } from 'react';
import params from '../../utils/params';

export const ASC = 'ASC';
export const DESC = 'DESC';
export const SORT_FIELD_KEY = 'field';
export const SORT_ORDER_KEY = 'order';

const useSorting = (history) => {
  const [sortOrder, setSortOrder] = useState(DESC);
  const [sortField, setSortField] = useState();
  const { setParams } = params(history);

  const reverseOrder = () => (sortOrder === DESC ? ASC : DESC);

  const onSortChange = (field) => {
    if (sortField !== field) {
      setParams({
        [SORT_FIELD_KEY]: field,
        [SORT_ORDER_KEY]: DESC,
      });
      setSortField(field);
      setSortOrder(DESC);
    } else {
      const reversedOrder = reverseOrder();
      setSortOrder(reversedOrder);
      setParams({ [SORT_ORDER_KEY]: reversedOrder });
    }
  };

  return {
    sortOrder, sortField, setSortField, setSortOrder, onSortChange,
  };
};

export default useSorting;
