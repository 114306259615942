import uniqueId from 'lodash/uniqueId';

const organizationsSerializer = ({ customAttributes = [], ...rest }) => ({
  ...rest,
  customAttributes: customAttributes.map(attribute => ({
    key: uniqueId('_key'),
    ...attribute,
  })),
});

export default organizationsSerializer;
