import {
  map,
  mergeMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { ORGANIZATIONS_CHILDREN_FETCH_REQUEST, ORGANIZATIONS_CHILDREN_FETCH_ERROR } from '../../constants/actionTypes';
import { fetchChildrenOrganizationsSuccess } from '../../actions/organizations';
import { ORGANIZATIONS_PATH } from '../../constants/paths';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ORGANIZATIONS_CHILDREN_FETCH_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      parentId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: `${ORGANIZATIONS_PATH}/${parentId}/children`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: organizations }) => fetchChildrenOrganizationsSuccess(
      organizations, parentId,
    )),
    catchError(error => of(handleError(error, ORGANIZATIONS_CHILDREN_FETCH_ERROR))),
  )),
);
