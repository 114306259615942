import get from 'lodash/get';
import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { ERROR } from '../../constants/actionTypes';
import NotificationType from '../../constants/notificationTypes';
import { addNotification } from '../../actions/notifications';

const handleError = action$ => action$.pipe(
  ofType(ERROR),
  map(({ payload: { error, actionType } }) => addNotification(
    NotificationType.ERROR,
    actionType,
    error.status,
    `${error.message}: ${get(error, 'response.message', '')}`,
  )),
);

export default handleError;
