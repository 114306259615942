import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { ORGANIZATIONS_STATUSES_PATH } from '../../constants/paths';
import { FETCH_ORGANIZATION_STATUSES_ERROR, FETCH_ORGANIZATION_STATUSES_REQUEST } from '../../constants/actionTypes';
import { fetchOrganizationStatusesSuccess } from '../../actions/organizationStatuses';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(FETCH_ORGANIZATION_STATUSES_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken } }]) => ajax({
    url: ORGANIZATIONS_STATUSES_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: organizationStatuses }) => fetchOrganizationStatusesSuccess(
      organizationStatuses,
    )),
    catchError(error => of(
      handleError(error, FETCH_ORGANIZATION_STATUSES_ERROR),
    )),
  )),
);
