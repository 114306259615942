import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './UserStatusListItem.module.scss';
import ListItem from '../../../../components/List/components/ListItem';
import ListActionButtons from '../../../../components/ListActionButtons';
import { ACTIVE, INACTIVE } from '../../../../constants/statusActiveness';

const UsersListItem = ({
  userStatus, updateUserStatus, deleteUserStatus,
  userStatusToUpdate, setUserStatusToUpdate, columns,
}) => {
  const [translate] = useTranslation();
  const { name: initName, status: initStatus } = userStatus;
  const [newName, setNewName] = useState(userStatus.name);
  const [newStatus, setNewStatus] = useState(userStatus.status);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && userStatusToUpdate) {
      inputRef.current.focus();
    }
  }, [userStatusToUpdate]);

  const handleSubmit = () => {
    setUserStatusToUpdate(null);
    updateUserStatus({ ...userStatus, name: newName, status: newStatus });
  };

  const handleUpdate = () => {
    setUserStatusToUpdate(userStatus.id);
    setNewName(userStatus.name);
    setNewStatus(userStatus.status);
  };

  const handleDelete = () => {
    deleteUserStatus(userStatus.id);
  };

  const isStatusChanged = () => !(initName === newName && initStatus === newStatus);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isStatusChanged()) {
      handleSubmit();
    }
  };

  const handleCancel = () => {
    setUserStatusToUpdate(null);
  };

  const handleNameChange = (event, { value }) => {
    setNewName(value);
  };

  const handleStatusChange = (event, { checked, disabled }) => {
    if (!disabled) {
      setNewStatus(checked ? ACTIVE : INACTIVE);
    }
  };

  const renderCheckbox = () => {
    const underEdition = userStatusToUpdate === userStatus.id;
    const statusToShow = underEdition ? newStatus : userStatus.status;
    const onClick = underEdition ? handleStatusChange : null;

    return (
      <Checkbox
        label={translate(statusToShow === ACTIVE ? ACTIVE : INACTIVE)}
        onClick={onClick}
        checked={statusToShow === ACTIVE}
        disabled={!underEdition}
      />
    );
  };

  return (
    <ListItem key={userStatus.id} classNames={[styles.listItem, (userStatusToUpdate === userStatus.id) && styles.activeListItem].join(' ')} columns={columns}>
      { (userStatusToUpdate === userStatus.id)
        ? (
          <Input
            value={newName}
            className={['border-bottom', styles.updateInput].join(' ')}
            onChange={handleNameChange}
            ref={inputRef}
            maxLength={50}
            onKeyDown={handleKeyDown}
          />
        )
        : <span>{userStatus.name}</span>
      }
      { renderCheckbox() }
      <ListActionButtons
        isActive={userStatusToUpdate === userStatus.id}
        disableSubmit={!isStatusChanged() || !newName}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        deleteConfirmationText={translate('ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER_STATUS')}
      />
    </ListItem>
  );
};

UsersListItem.propTypes = {
  userStatus: PropTypes.shape({}).isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  deleteUserStatus: PropTypes.func.isRequired,
  setUserStatusToUpdate: PropTypes.func.isRequired,
  userStatusToUpdate: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

UsersListItem.defaultProps = {
  userStatusToUpdate: null,
};

export default UsersListItem;
