import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UsersListItem from './UsersListItem';
import { editUserRequest, fetchUserRequest } from '../../../../actions/users';

const mapStateToProps = ({ users: { detailsById, error } }) => ({
  detailsById,
  error,
});

const mapDispatchToProps = dispatch => ({
  editUser: bindActionCreators(editUserRequest, dispatch),
  fetchUser: bindActionCreators(fetchUserRequest, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersListItem));
