import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { USER_STATUSES_PATH } from '../../constants/paths';
import {
  UPDATE_USER_STATUS_ERROR,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
} from '../../constants/actionTypes';
import { updateUserStatusSuccess } from '../../actions/userStatuses';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(UPDATE_USER_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { status: { id, name, status } } },
    { authentication: { authToken } },
  ]) => ajax({
    url: `${USER_STATUSES_PATH}/${id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ name, status }),
  }).pipe(
    mergeMap(({ response: updatedUserStatus }) => [
      addNotification(NotificationType.SUCCESS, UPDATE_USER_STATUS_SUCCESS),
      updateUserStatusSuccess(updatedUserStatus)]),
    catchError(error => of(handleError(error, UPDATE_USER_STATUS_ERROR))),
  )),
);
