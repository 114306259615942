import PropTypes from 'prop-types';
import React, { Children, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Loader, Dimmer } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import SortArrows from '../SortArrows';
import Text from '../Text';
import styles from './List.module.scss';

const List = ({
  count,
  columns,
  children,
  emptyTableText,
  onSortChange,
  sortField,
  sortOrder,
  loading,
  expandable,
  activeId,
}) => {
  const [expandedId, setExpandedId] = useState(+activeId);
  const [translate] = useTranslation();
  const shouldRenderItems = children && children.length !== 0;
  const shouldRenderHeaderRow = shouldRenderItems && columns;
  const onSortClick = ({ sortable, key }) => () => (
    sortable && key && onSortChange && onSortChange(key)
  );

  const elements = [];

  const expandedProps = expandable ? { expandedId, setExpandedId } : {};

  const extendProps = {
    ...expandedProps,
    columns,
  };

  Children.toArray(children).forEach((child) => {
    elements.push(React.cloneElement(child, extendProps));
  });

  useEffect(() => setExpandedId(+activeId), [activeId]);

  return (
    <div className={styles.wrapper}>
      {loading && <Dimmer active inverted className={styles.loaderWrapper}><Loader /></Dimmer>}

      <div className={styles.row} style={count === 0 ? { minHeight: 0 } : {}}>
        {shouldRenderHeaderRow && columns.map(column => (
          <div
            key={column.header}
            onClick={onSortClick(column)}
            className={classNames(
              styles['column-name'],
              styles[`align-content-${column.align}`],
              styles[`flex-${column.size}`],
              column.sortable && 'sortable',
            )}
          >
            {column.header && <Text bold translate>{column.header}</Text>}
            {column.sortable && sortField === column.key && <SortArrows sortOrder={sortOrder} />}
          </div>
        ))}
      </div>
      <div className={styles.scrollable}>
        {shouldRenderItems && elements}
      </div>
      {count === 0 && (<div className={styles.empty}>{translate(emptyTableText)}</div>)}
    </div>
  );
};

List.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    align: PropTypes.oneOf([
      'left',
      'center',
      'right',
    ]),
  })),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  emptyTableText: PropTypes.string,
  count: PropTypes.number,
  loading: PropTypes.bool,
  expandable: PropTypes.bool,
  onSortChange: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  activeId: PropTypes.string,
};

List.defaultProps = {
  emptyTableText: 'NO_ELEMENTS_AVAILABLE',
  count: null,
  columns: [],
  children: null,
  loading: false,
  expandable: false,
  sortField: '',
  sortOrder: '',
  onSortChange: null,
  activeId: null,
};

export default List;
