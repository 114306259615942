import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import StatusCell from '../../../../components/StatusCell';
import Text from '../../../../components/Text';
import styles from './MembersList.module.scss';
import { USERS_ROUTE } from '../../../../constants/customizations';

const MembersList = ({ members, filter }) => {
  const [translate] = useTranslation();

  const membersFiltered = members.filter(({ role, accountStatus }) => {
    switch (filter) {
      case 'ADMIN':
      case 'USER':
        return role === filter;
      case 'INACTIVE':
        return !accountStatus || accountStatus.status === filter;
      case 'VIEW_ALL':
      default:
        return true;
    }
  });

  return (
    <div className={styles.detailsWrapper}>
      <Text label>{translate('USERS')}</Text>
      <Table basic="very">
        <Table.Body>
          {membersFiltered.map(({
            displayName, role, accountStatus, id,
          }) => (
            <Table.Row key={id}>
              <Table.Cell width={5}>{displayName}</Table.Cell>
              <Table.Cell width={4}>{role ? translate(role) : 'None'}</Table.Cell>
              <Table.Cell width={4}><StatusCell status={accountStatus} /></Table.Cell>
              <Table.Cell width={3}>
                <Link to={`${USERS_ROUTE}?activeId=${id}&directId=${id}`}>
                  {translate('VIEW_DETAILS')}
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

MembersList.defaultProps = {
  members: [],
};

MembersList.propTypes = {
  filter: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MembersList;
