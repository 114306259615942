import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import userEnrichCreate from '../../utils/serializers/userEnrichCreate';
import usersSerializer from '../../utils/serializers/usersSerializer';
import { USERS_PATH } from '../../constants/paths';
import { USER_CREATE_ERROR, USER_CREATE_REQUEST, USER_CREATE_SUCCESS } from '../../constants/actionTypes';
import { createUserSuccess } from '../../actions/users';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(USER_CREATE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { user } },
    {
      authentication: { authToken },
      roles: { byHash: rolesByHash },
    },
  ]) => ajax({
    url: USERS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(userEnrichCreate(user, { rolesByHash })),
  }).pipe(
    mergeMap(({ response }) => [
      addNotification(NotificationType.SUCCESS, USER_CREATE_SUCCESS),
      createUserSuccess(usersSerializer(response)),
    ]),
    catchError(error => of(handleError(error, USER_CREATE_ERROR))),
  )),
);
