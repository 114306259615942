import PropTypes from 'prop-types';
import React from 'react';
import toPairs from 'lodash/toPairs';
import { Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './FilterResults.module.scss';
import useFilter from '../../../../hooks/useFilter';
import useSearch from '../../../../hooks/useSearch';
import { TYPES } from '../../constants';
import { formatDate } from '../../../../utils/dateUtils';

const FilterResults = ({ history, config }) => {
  const [translate] = useTranslation();
  const [search, onChangeSearch] = useSearch(history);
  const [filters, operators, onChangeFilters] = useFilter(history);
  const filtersArray = filters ? toPairs(filters) : [];

  const handleRemoveFilter = key => () => {
    const { [key]: toRemove, ...restFilters } = filters;
    onChangeFilters(restFilters);
  };

  const handleRemoveSearch = () => {
    onChangeSearch();
  };

  const mapFilter = ([key, value]) => {
    const { name, values, type } = config.find(({ key: filterKey }) => filterKey === key) || {};

    switch (type) {
      case TYPES.DATE: {
        const { from, to } = value;
        const operator = operators[key];
        const text = [from, to].filter(Boolean).map(formatDate).join(' — ');
        return {
          name, text, key, operator,
        };
      }
      case TYPES.NUMBER: {
        return {
          name, text: value && value.from, key,
        };
      }
      case TYPES.MULTISELECTION: {
        return {
          name,
          text: value
            .map((selectedValue) => {
              const foundObject = values.find(temp => temp.value === selectedValue);

              return foundObject && foundObject.text;
            })
            .join(', '),
          key,
        };
      }
      default: {
        const { text } = values.find(({ value: filterValue }) => filterValue === value) || {};
        return { name, text, key };
      }
    }
  };

  return (
    <div className={styles.container}>
      {search && (
        <Label
          circular
          className={styles.label}
          content={`${translate('SEARCH')}: ${search}`}
          onRemove={handleRemoveSearch}
        />
      )}

      {filtersArray.map(mapFilter).map(({
        key, name, text, operator,
      }) => (
        <Label
          circular
          className={styles.label}
          content={`${translate(name)}${operator ? ` ${translate(operator)}` : ''}: ${text}`}
          onRemove={handleRemoveFilter(key)}
          key={key}
        />
      ))}
    </div>
  );
};

FilterResults.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default FilterResults;
