import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonAdd from '../../components/ButtonAdd';
import FilterResults from '../../components/FilterPanel/components/FilterResults';
import Layout from '../../components/Layout';
import UsersListItem from './components/UsersListItem';
import UsersListItemNew from './components/UsersListItemNew';
import filterColumnsConfig from './filterColumnsConfig';
import filterConfig from './filterConfig';
import styles from './Users.module.scss';
import params from '../../utils/params';
import { filterValues } from '../../constants/propTypes';

const Users = ({
  count,
  fetchRoles,
  fetchUserStatuses,
  history,
  loadingList,
  fetchUser,
  roles,
  statuses,
  users,
  ...listProps
}) => {
  const [translate] = useTranslation();
  const [showAddForm, setShowAddForm] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const config = filterConfig({ statuses, roles });
  const columns = filterColumnsConfig(statuses);
  const { getParams } = params(history);
  const { directId } = getParams();

  useEffect(() => {
    if (directId) {
      if (users.length) {
        const foundUser = users.find(({ id }) => id === +directId);
        if (foundUser) {
          setUsersList([foundUser]);
          return;
        }
      }
      fetchUser(directId);
    } else {
      setUsersList(users);
    }
  }, [directId, users]);

  useEffect(() => {
    fetchRoles();
    fetchUserStatuses();
  }, []);

  const handleNewUser = () => {
    setShowAddForm(true);
  };

  const renderItems = () => [
    showAddForm && <UsersListItemNew setVisible={setShowAddForm} key="new" showStatuses={!!statuses.length} />,
    ...usersList.map(user => (
      <UsersListItem
        key={user.id}
        user={user}
        showStatuses={!!statuses.length}
      />
    )),
  ].filter(Boolean);

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      {count !== null ? `${count} ${translate('USERS')}` : ''}
      <FilterResults config={config} />
    </div>
  );

  return (
    <Layout.ListContainer
      {...listProps}
      count={count}
      columns={columns}
      filterConfig={config}
      header="USERS"
      history={history}
      loading={loadingList}
      renderAddButton={() => <ButtonAdd handleClick={handleNewUser} title="ADD_USER" />}
      renderHeader={renderHeader}
      renderItems={renderItems}
      searchText={translate('SEARCH_USERS')}
      expandable
    />
  );
};

Users.defaultProps = {
  count: null,
};

Users.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchUserStatuses: PropTypes.func.isRequired,
  loadingList: PropTypes.bool.isRequired,
  count: PropTypes.number,
  roles: filterValues.isRequired,
  statuses: filterValues.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contact: PropTypes.string,
  })).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Users;
