export const OPERATORS = {
  BEFORE: 'BEFORE',
  BETWEEN: 'BETWEEN',
  IS: 'IS',
  IS_ONE_OF: 'IS_ONE_OF',
  MAX: 'MAX',
  MIN: 'MIN',
  SINCE: 'SINCE',
};

export const TYPES = {
  DATE: 'date',
  NUMBER: 'number',
  SELECTION: 'selection',
  TEXT: 'text',
  MULTISELECTION: 'multiselection',
};
