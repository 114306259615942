import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { HOME_ROUTE } from '../../../../constants/customizations';

const UnprotectedRoute = ({ isAuthenticated, ...props }) => (
  isAuthenticated
    ? <Redirect to={HOME_ROUTE} />
    : <Route {...props} />
);

UnprotectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default UnprotectedRoute;
