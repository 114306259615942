import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './RolesListItem.module.scss';
import ListItem from '../../../../components/List/components/ListItem';
import ListActionButtons from '../../../../components/ListActionButtons';

const UsersListItem = ({
  role, updateRole, deleteRole, setRoleToUpdate, roleToUpdate, columns,
}) => {
  const [translate] = useTranslation();
  const [initValue] = useState(role.name);
  const [newRole, setNewRole] = useState(role.name);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && roleToUpdate) {
      inputRef.current.focus();
    }
  }, [roleToUpdate]);

  const handleSubmit = () => {
    setRoleToUpdate(null);
    updateRole({ ...role, name: newRole });
  };

  const handleUpdate = () => {
    setRoleToUpdate(role.id);
    setNewRole(role.name);
  };

  const handleDelete = () => {
    deleteRole(role.id);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !(initValue === newRole)) {
      handleSubmit();
    }
  };

  const handleCancel = () => {
    setRoleToUpdate(null);
  };

  const handleRoleChange = (event, { value }) => {
    setNewRole(value);
  };

  return (
    <ListItem key={role.id} classNames={[styles.listItem, (roleToUpdate === role.id) && styles.activeListItem].join(' ')} columns={columns}>
      { (roleToUpdate === role.id)
        ? (
          <Input
            value={newRole}
            className={['border-bottom', styles.updateInput].join(' ')}
            onChange={handleRoleChange}
            ref={inputRef}
            maxLength={50}
            onKeyDown={handleKeyDown}
          />
        )
        : <span>{translate(`CUSTOM_ROLE.${role.name}`, role.name)}</span>
      }
      <ListActionButtons
        isActive={roleToUpdate === role.id}
        disableSubmit={initValue === newRole || !newRole}
        handleSubmit={handleSubmit}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
        deleteConfirmationText={translate('ARE_YOU_SURE_YOU_WANT_TO_DELETE_USER_ROLE')}
      />
    </ListItem>
  );
};

UsersListItem.propTypes = {
  role: PropTypes.shape({}).isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  setRoleToUpdate: PropTypes.func.isRequired,
  roleToUpdate: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

UsersListItem.defaultProps = {
  roleToUpdate: null,
};

export default UsersListItem;
