import queryString from 'query-string';
import pickBy from 'lodash/pickBy';

export default (history) => {
  const getParams = () => queryString.parse(history.location.search);

  const setParam = (name, value) => {
    const queryParams = queryString.stringify(pickBy({
      ...getParams(),
      [name]: value,
    }));

    const url = queryParams
      ? `${history.location.pathname}?${queryParams}`
      : history.location.pathname;

    history.push(url);
  };

  const setParams = (newParams) => {
    const queryParams = queryString.stringify(pickBy({
      ...getParams(),
      ...newParams,
    }));

    const url = queryParams
      ? `${history.location.pathname}?${queryParams}`
      : history.location.pathname;
    history.push(url);
  };

  return {
    setParam,
    setParams,
    getParams,
  };
};
