import {
  isRequired,
  hasValidAttributes,
} from '../../utils/formValidators';

export const validationSchema = {
  name: [isRequired],
  customAttributes: [hasValidAttributes],
};

export default validationSchema;
