import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ListItem from '../../../../components/List/components/ListItem';
import ResetPasswordModal from '../../../ResetPasswordModal';
import EditEmailModal from '../EditEmailModal';
import StatusCell from '../../../../components/StatusCell';
import Text from '../../../../components/Text';
import UserDetails from '../UserDetails';
import UserForm from '../UserForm';
import styles from './UsersListItem.module.scss';
import useForm from '../../../../hooks/useForm';
import { formatDate } from '../../../../utils/dateUtils';
import { validationSchemaEdit } from '../../schema';
import params from '../../../../utils/params';

const UsersListItem = ({
  columns,
  expandedId,
  setExpandedId,
  detailsById,
  editUser,
  error,
  fetchUser,
  user,
  history,
  showStatuses,
}) => {
  const { setParam } = params(history);
  const [translate] = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const {
    values, setValues, handleChange, validate, errors, clearForm, handleBlur, setFormSubmitted,
  } = useForm(validationSchemaEdit);
  const {
    createdAt, email, firstName, id, lastName,
    organizationsCount, status, username,
  } = user;

  const onExpand = () => {
    const newExpandedId = expandedId === id ? null : id;
    setExpandedId(newExpandedId);
    setParam('activeId', newExpandedId);
  };

  useEffect(() => {
    if (expandedId && expandedId === id) {
      fetchUser(expandedId);
    }
  }, [expandedId, id]);

  useEffect(() => {
    setValues({
      ...user,
      ...((status && status.id) && ({ accountStatusId: status.id })),
    });
  }, [user]);

  const handleSave = () => {
    setFormSubmitted(true);
    if (validate()) {
      setLoading(true);
      editUser(values);
    }
  };

  const handleCancel = () => {
    clearForm(user);
    setShowForm(false);
  };

  const handleEdit = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (loading && error === false) {
      setLoading(false);
      setShowForm(false);
      clearForm(user);
    } else if (loading && error) {
      setLoading(false);
      setFormError(error);
    }
  }, [loading, error]);

  const renderExpandedWrapper = () => (
    <div className={styles.expandedWrapper}>
      {showForm ? [
        <UserForm
          errors={errors}
          formError={formError}
          handleChange={handleChange}
          key="form"
          user={values}
          handleBlur={handleBlur}
        />,
        <div className={styles.actionWrapper} key="actions">
          <Button primary content={translate('SAVE')} onClick={handleSave} disabled={!!Object.keys(errors).length || !!formError} />
          <Button primary content={translate('CANCEL')} onClick={handleCancel} basic className="simple" />
        </div>,
      ] : [
        <UserDetails user={user} key="details" />,
        <div className={styles.existingRowActions} key="actions">
          {/* TODO: Implement delete logic when backend is ready */}
          {/* <Button primary basic icon="trash" className="simple" size="big" /> */}
          <Button primary basic icon="edit" className="simple" size="big" onClick={handleEdit} />
          <EditEmailModal user={user} />
          <ResetPasswordModal username={username} email={email} userId={id} />
        </div>,
      ]}

      <Dimmer active={!detailsById.includes(id)} inverted>
        <Loader active inline />
      </Dimmer>
    </div>
  );

  return (
    <ListItem
      key={id}
      onExpand={onExpand}
      expandable
      expanded={expandedId === id}
      columns={columns}
    >
      <Text bold placeholder={!firstName && !lastName}>
        {(firstName || lastName) ? `${firstName || ''} ${lastName || ''}`.trim() : translate('UNKNOWN')}
      </Text>
      <Text placeholder={!organizationsCount}>
        {organizationsCount || translate('NONE')}
      </Text>
      <Text placeholder={!email}>
        {email || translate('UNKNOWN')}
      </Text>
      <Text>{createdAt && formatDate(createdAt)}</Text>
      {showStatuses && <StatusCell status={status} />}
      {renderExpandedWrapper()}
    </ListItem>
  );
};

UsersListItem.defaultProps = {
  error: null,
  columns: [],
};

UsersListItem.propTypes = {
  detailsById: PropTypes.arrayOf(PropTypes.number).isRequired,
  editUser: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  history: PropTypes.shape({}).isRequired,
  fetchUser: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  expandedId: PropTypes.number.isRequired,
  setExpandedId: PropTypes.func.isRequired,
  showStatuses: PropTypes.bool.isRequired,
};

export default UsersListItem;
