import {
  catchError, map, mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { API_BASE_URL } from '../../config';
import { AUTH_TOKEN, SIGN_IN_PATH } from '../../packages/authentication/constants';
import { SIGN_IN_REQUEST } from '../../constants/actionTypes';
import { signInError, signInSuccess } from '../../packages/authentication/actions';

export default (action$, state$, { ajax, localStorage }) => action$.pipe(
  ofType(SIGN_IN_REQUEST),
  mergeMap(({ payload: { username, password, requiredRoles } }) => ajax({
    url: `${API_BASE_URL}${SIGN_IN_PATH}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  }).pipe(
    map(({ response: signInResponse }) => {
      if (!requiredRoles.includes(signInResponse.user.role)) {
        return signInError();
      }

      const storage = localStorage || window.localStorage;
      storage.setItem(AUTH_TOKEN, signInResponse.session.authToken);

      return signInSuccess(signInResponse, { requiredRoles });
    }),
    catchError(error => of(signInError(error))),
  )),
);
