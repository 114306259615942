import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  map, mergeMap, catchError, withLatestFrom,
} from 'rxjs/operators';

import usersSerializer from '../../utils/serializers/usersSerializer';
import { USERS_PATH } from '../../constants/paths';
import { USER_FETCH_ERROR, USER_FETCH_REQUEST } from '../../constants/actionTypes';
import { fetchUserSuccess } from '../../actions/users';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(USER_FETCH_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id } },
    { authentication: { authToken } },
  ]) => ajax({
    url: `${USERS_PATH}/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }) => fetchUserSuccess(usersSerializer(response))),
    catchError(error => of(handleError(error, USER_FETCH_ERROR))),
  )),
);
