import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonAdd from '../../components/ButtonAdd';
import FilterResults from '../../components/FilterPanel/components/FilterResults';
import Layout from '../../components/Layout';
import OrganizationsListItem from './components/OrganizationsListItem';
import OrganizationsListItemNew from './components/OrganizationsListItemNew';
import filterColumnsConfig from './filterColumnsConfig';
import filterConfig from './filterConfig';
import styles from '../Users/Users.module.scss';
import { filterValues } from '../../constants/propTypes';

const Organizations = ({
  organizations, history, fetchOrganizationStatuses, count, statuses, ...listProps
}) => {
  const [translate] = useTranslation();
  const [showAddForm, setShowAddForm] = useState(false);
  const config = filterConfig({ statuses });
  const columns = filterColumnsConfig(statuses);

  useEffect(() => {
    fetchOrganizationStatuses();
  }, []);

  const handleNewUser = () => {
    setShowAddForm(true);
  };

  const renderItems = () => [
    showAddForm && <OrganizationsListItemNew setVisible={setShowAddForm} key="new" showStatuses={!!statuses.length} />,
    ...organizations.map(organization => (
      <OrganizationsListItem
        key={organization.id}
        organization={organization}
        showStatuses={!!statuses.length}
      />
    )),
  ].filter(Boolean);

  const renderHeader = () => (
    <div className={styles.headerContainer}>
      {count !== null ? `${count} ${translate('ORGANIZATIONS')}` : ''}
      <FilterResults config={config} />
    </div>
  );

  return (
    <Layout.ListContainer
      {...listProps}
      count={count}
      columns={columns}
      filterConfig={config}
      header="ORGANIZATIONS"
      history={history}
      renderAddButton={() => <ButtonAdd handleClick={handleNewUser} title="ADD_ORGANIZATION" />}
      renderHeader={renderHeader}
      renderItems={renderItems}
      searchText={translate('SEARCH_ORGANIZATIONS')}
      expandable
    />
  );
};

Organizations.defaultProps = {
  count: null,
};

Organizations.propTypes = {
  count: PropTypes.number,
  fetch: PropTypes.func.isRequired,
  fetchOrganizationStatuses: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  statuses: filterValues.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number.isRequired,
  })).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default Organizations;
