import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import generatePassword from 'generate-password';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Icon, Input, Label,
} from 'semantic-ui-react';

import styles from './UpdatePasswordAdminForm.module.scss';

const UpdatePasswordAdminForm = ({
  values, handleChange, setValues, errors, handleBlur,
}) => {
  const [translate] = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputRef = useRef(null);

  const handleGenerate = () => {
    const value = generatePassword.generate({
      length: 12,
      numbers: true,
      strict: true,
      symbols: true,
      uppercase: true,
    });

    setValues({ password: value });
  };

  const handleCopy = () => {
    const prevPasswordVisible = passwordVisible;
    setPasswordVisible(true);

    setTimeout(() => {
      inputRef.current.select();
      document.execCommand('copy');
      setPasswordVisible(prevPasswordVisible);
    }, 0);
  };

  const handlePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Form className={styles.inputGroup}>
      <label htmlFor="upaf-password">{`${translate('NEW_PASSWORD')}:`}</label>
      <Form.Field error={!!errors.password} className={styles.inputContainer} id="upaf-password">
        <Input
          icon={<Icon link name={passwordVisible ? 'eye' : 'eye slash'} onClick={handlePasswordVisible} />}
          name="password"
          onChange={handleChange}
          placeholder={translate('PASSWORD')}
          onBlur={handleBlur}
          ref={inputRef}
          type={passwordVisible ? 'text' : 'password'}
          value={values.password || ''}
        />
        {errors.password && (
          <Label pointing="above" prompt content={errors.password} />
        )}
      </Form.Field>
      <Button.Group className={styles.buttonsContainer}>
        <Button content={translate('GENERATE')} onClick={handleGenerate} />
        <Button content={translate('COPY')} disabled={!values.password} onClick={handleCopy} primary />
      </Button.Group>
    </Form>
  );
};

UpdatePasswordAdminForm.defaultProps = {
  handleBlur: () => {},
};

UpdatePasswordAdminForm.propTypes = {
  values: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func,
};

export default UpdatePasswordAdminForm;
