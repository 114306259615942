import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Text from '../Text';
import styles from './StatusCell.module.scss';
import { ACTIVE } from '../../constants/statusActiveness';

const StatusCell = ({ status }) => {
  const [translate] = useTranslation();

  return (
    <Text placeholder={!status} classNames={styles.status}>
      {status && (
        <Icon
          name="circle"
          size="mini"
          color={status.status === ACTIVE ? 'green' : 'red'}
        />
      )}
      {status ? status.name : translate('UNKNOWN')}
    </Text>
  );
};

StatusCell.defaultProps = {
  status: null,
};

StatusCell.propTypes = {
  status: PropTypes.shape({}),
};

export default StatusCell;
