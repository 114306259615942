import { createLogger } from 'redux-logger';
import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import reducers from './reducers';
import epics from './epics';
import useAuthentication, { AUTH_TOKEN } from './packages/authentication';
import { APP_NAME } from './constants/customizations';
import { API_BASE_URL } from './config';

const [authReducer, authEpics] = useAuthentication({ baseUrl: API_BASE_URL, app: APP_NAME });

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const epicMiddleware = createEpicMiddleware();

const middleware = [epicMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

function getAuthState() {
  try {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    return authToken ? { authentication: { authToken } } : {};
  } catch (err) { return {}; }
}

const store = createStore(combineReducers({ ...reducers, ...authReducer }), getAuthState(),
  composeEnhancers(applyMiddleware(...middleware)));

const composedEpics = (...args) => combineEpics(
  ...epics,
  ...authEpics,
)(...args, { ajax, localStorage });

epicMiddleware.run(composedEpics);

export default store;
