import {
  map,
  mergeMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import queryString from 'query-string';
import pickBy from 'lodash/pickBy';
import { ORGANIZATIONS_FETCH_REQUEST, HELPER_ORGANIZATIONS_FETCH_REQUEST, ORGANIZATIONS_FETCH_ERROR } from '../../constants/actionTypes';
import { ORGANIZATIONS_LIST_LIMIT } from '../../constants/customizations';
import { fetchOrganizationsSuccess, fetchHelperOrganizationsSuccess } from '../../actions/organizations';
import { ORGANIZATIONS_PATH } from '../../constants/paths';
import organizationsSerializer from '../../utils/serializers/organizationsSerializer';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ORGANIZATIONS_FETCH_REQUEST || HELPER_ORGANIZATIONS_FETCH_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      page, search, filters, field, order, saveTempOrgList,
    },
  }, {
    authentication: { authToken },
  }]) => {
    const query = queryString.stringify(pickBy({
      offset: (page - 1) * ORGANIZATIONS_LIST_LIMIT,
      limit: ORGANIZATIONS_LIST_LIMIT,
    }));

    return ajax({
      url: `${ORGANIZATIONS_PATH}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        search,
        ...((field || order) && { sort: { field, order } }),
        ...filters,
      }),
    }).pipe(
      map(({ response: { data, count } }) => (saveTempOrgList
        ? fetchHelperOrganizationsSuccess(data)
        : fetchOrganizationsSuccess(data.map(organizationsSerializer), count)
      )),
      catchError(error => of(handleError(error, ORGANIZATIONS_FETCH_ERROR))),
    );
  }),
);
