import { API_BASE_URL } from '../config';

export const SIGN_IN_PATH = `${API_BASE_URL}/login`;
export const SIGN_OUT_PATH = `${API_BASE_URL}/logout`;
export const RESET_PASSWORD_PATH = `${API_BASE_URL}/user/$username/password`;
export const RESET_PASSWORD_TOKEN_PATH = `${API_BASE_URL}/user/$username/password/token-request`;

export const ORGANIZATIONS_PATH = `${API_BASE_URL}/organizations`;
export const ADMIN_ORGANIZATIONS_PATH = `${API_BASE_URL}/organization`;
export const ORGANIZATIONS_STATUSES_PATH = `${ORGANIZATIONS_PATH}/statuses`;
export const ORGANIZATIONS_EDIT_PATH = `${ORGANIZATIONS_PATH}/$organizationId`;
export const ASSIGN_PARENT_ORGANIZATION_PATH = `${ADMIN_ORGANIZATIONS_PATH}/$organizationId/assign-parent/$parentId`;

export const USERS_PATH = `${API_BASE_URL}/users`;
export const USERS_EDIT_PATH = `${USERS_PATH}/$userId`;

export const CUSTOM_ROLES_PATH = `${API_BASE_URL}/custom-roles`;
export const USER_STATUSES_PATH = `${API_BASE_URL}/user/statuses`;

export const EDIT_USER_EMAIL_PATH = `${USERS_PATH}/$userId/login`;
