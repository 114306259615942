import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RolesListItem from './RolesListItem';
import { updateRoleRequest, deleteRoleRequest } from '../../../../actions/roles';

const mapDispatchToProps = dispatch => ({
  updateRole: bindActionCreators(updateRoleRequest, dispatch),
  deleteRole: bindActionCreators(deleteRoleRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(RolesListItem);
