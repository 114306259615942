import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  mergeMap, catchError, withLatestFrom,
} from 'rxjs/operators';

import organizationEnrich from '../../utils/serializers/organizationEnrich';
import organizationsSerializer from '../../utils/serializers/organizationsSerializer';
import { ORGANIZATIONS_PATH } from '../../constants/paths';
import NotificationType from '../../constants/notificationTypes';
import { ORGANIZATION_CREATE_REQUEST, ORGANIZATION_CREATE_SUCCESS, ORGANIZATION_CREATE_ERROR } from '../../constants/actionTypes';
import { createOrganizationSuccess, assignParentOrganization } from '../../actions/organizations';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ORGANIZATION_CREATE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { organization, parentId } }, { authentication: { authToken } }]) => ajax({
    url: ORGANIZATIONS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(organizationEnrich(organization)),
  }).pipe(
    mergeMap(({ response }) => [
      addNotification(NotificationType.SUCCESS, ORGANIZATION_CREATE_SUCCESS),
      createOrganizationSuccess(organizationsSerializer(response)),
      parentId && assignParentOrganization(response, parentId),
    ].filter(Boolean)),
    catchError(error => of(handleError(error, ORGANIZATION_CREATE_ERROR))),
  )),
);
