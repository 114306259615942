import includes from 'lodash/includes';

import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  NEW_ROLE_REQUEST,
  NEW_ROLE_SUCCESS,
  NEW_ROLE_MODAL_OPEN,
  NEW_ROLE_MODAL_CLOSE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  FETCH_ROLES_ERROR,
  NEW_ROLE_ERROR,
  UPDATE_ROLE_ERROR,
  DELETE_ROLE_CONFLICT_ERROR,
  ERROR,
} from '../constants/actionTypes';

const initialState = {
  loading: false,
  byHash: {},
  byId: [],
  fetchRolesError: false,
  updateRoleError: false,
  deleteRoleError: false,
  newRoleError: false,
  newRoleModalOpened: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        byHash: payload.roles
          .reduce((byHash, role) => ({
            ...byHash,
            [role.id]: role,
          }), state.byHash),
        byId: payload.roles.map(role => role.id),
        fetchRolesError: false,
      };
    case UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ROLE_SUCCESS: {
      const { updatedRole } = payload;
      return {
        ...state,
        updateRoleError: false,
        loading: false,
        byHash: {
          ...state.byHash,
          [updatedRole.id]: { ...state.byHash[updatedRole.id], ...updatedRole },
        },
      };
    }
    case NEW_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_ROLE_SUCCESS: {
      const { newRole } = payload;
      return {
        ...state,
        loading: false,
        newRoleError: false,
        byHash: { [newRole.id]: { ...newRole }, ...state.byHash },
        byId: [newRole.id, ...state.byId],
      };
    }
    case NEW_ROLE_MODAL_OPEN:
      return {
        ...state,
        newRoleModalOpened: true,
      };
    case NEW_ROLE_MODAL_CLOSE:
      return {
        ...state,
        newRoleModalOpened: false,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROLE_SUCCESS: {
      const { [payload.id]: deletedValue, ...newByHash } = state.byHash;
      return {
        ...state,
        deleteRoleError: false,
        loading: false,
        byId: state.byId.filter(item => item !== payload.id),
        byHash: newByHash,
      };
    }
    case ERROR:
      return includes(
        [
          DELETE_ROLE_ERROR,
          DELETE_ROLE_CONFLICT_ERROR,
          FETCH_ROLES_ERROR,
          NEW_ROLE_ERROR,
          UPDATE_ROLE_ERROR,
        ],
        payload.actionType,
      ) ? { ...state, loading: false } : state;
    default:
      return state;
  }
};
