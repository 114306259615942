import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Image } from 'semantic-ui-react';

import ListContainer from './components/ListContainer';
import Sidebar from '../Sidebar';
import styles from './layout.module.scss';

class Layout extends PureComponent {
  static ListContainer = ListContainer;

  render() {
    const { children, signOut } = this.props;

    return (
      <div className={styles.wrapper}>
        <Sidebar signOut={signOut} />
        <Image src="/images/layout-background.svg" centered className={styles.background} />

        <div className={styles.container}>
          {children}
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Layout;
