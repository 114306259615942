import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Input, Label, Modal,
} from 'semantic-ui-react';
import styles from './EditEmailModal.module.scss';
import useForm from '../../../../hooks/useForm';
import { validationSchema } from './schema';

const EditEmailModal = ({
  editUserEmail, user, loading, error, success, complete,
}) => {
  const [translate] = useTranslation();
  const [modalOpened, setModalOpened] = useState(false);
  const {
    values, errors, clearForm, handleChange, handleBlur,
  } = useForm(validationSchema);

  const handleSubmit = () => {
    editUserEmail(user, values.email);
  };

  const toggleModal = () => {
    setModalOpened(isOpened => !isOpened);
    clearForm({});
  };

  useEffect(() => {
    if (success) {
      toggleModal();
      complete();
    }
  }, [success]);

  return (
    <Modal
      closeIcon
      onClose={toggleModal}
      open={modalOpened}
      size="small"
      trigger={<Button content={translate('EDIT_EMAIL')} onClick={toggleModal} circular primary />}
    >
      <Modal.Header content={translate('EDIT_EMAIL')} />
      <Modal.Content className={styles.modalContent}>
        <Form
          onSubmit={handleSubmit}
          loading={loading}
          className={styles.form}
        >
          <Form.Field error={!!errors.email || !!error} className={[styles.formField, styles.formFieldMobile].join(' ')}>
            <label htmlFor="update-email-input">{translate('EMAIL.LABEL')}</label>
            <Input
              value={values.email || ''}
              errors={errors.email || error}
              id="update-email-input"
              className={styles.formInput}
              name="email"
              fluid
              maxLength={50}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translate('EMAIL.LABEL')}
            />
            {(errors.email || error) && (
              <Label pointing="above" prompt content={errors.email || translate(`MESSAGES.${error.toUpperCase()}`)} />
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={translate('CANCEL')} onClick={toggleModal} primary basic className="simple" />
        <Button content={translate('OK')} onClick={handleSubmit} primary disabled={!!errors.email || loading} />
      </Modal.Actions>
    </Modal>
  );
};

export default EditEmailModal;
