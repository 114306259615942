import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import OrganizationsForm from './OrganizationsForm';
import { fetchOrganizationsRequest } from '../../../../actions/organizations';

const mapStateToProps = ({
  roles: { byId: rolesById, byHash: rolesByHash },
  organizations: { byId: organizationsById, byHash: organizationsByHash },
  userStatuses: { byId: statusesById, byHash: statusesByHash },
}) => ({
  organizationsOptions: organizationsById.map(id => ({
    value: id,
    text: organizationsByHash[id].name,
  })),
  organizationsByHash,
  rolesOptions: rolesById
    .map(id => rolesByHash[id])
    .map(({ name }) => ({ value: name, text: name })),
  statusesOptions: statusesById
    .map(id => statusesByHash[id])
    .map(({ name }) => ({ value: name, text: name })),
});

const mapDispatchToProps = dispatch => ({
  fetchOrganizations: bindActionCreators(fetchOrganizationsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsForm);
