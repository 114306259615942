import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Users from './Users';
import { fetchRolesRequest } from '../../actions/roles';
import { fetchUserStatusesRequest } from '../../actions/userStatuses';
import { fetchUsersRequest, fetchUserRequest } from '../../actions/users';

const getValues = userStatuses => userStatuses.byId
  .map(id => userStatuses.byHash[id])
  .map(({ id, name }) => ({ value: id.toString(), text: name }));

const mapStateToProps = ({
  users: {
    byId,
    byHash,
    loadingList,
    count,
  },
  userStatuses,
  roles,
}) => ({
  users: byId.map(id => byHash[id]),
  loadingList,
  count,
  roles: getValues(roles),
  statuses: getValues(userStatuses),
});

const mapDispatchToProps = dispatch => ({
  fetch: bindActionCreators(fetchUsersRequest, dispatch),
  fetchRoles: bindActionCreators(fetchRolesRequest, dispatch),
  fetchUserStatuses: bindActionCreators(fetchUserStatusesRequest, dispatch),
  fetchUser: bindActionCreators(fetchUserRequest, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
