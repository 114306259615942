import PropTypes from 'prop-types';
import React, { Children } from 'react';
import classNames from 'classnames';
import { Button, Icon } from 'semantic-ui-react';

import styles from './ListItem.module.scss';

const ListItem = ({
  children,
  columns,
  itemOnClick,
  onExpand,
  expandable,
  classNames: itemClassNames,
  expanded,
}) => {
  const expandOnClick = (event) => {
    event.stopPropagation();
    onExpand();
  };

  const filteredChildren = Children.toArray(children).filter(Boolean);

  const expandSuffix = (child, index) => (
    <div
      className={classNames(
        styles.column,
        styles['action-justify'],
        styles[`flex-${columns[index].size}`],
      )}
      key="expand-suffix"
    >
      {child}
      {!!onExpand && (
        <Button basic className="simple" onClick={expandOnClick} icon>
          <Icon name={`chevron ${expanded ? 'up' : 'down'}`} />
        </Button>
      )}
    </div>
  );

  const expandedContent = () => {
    const lastChild = filteredChildren[filteredChildren.length - 1];

    return expanded && expandable && (
      <div
        key="extendedContent"
        className={styles.extendedContent}
        role="button"
        tabIndex={0}
        onClick={e => e.stopPropagation()}
        onKeyPress={e => e.stopPropagation()}
      >
        {lastChild}
      </div>
    );
  };

  const commonColumn = (child, index) => (
    columns[index] && (
      <div
        key={`${columns[index].header}${index}`}
        className={classNames(
          styles.column,
          styles[`align-content-${columns[index].align}`],
          styles[`flex-${columns[index].size}`],
        )}
      >
        {child}
      </div>
    ));

  const detectAdditionalRowOptions = index => (
    index === filteredChildren.length - 2 && expandable ? expandSuffix : commonColumn
  );

  return (
    <div
      onClick={itemOnClick}
      onKeyPress={itemOnClick}
      role="button"
      tabIndex={0}
      className={classNames(
        styles.row,
        itemOnClick && styles.clickable,
        itemClassNames,
        expanded && 'expanded',
      )}
    >
      {Array.isArray(filteredChildren) ? filteredChildren.map((child, index) => (
        detectAdditionalRowOptions(index)(child, index)
      )) : commonColumn(filteredChildren, 0)}
      {expandedContent()}
    </div>
  );
};

ListItem.propTypes = {
  itemOnClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  expandable: PropTypes.bool,
  onExpand: PropTypes.func,
  classNames: PropTypes.string,
  expanded: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({
    size: PropTypes.number,
    align: PropTypes.string,
    header: PropTypes.string,
  })),
};

ListItem.defaultProps = {
  itemOnClick: null,
  expandable: false,
  onExpand: null,
  classNames: '',
  expanded: false,
  columns: [],
};

export default ListItem;
