import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Header, Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styles from './ParentOrgSection.module.scss';
import { ORGANIZATIONS_ROUTE } from '../../../../constants/customizations';

const ParentOrgSection = ({ parent }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.parentOrganizationWrapper}>
      <Header as="h4" className={styles.sectionHeader}>{translate('PARENT_ORGANIZATION')}</Header>
      <Table basic="very" className={styles.parentOrgTable}>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {parent.name}
            </Table.Cell>
            <Table.Cell collapsing>
              <Link to={`${ORGANIZATIONS_ROUTE}?activeId=${parent.id}&search=${parent.name}`}>
                {translate('VIEW_DETAILS')}
              </Link>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

ParentOrgSection.propTypes = {
  parent: PropTypes.shape({}).isRequired,
};

export default ParentOrgSection;
