import { SIGN_IN_REQUEST } from '../constants/actionTypes';

const signInRequest = (username, password, requiredRoles = []) => ({
  type: SIGN_IN_REQUEST,
  payload: {
    username,
    password,
    requiredRoles,
  },
});

export default signInRequest;
