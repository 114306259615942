import application from './application';
import notifications from './notifications';
import users from './users';
import organizations from './organizations';
import userStatuses from './userStatuses';
import organizationStatuses from './organizationStatuses';
import roles from './roles';

export default {
  application,
  notifications,
  users,
  organizations,
  roles,
  userStatuses,
  organizationStatuses,
};
