import {
  SET_MENU_EXPANDED,
} from '../constants/actionTypes';

const setMenuExpanded = value => ({
  type: SET_MENU_EXPANDED,
  payload: {
    value,
  },
});

export default setMenuExpanded;
