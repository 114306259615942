import PropTypes from 'prop-types';
import React, { useState } from 'react';
import escapeString from 'escape-string-regexp';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import slice from 'lodash/fp/slice';
import sortBy from 'lodash/fp/sortBy';
import { Dropdown, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import FilterDatePicker from '../FilterDatePicker';
import styles from './FilterField.module.scss';
import { DROPDOWN_OPTIONS_LIMIT } from '../../../../constants/customizations';
import { OPERATORS, TYPES } from '../../constants';
import { filterConfigType } from '../../../../constants/propTypes';

const FilterField = ({
  filter: { name: filterName, key, type },
  config,
  fieldValue,
  operatorValue,
  setFilterForName,
  setOperatorForName,
}) => {
  const [translate] = useTranslation();
  const [search, setSearch] = useState('');
  const searchRegex = new RegExp(escapeString(search), 'ig');

  const convertToDropdownOptions = ({ value, text }) => ({
    key: value,
    text: translate(text || value),
    value,
  });

  const operatorsDropdownOptions = [
    OPERATORS.SINCE, OPERATORS.BEFORE, OPERATORS.BETWEEN,
  ].map(value => convertToDropdownOptions({ value }));

  const filterValuesDropdownOptions = name => flow(
    find({ name }),
    getOr([], 'values'),
    filter(({ text, value }) => (
      text.toLowerCase().search(searchRegex) !== -1 || value === fieldValue
    )),
    sortBy(({ value }) => value !== fieldValue),
    slice(0, DROPDOWN_OPTIONS_LIMIT),
    map(convertToDropdownOptions),
  )(config);

  switch (type) {
    case TYPES.DATE:
      return (
        <Form.Group inline className={styles.groupInline} key={filterName}>
          <label htmlFor="filter-field-date-type">{translate(filterName)}</label>
          <Dropdown
            fluid
            id="filter-field-date-type"
            onChange={(event, { value }) => setOperatorForName(key, value)}
            options={operatorsDropdownOptions}
            search
            selection
            value={operatorValue}
          />
          {operatorValue && (
            <FilterDatePicker
              onChange={value => setFilterForName(key, value)}
              operator={operatorValue}
              fieldValue={fieldValue || {}}
            />
          )}
        </Form.Group>
      );
    case TYPES.SELECTION:
      return (
        <Form.Dropdown
          fluid
          key={key}
          lazyLoad
          onChange={(event, { value }) => setFilterForName(key, value)}
          onSearchChange={(event, { searchQuery }) => setSearch(searchQuery)}
          options={filterValuesDropdownOptions(filterName)}
          placeholder={translate(filterName)}
          search
          selection
          value={fieldValue}
        />
      );
    case TYPES.MULTISELECTION:
      return (
        <Form.Dropdown
          fluid
          key={key}
          lazyLoad
          onChange={(event, { value }) => setFilterForName(key, value)}
          onSearchChange={(event, { searchQuery }) => setSearch(searchQuery)}
          options={filterValuesDropdownOptions(filterName)}
          placeholder={translate(filterName)}
          search
          multiple
          selection
          value={fieldValue}
        />
      );
    case TYPES.NUMBER:
      return (
        <Form.Input
          defaultValue={fieldValue && fieldValue.from}
          fluid
          key={key}
          onChange={(event, { value }) => setFilterForName(key, { from: value, to: value })}
          placeholder={translate(filterName)}
          type="number"
        />
      );
    case TYPES.TEXT:
    default:
      return (
        <Form.Input
          fluid
          key={key}
          maxLength={50}
          onChange={(event, { value }) => setFilterForName(key, value)}
          placeholder={translate(filterName)}
        />
      );
  }
};

FilterField.defaultProps = {
  operatorValue: null,
  fieldValue: null,
};

FilterField.propTypes = {
  filter: PropTypes.shape({}).isRequired,
  config: filterConfigType.isRequired,
  fieldValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  operatorValue: PropTypes.string,
  setFilterForName: PropTypes.func.isRequired,
  setOperatorForName: PropTypes.func.isRequired,
};

export default FilterField;
