import includes from 'lodash/includes';
import {
  ERROR,
  FETCH_ORGANIZATION_STATUSES_REQUEST,
  FETCH_ORGANIZATION_STATUSES_SUCCESS,
  UPDATE_ORGANIZATION_STATUS_REQUEST,
  UPDATE_ORGANIZATION_STATUS_SUCCESS,
  NEW_ORGANIZATION_STATUS_REQUEST,
  NEW_ORGANIZATION_STATUS_SUCCESS,
  NEW_ORGANIZATION_STATUS_MODAL_OPEN,
  NEW_ORGANIZATION_STATUS_MODAL_CLOSE,
  DELETE_ORGANIZATION_STATUS_REQUEST,
  DELETE_ORGANIZATION_STATUS_CONFLICT_ERROR,
  FETCH_ORGANIZATION_STATUSES_ERROR,
  UPDATE_ORGANIZATION_STATUS_ERROR,
  DELETE_ORGANIZATION_STATUS_ERROR,
  NEW_ORGANIZATION_STATUS_ERROR,
  DELETE_ORGANIZATION_STATUS_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  loading: false,
  byId: [],
  byHash: {},
  newOrganizationStatusModalOpened: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORGANIZATION_STATUSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORGANIZATION_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        byHash: payload.organizationStatuses
          .reduce((byHash, status) => ({
            ...byHash,
            [status.id]: status,
          }), state.byHash),
        byId: payload.organizationStatuses.map(status => status.id),
      };
    case UPDATE_ORGANIZATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ORGANIZATION_STATUS_SUCCESS: {
      const { status } = payload;
      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [status.id]: { ...state.byHash[status.id], ...status },
        },
      };
    }
    case NEW_ORGANIZATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_ORGANIZATION_STATUS_SUCCESS: {
      const { status } = payload;
      return {
        ...state,
        loading: false,
        byHash: { [status.id]: { ...status }, ...state.byHash },
        byId: [status.id, ...state.byId],
      };
    }
    case NEW_ORGANIZATION_STATUS_MODAL_OPEN:
      return {
        ...state,
        newOrganizationStatusModalOpened: true,
      };
    case NEW_ORGANIZATION_STATUS_MODAL_CLOSE:
      return {
        ...state,
        newOrganizationStatusModalOpened: false,
      };
    case DELETE_ORGANIZATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ORGANIZATION_STATUS_SUCCESS: {
      const { [payload.id]: deletedValue, ...newByHash } = state.byHash;
      return {
        ...state,
        loading: false,
        byId: state.byId.filter(item => item !== payload.id),
        byHash: newByHash,
      };
    }
    case ERROR:
      return includes(
        [
          FETCH_ORGANIZATION_STATUSES_ERROR,
          UPDATE_ORGANIZATION_STATUS_ERROR,
          DELETE_ORGANIZATION_STATUS_ERROR,
          DELETE_ORGANIZATION_STATUS_CONFLICT_ERROR,
          NEW_ORGANIZATION_STATUS_ERROR,
        ],
        payload.actionType,
      ) ? { ...state, loading: false } : state;
    default:
      return state;
  }
};
