import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { ORGANIZATIONS_STATUSES_PATH } from '../../constants/paths';
import {
  UPDATE_ORGANIZATION_STATUS_ERROR,
  UPDATE_ORGANIZATION_STATUS_REQUEST,
  UPDATE_ORGANIZATION_STATUS_SUCCESS,
} from '../../constants/actionTypes';
import { updateOrganizationStatusSuccess } from '../../actions/organizationStatuses';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(UPDATE_ORGANIZATION_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { status: { id, ...status } } },
    { authentication: { authToken } },
  ]) => ajax({
    url: `${ORGANIZATIONS_STATUSES_PATH}/${id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(status),
  }).pipe(
    mergeMap(({ response: updatedOrgStatus }) => [
      addNotification(NotificationType.SUCCESS, UPDATE_ORGANIZATION_STATUS_SUCCESS),
      updateOrganizationStatusSuccess(updatedOrgStatus),
    ]),
    catchError(error => of(
      handleError(error, UPDATE_ORGANIZATION_STATUS_ERROR),
    )),
  )),
);
