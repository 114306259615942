export default [{
  header: 'STATUS_NAME',
  align: 'left',
  size: 2,
}, {
  header: 'ACTIVE',
  align: 'left',
  size: 2,
}, {
  header: '',
  size: 3,
  align: 'right',
}];
