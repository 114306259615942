import { v4 as uuidv4 } from 'uuid';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants/actionTypes';

export const addNotification = (
  type,
  actionType,
  status = null,
  message = '',
  id = uuidv4(),
) => ({
  type: ADD_NOTIFICATION,
  payload: {
    type,
    actionType,
    status,
    message,
    id,
  },
});

export const removeNotification = id => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    id,
  },
});
