import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './NotificationMessage.module.scss';
import NotificationType from '../../../../constants/notificationTypes';

const NotificationMessage = ({
  notification, removeNotification,
}) => {
  const [translate] = useTranslation();
  const [progressActive, setProgressActive] = useState(false);
  const {
    id, type, actionType,
  } = notification;

  useEffect(() => {
    setTimeout(() => setProgressActive(true), 0);
  }, []);

  return (
    <Message
      icon
      onDismiss={() => removeNotification(id)}
      negative={type === NotificationType.ERROR}
      positive={type === NotificationType.SUCCESS}
    >
      {type === NotificationType.ERROR ? <Icon name="x" /> : <Icon name="check" />}
      <Message.Content>
        <Message.Header content={translate(`MESSAGES.${actionType}_HEADER`)} />
        <p>{translate(`MESSAGES.${actionType}_TEXT`)}</p>
      </Message.Content>

      <div className={[styles.progress, progressActive ? styles.progressActive : ''].join(' ')} />
    </Message>
  );
};

NotificationMessage.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
  }).isRequired,
  removeNotification: PropTypes.func.isRequired,
};

export default NotificationMessage;
