import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { completeEditUserEmail, editUserEmail } from '../../../../actions/users';

import EditEmailModal from './EditEmailModal';

const mapDispatchToProps = dispatch => ({
  editUserEmail: bindActionCreators(editUserEmail, dispatch),
  complete: bindActionCreators(completeEditUserEmail, dispatch),
});

const mapStateToProps = ({
  users: {
    updateEmailSuccess, updateEmailRequest, updateEmailError,
  },
}) => ({
  loading: updateEmailRequest,
  success: updateEmailSuccess,
  error: updateEmailError,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailModal);
