import React from 'react';
import queryString from 'query-string';
import { Header, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import RequestTokenForm from './components/RequestTokenForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import { locationType } from '../../constants/propTypes';
import { styles } from '../../components/LayoutLogin';

const ResetPassword = ({ location }) => {
  const { resettoken: resetToken, username } = queryString.parse(location.search);
  const [translate] = useTranslation();

  return (
    <>
      <Image src="/images/bloom-logo.svg" centered className={styles.logo} />
      <Header as="h2" content={translate('RESET_PASSWORD')} />

      {!resetToken ? (
        <RequestTokenForm />
      ) : (
        <UpdatePasswordForm token={resetToken} username={username} />
      )}
    </>
  );
};

ResetPassword.propTypes = {
  location: locationType.isRequired,
};

export default ResetPassword;
