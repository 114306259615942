import {
  FETCH_USER_STATUSES_REQUEST,
  FETCH_USER_STATUSES_SUCCESS,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
  NEW_USER_STATUS_REQUEST,
  NEW_USER_STATUS_SUCCESS,
  NEW_USER_STATUS_MODAL_OPEN,
  NEW_USER_STATUS_MODAL_CLOSE,
  DELETE_USER_STATUS_REQUEST,
  DELETE_USER_STATUS_SUCCESS,
} from '../constants/actionTypes';

export const fetchUserStatusesRequest = () => ({
  type: FETCH_USER_STATUSES_REQUEST,
});

export const fetchUserStatusesSuccess = userStatuses => ({
  type: FETCH_USER_STATUSES_SUCCESS,
  payload: {
    userStatuses,
  },
});

export const updateUserStatusRequest = status => ({
  type: UPDATE_USER_STATUS_REQUEST,
  payload: {
    status,
  },
});

export const updateUserStatusSuccess = status => ({
  type: UPDATE_USER_STATUS_SUCCESS,
  payload: {
    status,
  },
});

export const newUserStatusRequest = status => ({
  type: NEW_USER_STATUS_REQUEST,
  payload: {
    status,
  },
});

export const newUserStatusSuccess = status => ({
  type: NEW_USER_STATUS_SUCCESS,
  payload: {
    status,
  },
});

export const openModal = () => ({
  type: NEW_USER_STATUS_MODAL_OPEN,
});

export const closeModal = () => ({
  type: NEW_USER_STATUS_MODAL_CLOSE,
});

export const deleteUserStatusRequest = id => ({
  type: DELETE_USER_STATUS_REQUEST,
  payload: {
    id,
  },
});

export const deleteUserStatusSuccess = id => ({
  type: DELETE_USER_STATUS_SUCCESS,
  payload: {
    id,
  },
});
