import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OrganizationStatusListItem from './OrganizationStatusListItem';

import { updateOrganizationStatusRequest, deleteOrganizationStatusRequest } from '../../../../actions/organizationStatuses';

const mapDispatchToProps = dispatch => ({
  updateOrganizationStatus: bindActionCreators(updateOrganizationStatusRequest, dispatch),
  deleteOrganizationStatus: bindActionCreators(deleteOrganizationStatusRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(OrganizationStatusListItem);
