import get from 'lodash/get';
import {
  ERROR,
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USER_FETCH_ERROR,
  USERS_FETCH_ERROR,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_ERROR,
  USER_FETCH_REQUEST,
  USER_FETCH_SUCCESS,
  USER_CREATE_ERROR,
  EDIT_USER_EMAIL_SUCCESS,
  EDIT_USER_EMAIL_REQUEST,
  EDIT_USER_EMAIL_ERROR,
  EDIT_USER_EMAIL_COMPLETE,
} from '../constants/actionTypes';
import { DEFAULT_LIST_LIMIT } from '../constants/customizations';

const initialState = {
  count: null,
  error: null,
  loadingList: false,
  loading: false,
  byHash: {},
  byId: [],
  detailsById: [],
  updateEmailSuccess: false,
  updateEmailRequest: false,
  updateEmailError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USERS_FETCH_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case USERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingList: false,
        byHash: payload.users
          .reduce((byHash, user) => ({
            ...byHash,
            [user.id]: user,
          }), state.byHash),
        byId: payload.users.map(user => user.id),
        count: payload.count,
      };
    case USER_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_CREATE_REQUEST:
    case USER_EDIT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case USER_CREATE_SUCCESS: {
      const { user } = payload;
      const { byHash, byId, count } = state;

      return {
        ...state,
        loading: false,
        count: count + 1,
        error: false,
        byId: [
          user.id,
          ...byId,
        ].slice(0, DEFAULT_LIST_LIMIT),
        byHash: {
          ...byHash,
          [user.id]: {
            ...byHash[user.id],
            ...user,
          },
        },
        detailsById: [
          user.id,
          ...state.detailsById.filter(id => user.id !== id),
        ],
      };
    }
    case USER_EDIT_SUCCESS:
    case USER_FETCH_SUCCESS: {
      const { user } = payload;
      const { byHash } = state;

      return {
        ...state,
        loading: false,
        error: false,
        byHash: {
          ...byHash,
          [user.id]: {
            ...byHash[user.id],
            ...user,
          },
        },
        byId: [
          ...(state.byId.includes(user.id) ? [] : [user.id]),
          ...state.byId,
        ],
        detailsById: [
          user.id,
          ...state.detailsById.filter(id => user.id !== id),
        ],
      };
    }
    case EDIT_USER_EMAIL_SUCCESS: {
      const { user } = payload;
      const { byHash } = state;
      return {
        ...state,
        updateEmailSuccess: true,
        updateEmailRequest: false,
        updateEmailError: null,
        byHash: {
          ...byHash,
          [user.id]: {
            ...byHash[user.id],
            ...user,
          },
        },
        byId: [
          ...(state.byId.includes(user.id) ? [] : [user.id]),
          ...state.byId,
        ],
        detailsById: [
          user.id,
          ...state.detailsById.filter(id => user.id !== id),
        ],
      };
    }
    case EDIT_USER_EMAIL_REQUEST: {
      return {
        ...state,
        updateEmailSuccess: false,
        updateEmailRequest: true,
        updateEmailError: null,
      };
    }
    case EDIT_USER_EMAIL_COMPLETE: {
      return {
        ...state,
        updateEmailSuccess: false,
        updateEmailRequest: false,
        updateEmailError: null,
      };
    }
    case ERROR: {
      switch (payload.actionType) {
        case USER_FETCH_ERROR:
        case USERS_FETCH_ERROR:
          return {
            ...state,
            loadingList: false,
            loadingDetails: false,
          };
        case USER_EDIT_ERROR:
        case USER_CREATE_ERROR:
          return {
            ...state,
            error: get(payload, 'error.response.message'),
          };
        case EDIT_USER_EMAIL_ERROR:
          return {
            ...state,
            updateEmailRequest: false,
            updateEmailSuccess: false,
            updateEmailError: get(payload, 'error.response.message'),
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
};
