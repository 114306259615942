import PropTypes from 'prop-types';
import React, { useState } from 'react';

import FilterForm from './components/FilterForm';
import useFilter from '../../hooks/useFilter';
import { filterConfigType } from '../../constants/propTypes';

const FilterPanel = ({ config, onClose, history }) => {
  const [filters, operators, onChange] = useFilter(history);
  const [filtersValues, setFiltersValues] = useState(filters);
  const [operatorsValues, setOperatorsValues] = useState(operators);

  const handleReset = () => {
    setFiltersValues({});
    setOperatorsValues({});
    onChange();
  };

  const handleSubmit = () => {
    onChange(filtersValues, operatorsValues);
    onClose();
  };

  return (
    <FilterForm
      config={config}
      onReset={handleReset}
      onSubmit={handleSubmit}
      filters={filtersValues}
      setFilters={setFiltersValues}
      operators={operatorsValues}
      setOperators={setOperatorsValues}
    />
  );
};

FilterPanel.propTypes = {
  config: filterConfigType.isRequired,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default FilterPanel;
