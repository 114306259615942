import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Header,
} from 'semantic-ui-react';

import DetailsRow from '../../../../components/DetailsRow';
import ParentOrgSection from '../ParentOrgSection';
import styles from './OrganizationDetails.module.scss';
import StatusCell from '../../../../components/StatusCell';
import ChildrenOrgSection from '../ChildrenOrgSection';

const OrganizationDetails = ({
  organization: {
    name, accountStatus, userCount, customAttributes, children: childrenOrganizations = [], parent,
  },
}) => {
  const [translate] = useTranslation();

  const customAttributesSection = (
    <>
      <Header as="h4" className={styles.customAttributesHeader}>{translate('CUSTOM_ATTRIBUTES')}</Header>
      {customAttributes.map(({
        key, id, name: attrName, value,
      }) => (<DetailsRow key={key || id} label={attrName} value={value} labelWidth="large" />))}
    </>
  );

  return (
    <div className={[styles.detailsWrapper, styles.noPadding].join(' ')} key="details">
      <DetailsRow label="NAME" value={name} />
      <DetailsRow label="NUMBER_USERS" value={!userCount || userCount === 0 ? '0' : userCount} />
      <DetailsRow label="STATUS" component={(<StatusCell status={accountStatus} />)} />
      {customAttributes && !!customAttributes.length && customAttributesSection}
      {parent && <ParentOrgSection parent={parent} />}
      {childrenOrganizations && !!childrenOrganizations.length
        && <ChildrenOrgSection childrenOrganizations={childrenOrganizations} />}
    </div>
  );
};

OrganizationDetails.propTypes = {
  organization: PropTypes.shape({}).isRequired,
};

export default OrganizationDetails;
