import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import OrganizationsListItem from './OrganizationsListItem';
import {
  editOrganizationRequest, fetchOrganizationRequest,
  fetchChildrenOrganizationsRequest, assignParentOrganization,
} from '../../../../actions/organizations';

const mapStateToProps = ({ organizations: { detailsById, errorAssignParentId } }) => ({
  detailsById,
  errorAssignParentId,
});

const mapDispatchToProps = dispatch => ({
  editOrganization: bindActionCreators(editOrganizationRequest, dispatch),
  fetchOrganization: bindActionCreators(fetchOrganizationRequest, dispatch),
  fetchChildrenOrganizations: bindActionCreators(fetchChildrenOrganizationsRequest, dispatch),
  assignParentOrganization: bindActionCreators(assignParentOrganization, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsListItem));
