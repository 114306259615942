import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DetailsRow.module.scss';
import Text from '../Text';

const DetailsRow = ({
  label, value, component, labelWidth,
}) => {
  const [translate] = useTranslation();

  return (
    <div key={label} className={[styles.detailsRow, styles[`labelWidth-${labelWidth}`]].join(' ')}>
      <Text translate label>{label}</Text>
      {component || (<Text>{value || translate('UNDEFINED')}</Text>)}
    </div>
  );
};

DetailsRow.defaultProps = {
  value: null,
  component: null,
  labelWidth: 'medium',
};

DetailsRow.propTypes = {
  label: PropTypes.string.isRequired,
  labelWidth: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  component: PropTypes.node,
};

export default DetailsRow;
