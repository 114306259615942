import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Header,
} from 'semantic-ui-react';

import DetailsRow from '../../../../components/DetailsRow';
import OrganizationsList from '../OrganizationsDetails';
import styles from './UserDetails.module.scss';
import StatusCell from '../../../../components/StatusCell';
import { languages } from '../../../../utils/languages';
import { countries } from '../../../../utils/countries';

const UserDetails = ({
  user: {
    country,
    email,
    firstName,
    language,
    lastName,
    memberships,
    role,
    status,
    customAttributes,
  },
}) => {
  const [tCountries] = useTranslation('countries');
  const [tLanguage] = useTranslation('languages');
  const [countryTranslation, setCountryTranslation] = useState();
  const [languageTranslation, setLanguageTranslation] = useState();
  const [translate] = useTranslation();

  const translateLanguage = () => {
    if (language) {
      const langEntry = languages.find(
        ({ langCode }) => langCode.toUpperCase() === language.toUpperCase(),
      ).name;
      const translationKey = langEntry.replace(' ', '_');
      setLanguageTranslation(translationKey);
    }
  };

  const translateCountry = () => {
    if (country) {
      const countryEntry = countries.find(
        ({ countryCode }) => countryCode.toUpperCase() === country.toUpperCase(),
      ).name;
      const translationKey = countryEntry.replace(' ', '_');
      setCountryTranslation(translationKey);
    }
  };

  useEffect(() => {
    translateLanguage();
    translateCountry();
  }, [language, country]);

  const customAttributesSection = (
    <>
      <Header as="h4" className={styles.customAttributesHeader}>{translate('CUSTOM_ATTRIBUTES')}</Header>
      {customAttributes.map(({
        key, id, name, value,
      }) => (<DetailsRow key={key || id} label={name} value={value} labelWidth="large" />))}
    </>
  );

  return [
    <div className={[styles.detailsWrapper, styles.noPadding].join(' ')} key="details">
      <DetailsRow label="NAME" value={(firstName || lastName) ? `${firstName || ''} ${lastName || ''}`.trim() : translate('UNKNOWN')} />
      <DetailsRow label="EMAIL.LABEL" value={email || translate('UNKNOWN')} />

      <div className={styles.horizontalWrapper}>
        { role && <DetailsRow label="ROLE" value={translate(role)} /> }
        { status && <DetailsRow label="STATUS" component={(<StatusCell status={status} />)} /> }
      </div>
      <div className={styles.horizontalWrapper}>
        { countryTranslation && <DetailsRow label="COUNTRY" value={tCountries(countryTranslation.toUpperCase())} /> }
        { languageTranslation && <DetailsRow label="LANGUAGE" value={tLanguage(languageTranslation.toUpperCase())} /> }
      </div>
      {customAttributes && !!customAttributes.length && customAttributesSection}
    </div>,
    <OrganizationsList organizations={memberships} key="organizations" />,
  ];
};

UserDetails.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default UserDetails;
