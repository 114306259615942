import PropTypes from 'prop-types';
import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ButtonAdd.module.scss';

const ButtonAdd = ({ title, handleClick }) => {
  const [translate] = useTranslation();

  return (
    <Button circular className={styles.addButton} onClick={handleClick}>
      <Image src="/images/icons/plus.svg" />
      <span>{translate(title)}</span>
    </Button>
  );
};

ButtonAdd.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ButtonAdd;
