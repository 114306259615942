import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './SortArrows.module.scss';
import { ASC } from '../../hooks/useSorting';

const SortArrows = ({ sortOrder }) => (
  <div className={styles.container}>
    <Icon name={sortOrder === ASC ? 'triangle up' : 'triangle down'} />
  </div>
);

SortArrows.defaultProps = {
  sortOrder: null,
};

SortArrows.propTypes = {
  sortOrder: PropTypes.string,
};

export default SortArrows;
