import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import usersSerializer from '../../utils/serializers/usersSerializer';
import { USERS_EDIT_PATH } from '../../constants/paths';
import { USER_EDIT_ERROR, USER_EDIT_REQUEST, USER_EDIT_SUCCESS } from '../../constants/actionTypes';
import { editUserSuccess } from '../../actions/users';
import userEnrichEdit from '../../utils/serializers/userEnrichEdit';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(USER_EDIT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { user } },
    {
      authentication: { authToken },
      roles: { byHash: rolesByHash },
    },
  ]) => ajax({
    url: USERS_EDIT_PATH.replace('$userId', user.id),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(userEnrichEdit(user, { rolesByHash })),
  }).pipe(
    mergeMap(({ response }) => [
      addNotification(NotificationType.SUCCESS, USER_EDIT_SUCCESS),
      editUserSuccess(usersSerializer(response)),
    ]),
    catchError(error => of(handleError(error, USER_EDIT_ERROR))),
  )),
);
