import {
  ORGANIZATIONS_FETCH_REQUEST,
  ORGANIZATIONS_FETCH_SUCCESS,
  ORGANIZATION_CREATE_REQUEST,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_EDIT_REQUEST,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_FETCH_REQUEST,
  ORGANIZATION_FETCH_SUCCESS,
  ORGANIZATIONS_CHILDREN_FETCH_REQUEST,
  ORGANIZATIONS_CHILDREN_FETCH_SUCCESS,
  HELPER_ORGANIZATIONS_FETCH_REQUEST,
  HELPER_ORGANIZATIONS_FETCH_SUCCESS,
  ASSIGN_PARENT_ORGANIZATION_SUCCESS,
  ASSIGN_PARENT_ORGANIZATION_REQUEST,
} from '../constants/actionTypes';

const fetchOrganizationsDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchChildrenOrganizationsRequest = parentId => ({
  type: ORGANIZATIONS_CHILDREN_FETCH_REQUEST,
  payload: {
    parentId,
  },
});

export const fetchChildrenOrganizationsSuccess = (organizations, parentId) => ({
  type: ORGANIZATIONS_CHILDREN_FETCH_SUCCESS,
  payload: {
    organizations,
    parentId,
  },
});

export const fetchHelperOrganizationsRequest = (payload = {}) => ({
  type: HELPER_ORGANIZATIONS_FETCH_REQUEST,
  payload: {
    ...fetchOrganizationsDefaultPayload,
    ...payload,
  },
});

export const fetchHelperOrganizationsSuccess = organizations => ({
  type: HELPER_ORGANIZATIONS_FETCH_SUCCESS,
  payload: {
    organizations,
  },
});

export const fetchOrganizationsRequest = (payload = {}) => ({
  type: ORGANIZATIONS_FETCH_REQUEST,
  payload: {
    ...fetchOrganizationsDefaultPayload,
    ...payload,
  },
});

export const fetchOrganizationsSuccess = (organizations, count) => ({
  type: ORGANIZATIONS_FETCH_SUCCESS,
  payload: {
    organizations, count,
  },
});

export const fetchOrganizationRequest = id => ({
  type: ORGANIZATION_FETCH_REQUEST,
  payload: {
    id,
  },
});

export const fetchOrganizationSuccess = organization => ({
  type: ORGANIZATION_FETCH_SUCCESS,
  payload: {
    organization,
  },
});

export const createOrganizationRequest = (organization, parentId) => ({
  type: ORGANIZATION_CREATE_REQUEST,
  payload: {
    organization,
    parentId,
  },
});

export const createOrganizationSuccess = organization => ({
  type: ORGANIZATION_CREATE_SUCCESS,
  payload: {
    organization,
  },
});

export const assignParentOrganization = (organization, parentId, editOrganization) => ({
  type: ASSIGN_PARENT_ORGANIZATION_REQUEST,
  payload: {
    organization,
    parentId,
    editOrganization,
  },
});

export const assignParentOrganizationSuccess = () => ({
  type: ASSIGN_PARENT_ORGANIZATION_SUCCESS,
});

export const editOrganizationRequest = (organization, parentId) => ({
  type: ORGANIZATION_EDIT_REQUEST,
  payload: {
    organization,
    parentId,
  },
});

export const editOrganizationSuccess = organization => ({
  type: ORGANIZATION_EDIT_SUCCESS,
  payload: {
    organization,
  },
});
