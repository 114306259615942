import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Message, Icon,
} from 'semantic-ui-react';

import useForm from '../../../../hooks/useForm';
import { SIGN_IN_ROUTE } from '../../../../constants/customizations';
import { styles } from '../../../../components/LayoutLogin';
import { validationSchema } from './schema';

const RequestTokenForm = ({
  emailSent, errorMessage, resetPasswordTokenRequest, loading,
}) => {
  const [translate] = useTranslation();
  const {
    values, handleChange, validate, errors, handleBlur,
  } = useForm(validationSchema);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      resetPasswordTokenRequest(values.username);
    }
  };

  const renderBackLink = () => (
    <Link className={styles.backLink} to={SIGN_IN_ROUTE}>
      <Icon name="caret left" />
      {translate('BACK_TO_LOGIN')}
    </Link>
  );

  return emailSent ? (
    <div>
      <Message
        positive
        header={translate('SUCCESS')}
        content={translate('RESET_PASSWORD_EMAIL_HAS_BEEN_SENT')}
      />
      {renderBackLink()}
    </div>
  ) : (
    <Form onSubmit={handleSubmit} loading={loading}>
      <Form.Input
        className={styles.input}
        disabled={loading}
        error={errors.username}
        name="username"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={translate('USERNAME')}
        value={values.username}
      />

      {errorMessage && (
        <Message size="mini" negative header={translate(errorMessage)} />
      )}

      <Button
        className={styles.submit}
        content={translate('SEND_RESET_PASSWORD_EMAIL')}
        fluid
        type="submit"
      />

      {renderBackLink()}
    </Form>
  );
};

RequestTokenForm.propTypes = {
  emailSent: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  resetPasswordTokenRequest: PropTypes.func.isRequired,
};

export default RequestTokenForm;
