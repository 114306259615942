
import {
  EDIT_USER_EMAIL_COMPLETE,
  EDIT_USER_EMAIL_ERROR,
  EDIT_USER_EMAIL_REQUEST,
  EDIT_USER_EMAIL_SUCCESS,
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_FETCH_REQUEST,
  USER_FETCH_SUCCESS,
} from '../constants/actionTypes';

const fetchUsersDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchUsersRequest = (payload = {}) => ({
  type: USERS_FETCH_REQUEST,
  payload: {
    ...fetchUsersDefaultPayload,
    ...payload,
  },
});

export const fetchUsersSuccess = (users, count) => ({
  type: USERS_FETCH_SUCCESS,
  payload: {
    users, count,
  },
});

export const fetchUserRequest = id => ({
  type: USER_FETCH_REQUEST,
  payload: {
    id,
  },
});

export const fetchUserSuccess = user => ({
  type: USER_FETCH_SUCCESS,
  payload: {
    user,
  },
});

export const createUserRequest = user => ({
  type: USER_CREATE_REQUEST,
  payload: {
    user,
  },
});

export const createUserSuccess = user => ({
  type: USER_CREATE_SUCCESS,
  payload: {
    user,
  },
});

export const editUserRequest = user => ({
  type: USER_EDIT_REQUEST,
  payload: {
    user,
  },
});

export const editUserSuccess = user => ({
  type: USER_EDIT_SUCCESS,
  payload: {
    user,
  },
});

export const editUserEmail = (user, email) => ({
  type: EDIT_USER_EMAIL_REQUEST,
  payload: {
    user,
    email,
  },
});

export const editUserEmailSuccess = user => ({
  type: EDIT_USER_EMAIL_SUCCESS,
  payload: {
    user,
  },
});

export const editUserEmailError = errMessage => ({
  type: EDIT_USER_EMAIL_ERROR,
  payload: {
    errMessage,
  },
});

export const completeEditUserEmail = () => ({
  type: EDIT_USER_EMAIL_COMPLETE,
});
