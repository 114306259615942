import { countryOptions } from '../../utils/countries';
import { langOptions } from '../../utils/languages';
import { TYPES } from '../../components/FilterPanel/constants';
import insertIf from '../../utils/insertIf';

const basicFilters = [{
  name: 'CREATED',
  key: 'created',
  type: TYPES.DATE,
}, {
  name: 'UPDATED',
  key: 'updated',
  type: TYPES.DATE,
}, {
  name: 'COUNTRY',
  key: 'countries',
  type: TYPES.MULTISELECTION,
  values: countryOptions,
}, {
  name: 'LANGUAGE',
  key: 'languages',
  type: TYPES.MULTISELECTION,
  values: langOptions,
}];

export default ({ statuses, roles }) => [
  ...basicFilters,
  ...insertIf(!!roles.length, {
    name: 'ROLE',
    key: 'roles',
    type: TYPES.SELECTION,
    values: roles,
  }),
  ...insertIf(!!statuses.length, {
    name: 'STATUS',
    key: 'statuses',
    type: TYPES.SELECTION,
    values: statuses,
  }),
];
