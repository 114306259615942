import React from 'react';
import PropTypes from 'prop-types';
import NotificationMessage from '../NotificationMessage';
import styles from './Notifications.module.scss';

const Notifications = ({ notifications }) => (
  <div className={styles.wrapper}>
    {notifications.map(notification => (
      <NotificationMessage
        notification={notification}
        key={notification.id}
      />
    ))}
  </div>
);

Notifications.defaultProps = {
  notifications: [],
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired,
  })),
};

export default Notifications;
