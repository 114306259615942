import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import { ASSIGN_PARENT_ORGANIZATION_PATH } from '../../constants/paths';
import { ASSIGN_PARENT_ORGANIZATION_REQUEST, ASSIGN_PARENT_ORGANIZATION_SUCCESS, ASSIGN_PARENT_ORGANIZATION_ERROR } from '../../constants/actionTypes';
import { assignParentOrganizationSuccess, editOrganizationRequest } from '../../actions/organizations';
import { addNotification } from '../../actions/notifications';
import NotificationType from '../../constants/notificationTypes';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ASSIGN_PARENT_ORGANIZATION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { organization, parentId, editOrganization } },
    { authentication: { authToken } },
  ]) => ajax({
    url: ASSIGN_PARENT_ORGANIZATION_PATH.replace('$organizationId', organization.id).replace('$parentId', parentId),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, ASSIGN_PARENT_ORGANIZATION_SUCCESS),
      assignParentOrganizationSuccess(),
      editOrganization && editOrganizationRequest(organization),
    ].filter(Boolean)),
    catchError(error => of(
      handleError(error, ASSIGN_PARENT_ORGANIZATION_ERROR, { organizationId: organization.id }),
    )),
  )),
);
