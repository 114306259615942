import {
  REDUX_INIT,
  RESET_PASSWORD_BY_ADMIN_REQUEST,
  RESET_PASSWORD_BY_ADMIN_SUCCESS,
  RESET_PASSWORD_CLEAN_UP,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TOKEN_REQUEST,
  RESET_PASSWORD_TOKEN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_ERROR,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  UNAUTHORIZED,
} from '../constants/actionTypes';

export const initialState = {
  authToken: '',
  error: false,
  loading: false,
  resetPasswordEmailSent: false,
  resetPasswordErrorMessage: '',
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case SIGN_IN_SUCCESS: {
      const { session: { authToken }, ...rest } = payload.signInResponse;
      return {
        ...state,
        loading: false,
        error: false,
        authToken,
        ...rest,
      };
    }
    case SIGN_IN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        authToken: '',
        loading: true,
        signOutAuthToken: state.authToken,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    case RESET_PASSWORD_BY_ADMIN_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case RESET_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        resetPasswordEmailSent: true,
        resetPasswordErrorMessage: '',
        resetPasswordLoading: false,
        resetPasswordSuccess: false,
      };
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_BY_ADMIN_SUCCESS:
      return {
        ...state,
        resetPasswordEmailSent: false,
        resetPasswordErrorMessage: '',
        resetPasswordLoading: false,
        resetPasswordSuccess: true,
      };
    case RESET_PASSWORD_ERROR: {
      const { error: { message = '' } = {} } = payload;
      return {
        ...state,
        resetPasswordEmailSent: false,
        resetPasswordErrorMessage: message,
        resetPasswordLoading: false,
        resetPasswordSuccess: false,
      };
    }
    case RESET_PASSWORD_CLEAN_UP:
      return {
        ...state,
        resetPasswordEmailSent: false,
        resetPasswordErrorMessage: '',
        resetPasswordLoading: false,
        resetPasswordSuccess: false,
      };
    case REDUX_INIT:
      return {
        ...initialState,
        ...state,
      };
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};
