import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Organizations from './Organizations';
import { fetchOrganizationStatusesRequest } from '../../actions/organizationStatuses';
import { fetchOrganizationsRequest } from '../../actions/organizations';

const getValues = userStatuses => userStatuses.byId
  .map(id => userStatuses.byHash[id])
  .map(({ id, name }) => ({ value: id.toString(), text: name }));

const mapStateToProps = ({
  organizationStatuses,
  organizations: {
    byId,
    byHash,
    loadingList,
    count,
  },
}) => ({
  organizations: byId.map(id => byHash[id]),
  loading: loadingList,
  count,
  statuses: getValues(organizationStatuses),
});

const mapDispatchToProps = dispatch => ({
  fetch: bindActionCreators(fetchOrganizationsRequest, dispatch),
  fetchOrganizationStatuses: bindActionCreators(fetchOrganizationStatusesRequest, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organizations));
