import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Button, Input, Popup } from 'semantic-ui-react';

import styles from './searchPanel.module.scss';
import FilterPanel from '../FilterPanel';
import { filterConfigType } from '../../constants/propTypes';

const SearchPanel = ({
  defaultValue, filterConfig, placeholder, onChange,
}) => {
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [searchText, setSearchText] = useState(defaultValue);

  const toggleFilter = () => {
    setFilterPanelVisible(!filterPanelVisible);
  };

  const onSearch = (value) => {
    onChange(value);
  };

  const debouncedSearch = useCallback(debounce(onSearch, 300), []);

  const handleOnChange = (event, data) => {
    debouncedSearch(data.value);
    setSearchText(data.value);
  };

  const resetSearch = () => {
    setSearchText('');
    onSearch('');
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={searchText}
        maxLength={100}
        floated="right"
        className={styles.searchInput}
        icon={
          {
            link: true,
            name: searchText ? 'close' : 'search',
            onClick: searchText ? resetSearch : null,
          }
        }
        onChange={handleOnChange}
        placeholder={placeholder}
      />
      <Popup
        on={['click']}
        position="bottom right"
        open={filterPanelVisible}
        onOpen={toggleFilter}
        onClose={toggleFilter}
        trigger={(
          <Button
            className={classNames(styles.filterButton, filterPanelVisible && styles.active)}
            icon="filter"
          />
        )}
        content={(
          <FilterPanel
            config={filterConfig}
            onClose={toggleFilter}
          />
        )}
      />
    </div>
  );
};

SearchPanel.defaultProps = {
  defaultValue: '',
};

SearchPanel.propTypes = {
  defaultValue: PropTypes.string,
  filterConfig: filterConfigType.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchPanel;
