import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import React from 'react';

import styles from './LayoutLogin.module.scss';

const LayoutLogin = ({ children }) => (
  <div className={styles.loginWrapper}>
    <Image src="/images/layout-background.svg" centered className={styles.background} />

    <div className={styles.formContainer}>
      {children}
    </div>
  </div>
);

LayoutLogin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


export default LayoutLogin;
