import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from './Login';
import signInRequest from '../../actions/authentication';

const mapStateToProps = ({ authentication: { loading, error } }) => ({
  loading,
  error,
});

const mapDispatchToProps = dispatch => ({
  signIn: bindActionCreators(signInRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
