import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ListActionButtons.module.scss';

const ListActionButtons = ({
  isActive, handleDelete, handleUpdate, handleSubmit,
  handleCancel, disableSubmit, deleteConfirmationText,
}) => {
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);

  const buttonProps = {
    className: 'simple',
    primary: true,
  };

  const handleConfirmCancel = () => {
    setOpen(false);
  };

  const handleConfirmSubmit = () => {
    setOpen(false);
    handleDelete();
  };

  const handleDeleteAction = () => {
    setOpen(true);
  };

  return isActive ? (
    <div className={styles.container}>
      <Button {...buttonProps} icon="cancel" content={translate('CANCEL')} onClick={handleCancel} basic />
      <Button {...buttonProps} icon="check" content={translate('SAVE')} onClick={handleSubmit} disabled={disableSubmit} />
    </div>
  ) : (
    <div className={styles.container}>
      <Button {...buttonProps} icon="edit" onClick={handleUpdate} basic size="big" />
      <Button {...buttonProps} icon="trash" onClick={handleDeleteAction} basic size="big" />
      <Confirm
        open={open}
        onCancel={handleConfirmCancel}
        content={deleteConfirmationText}
        onConfirm={handleConfirmSubmit}
      />
    </div>
  );
};

ListActionButtons.propTypes = {
  isActive: PropTypes.bool.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteConfirmationText: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ListActionButtons;
