import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import organizationEnrich from '../../utils/serializers/organizationEnrich';
import organizationsSerializer from '../../utils/serializers/organizationsSerializer';
import { ORGANIZATIONS_EDIT_PATH } from '../../constants/paths';
import NotificationType from '../../constants/notificationTypes';
import {
  ORGANIZATION_EDIT_REQUEST,
  ORGANIZATION_EDIT_SUCCESS,
  ORGANIZATION_EDIT_ERROR,
} from '../../constants/actionTypes';
import { editOrganizationSuccess } from '../../actions/organizations';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (action$, state$, { ajax }) => action$.pipe(
  ofType(ORGANIZATION_EDIT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { organization } }, { authentication: { authToken } }]) => ajax({
    url: ORGANIZATIONS_EDIT_PATH.replace('$organizationId', organization.id),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(organizationEnrich(organization)),
  }).pipe(
    mergeMap(({ response }) => [
      addNotification(NotificationType.SUCCESS, ORGANIZATION_EDIT_SUCCESS),
      editOrganizationSuccess(organizationsSerializer(response)),
    ]),
    catchError(error => of(handleError(error, ORGANIZATION_EDIT_ERROR))),
  )),
);
