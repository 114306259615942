const organizationEnrich = ({
  name, statusId, customAttributes, ...rest
}) => ({
  name,
  statusId,
  customAttributes: customAttributes
    .map(({ key, ...restAttributes }) => restAttributes)
    .filter(attr => attr.name && attr.value),
  ...rest,
});

export default organizationEnrich;
